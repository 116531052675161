
import { observer } from 'mobx-react';
import RegistrationLayout from './components/RegistrationLayout';
import RegistrationEmailSecondStep from './components/RegistrationEmailSecondStep';
import RegistrationSendMailSecondStep from './components/RegistrationSendMailSecondStep';
import RegistrationInformationSecondStep from './components/RegistrationInformationSecondStep';
import { useParams, useSearchParams  } from 'react-router-dom';
import { useEffect } from 'react';


const RegistrationSecondStepScreen = observer(() => {
    const [searchParams] = useSearchParams();
    const step = searchParams.get('step');
    const params = useParams();

    useEffect(()=> {
        
    },[searchParams])


    return (
        <RegistrationLayout>
            {step== 2 ?  <RegistrationSendMailSecondStep /> : 
                step == 3 ? <RegistrationInformationSecondStep /> : <RegistrationEmailSecondStep /> 
            }
        </RegistrationLayout>
    )
})

export default RegistrationSecondStepScreen;