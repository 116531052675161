import { useNavigate } from 'react-router-dom';

export default function Breadcrumb() {
    const navigate = useNavigate();

    return (
        <div className="bres-cus">
            <div className="container">
                <ul>
                    <li>
                        <a onClick={() => navigate('/')}><img src="/images/icon-home.svg" alt="" /></a> 
                    </li>
                    <li className="dash">\</li>
                    <li>新規アカウント登録</li>
                </ul>
            </div>
        </div>
    )
}