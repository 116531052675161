/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import { useStore } from '../../core/utils/hook';
import { numberWithCommas } from '../../core/utils/common';
import { COURSE_PERIOD_STATUS, DATE_YMD_ISO, DATE_YMD_JP, MSG, SYSTEM_PATH, TIME_APPLY_COURSE_PERIOD } from '../../core/configs/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import yup from '../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment/moment';
import NotFoundScreen from '../404/NotFoundScreen';

const PaymentScreen = observer(() => {

    // other
    const { state } = useLocation();
    const navigate = useNavigate()

    // store
    const {
        ticketStore: { checkPromotionCode },
        paymentStore: { pushDataToGMO, pushToPaymentStatusWhenOrderFree },
        userStore: { userInfoHeader, coursePeriodStatus }
    } = useStore();

    // state
    const [quantity, setQuantity] = useState(1);
    const [isPromotion, setIsPromotion] = useState(null);
    const [dataPromotionCode, setDataPromotionCode] = useState({});
    const [discountAmount, setDiscountAmount] = useState(0);
    const [isDiscountAmountFull, setIsDiscountAmountFull] = useState(false);
    const [taxMoney, setTaxMoney] = useState(0);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [priceAmount, setPriceAmount] = useState(0);
    const [messErrorPromotion, setMessErrorPromotion] = useState('');
    const [disableClick, setDisableClick] = useState(false);
    const [timeApplyCoursePeriod, setTimeApplyCoursePeriod] = useState(TIME_APPLY_COURSE_PERIOD.IMMEDIATELY_AFTER_PURCHASE.value);
    const [coursePeriod, setCoursePeriod] = useState(moment());

    const validatePaymentSchema = yup.object().shape({
        quantity: yup.number().max(100).default(1)
    });

    const { register, handleSubmit, 
        formState: { errors, isSubmitting }, setValue, clearErrors, reset , getValues
    } = useForm({ resolver: yupResolver(validatePaymentSchema), mode: 'onChange' });

    // lifecycle
    useEffect(() => {
        updatePriceWhenAddDiscount();
    }, [discountAmount, quantity]);

    useEffect(() => {
        if(userInfoHeader?.enableSelectOptionsWhenUseCreditCard) {
            if(coursePeriodStatus !== COURSE_PERIOD_STATUS.EXPIRED && !(userInfoHeader.numberTicket === 0 && !userInfoHeader.hasCourseAttending)) {
                onChangeTimeApplyCoursePeriod({ target: { value: TIME_APPLY_COURSE_PERIOD.AFTER_THE_PREVIOUS_COURSE_PERIOD_EXPIRE.value } });
            } else {
                onChangeTimeApplyCoursePeriod({ target: { value: TIME_APPLY_COURSE_PERIOD.SELECT_COURSE_PERIOD.value } });
            }
        }
    }, [userInfoHeader, coursePeriodStatus])

    // function
    const updatePriceWhenAddDiscount =  async () => {
        const calculationPriceAmount = state?.price * quantity;
        
        const isSuccessful = (calculationPriceAmount - discountAmount) > 0;

        const calculationFormulaTax = (isSuccessful) ?  Math.round((calculationPriceAmount - discountAmount) * 0.1) : 0; // tax 10% 
        const calculationPaymentAmount = (isSuccessful) && calculationPriceAmount && 
            Math.round(calculationPriceAmount + calculationFormulaTax - discountAmount) || 0 ; 
            
        calculateTicketWhenAddPromotionCode(dataPromotionCode, calculationPriceAmount);
        setPriceAmount(calculationPriceAmount);

        if(isDiscountAmountFull == true) {
            setPaymentAmount(0);
            setTaxMoney(0);
        } else {
            setPaymentAmount(calculationPaymentAmount);
            setTaxMoney(calculationPriceAmount && calculationFormulaTax);
        }
    };

    const handleOnSubmit = async () => {
        if(!disableClick){
            const { id, code } = dataPromotionCode;
            const body = {
                amount: quantity,
                unitAmountTicket: state?.amount || 0,
                unitPriceTicket: state?.price || 0,
                tax: taxMoney,
                promoId: id || null,
                promotionCode: code || null,
                subtotal: quantity * state?.price || 0,
                discount: discountAmount,
                totalPayment: paymentAmount,
                ticketId: state?.id,
                isRePayment: false,
                timeApplyCoursePeriod: userInfoHeader?.enableSelectOptionsWhenUseCreditCard ? timeApplyCoursePeriod : TIME_APPLY_COURSE_PERIOD.IMMEDIATELY_AFTER_PURCHASE.value,
                ...(timeApplyCoursePeriod === TIME_APPLY_COURSE_PERIOD.SELECT_COURSE_PERIOD.value ? {
                    coursePeriod: moment(coursePeriod).format(DATE_YMD_ISO)
                } : {}) 
            };

            setDisableClick(true);

            if(paymentAmount === 0){
                const data = await pushToPaymentStatusWhenOrderFree(body)
                if(data){
                    navigate(`/payment/payment-status/${data.id}`);
                }
            } else {
                const url = await pushDataToGMO(body);
                if(url) {
                    const newWindow = window.open(url, '_self');
                    if (newWindow) newWindow.opener = null;
                }
            }
        }

        setTimeout(() => {
            setDisableClick(false);
        }, 2000);
    }
    const calculateTicketWhenAddPromotionCode = (promotionCodeData,totalPrice) => {
        let { discountType, discountNumber } = promotionCodeData;
        let priceWithPercent = Math.round(totalPrice * (discountNumber / 100));

        if(discountType == 0 && discountNumber == 100){
            setIsDiscountAmountFull(true);
        } else {
            setIsDiscountAmountFull(false);
        }
        
        switch (discountType) {
        case 0:
            setDiscountAmount(priceWithPercent);
            break;
        case 1:
            setDiscountAmount(discountNumber);
            break;
        default:
            setDiscountAmount(0);
            break;
        }
    };

    const PromotionCode = () => {
        const updatePromotionCode = (evt)=> {
            if(evt.target.value.length == 0) {
                setMessErrorPromotion(null)
                setDelPromotionCode()
                reset()
            }
        };

        const setDelPromotionCode =()=>{
            setIsPromotion(false);
            setDataPromotionCode({});
            setDiscountAmount(0);
            calculateTicketWhenAddPromotionCode(0,0);
        }
        
        const handleCheckPromotion = async (isPromotion) => {
            if(getValues('promotionCode').length == 0) return;

            if(!isPromotion) {
                const promotionCode = getValues('promotionCode') || null;
                const { data } = await checkPromotionCode(promotionCode, { ticketId: state?.id });
                if (data.status === 1) {
                    if (data.expirateStatus){
                        setDelPromotionCode();
                        setMessErrorPromotion(MSG['error.promotion_code_expires']);
                        return;
                    }
                    calculateTicketWhenAddPromotionCode(data);
                    setDataPromotionCode(data);
                    setIsPromotion(true);
                } else {
                    setDelPromotionCode();
                    setMessErrorPromotion(MSG['error.promotion_code_not_match_format']);
                }
            } else {
                setDelPromotionCode();
                reset();
                setMessErrorPromotion(null);
            }
            
        };

        return (
            <div className="row justify-content-between pd-10">
                <div className="ma col-9 col-sm-10 col-md-10 justify-content-between">
                    <input
                        {...register('promotionCode')}
                        autoFocus
                        type="text"
                        className="input-payment col-10"
                        disabled={isPromotion}
                        maxLength={30}
                        onChange={updatePromotionCode}
                    />
                    <span className="col-1" style={{ textAlign: 'end' }}>
                        {isPromotion ? <img src="/images/v3.svg" alt="" /> : <></>}
                    </span>
                </div>
                <button onClick={()=>handleCheckPromotion(isPromotion ? true : false)} className="btn-add-code col-1">
                    {isPromotion ? '削除' : '適用'} 
                </button>
            </div>

        );
    };

    const onChangeTimeApplyCoursePeriod = (e) => {
        const value = Number(e.target.value);
        setTimeApplyCoursePeriod(value);
        setCoursePeriod(value === TIME_APPLY_COURSE_PERIOD.SELECT_COURSE_PERIOD.value ? moment() : moment(userInfoHeader.coursePeriodEnd).add(1, 'day'));
    }

    const PaymentContent = () => {
        return (
            <div className="content-Pay-ment">
                <div className="container">
                    <h2 className="title-primary">お申込み内容</h2>
                    <div className="content-content">
                        <h3 className="title-2">{state?.name}</h3>
                        {
                            userInfoHeader?.enableSelectOptionsWhenUseCreditCard &&
                            <div className="item-1">
                                <div className="row">
                                    <div className="col-12">
                                        <p className="title-l">受講期間を選択してください。</p>
                                    </div>
                                </div>
                                <div className='row ps-5'>
                                    {
                                        userInfoHeader.numberTicket === 0 && !userInfoHeader.hasCourseAttending &&
                                        <>
                                            <div className="col-12 d-flex align-items-center flex-gap-10">
                                                <input type='radio' name='type' style={{ 'width': '18px', 'height': '18px' }} 
                                                    value={TIME_APPLY_COURSE_PERIOD.SELECT_COURSE_PERIOD.value}
                                                    checked={timeApplyCoursePeriod === TIME_APPLY_COURSE_PERIOD.SELECT_COURSE_PERIOD.value}
                                                    id={TIME_APPLY_COURSE_PERIOD.SELECT_COURSE_PERIOD.label}
                                                    onChange={onChangeTimeApplyCoursePeriod}/>
                                                <label htmlFor={TIME_APPLY_COURSE_PERIOD.SELECT_COURSE_PERIOD.label} role='button'>
                                                    {TIME_APPLY_COURSE_PERIOD.SELECT_COURSE_PERIOD.label}
                                                </label>
                                            </div>
                                        </>
                                    }
                                    {
                                        coursePeriodStatus !== COURSE_PERIOD_STATUS.EXPIRED &&
                                        <>
                                            <div className="col-12 d-flex align-items-center flex-gap-10 mg-t-10">
                                                <input type='radio' name='type' style={{ 'width': '18px', 'height': '18px' }} 
                                                    value={TIME_APPLY_COURSE_PERIOD.AFTER_THE_PREVIOUS_COURSE_PERIOD_EXPIRE.value}
                                                    checked={timeApplyCoursePeriod === TIME_APPLY_COURSE_PERIOD.AFTER_THE_PREVIOUS_COURSE_PERIOD_EXPIRE.value}
                                                    id={TIME_APPLY_COURSE_PERIOD.AFTER_THE_PREVIOUS_COURSE_PERIOD_EXPIRE.label}
                                                    onChange={onChangeTimeApplyCoursePeriod}/>
                                                <label htmlFor={TIME_APPLY_COURSE_PERIOD.AFTER_THE_PREVIOUS_COURSE_PERIOD_EXPIRE.label} role='button'>
                                                    {TIME_APPLY_COURSE_PERIOD.AFTER_THE_PREVIOUS_COURSE_PERIOD_EXPIRE.label}
                                                </label>
                                            </div>
                                            <div className="col-12 d-flex align-items-center flex-gap-10">
                                                <div style={{ 'width': '18px', 'height': '18px' }}/>
                                                <span className='red small'>
                                                    {TIME_APPLY_COURSE_PERIOD.AFTER_THE_PREVIOUS_COURSE_PERIOD_EXPIRE.note}
                                                </span>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        }
                        <div className="item-1 mg-t-10">
                            <div className="row">
                                <div className="col-md-2 col-sm-3 col-4">
                                    <p className="title-l">受講期間</p>
                                </div>
                                <div className="col-md-10 col-sm-9 col-8">
                                    <p className="title-r d-flex align-items-center justify-content-end flex-wrap">
                                        <span>
                                            {moment(coursePeriod).format(DATE_YMD_JP)}
                                        </span>
                                        <span>～{moment(coursePeriod).add(1, 'year').subtract(1, 'day').format(DATE_YMD_JP)}</span>
                                        <span>(1 年間)</span>
                                    </p>
                                </div>
                            </div>
                            <p className="red small mg-t-5">※本サービスは自動更新制ではありません</p>
                        </div>
                        <div className="item-1">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l">価格:</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r">{numberWithCommas(state?.price)} 円</p>
                                </div>
                            </div>
                        </div>
                        <div className="item-1">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l">数量:</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r">{'1'}</p>
                                </div>
                            </div>
                        </div>

                        <div className="item-2 pd-0">
                            <h3 className="title-3">クーポンコード:</h3>
                            <PromotionCode />
                            {isPromotion === false ? (
                                <div className="text-danger fs-error mg-t-10 pd-0">
                                    {messErrorPromotion}
                                </div>
                            ) : <div className="text-danger fs-error mg-t-10 pd-0"></div>}
                        </div>

                        <div className="item-2-ticket">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l-ticket">決済方法:</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r-ticket">クレジットカード</p>
                                </div>
                            </div>
                        </div>

                        <div className="item-1">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l">税抜金額:</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r">{numberWithCommas(priceAmount)} 円</p>
                                </div>
                            </div>
                        </div>
                        <div className="item-1">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l">割引金額:</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r">
                                        {numberWithCommas(discountAmount)} 円
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item-1">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l">10％消費税額:</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r">{numberWithCommas(taxMoney)} 円</p>
                                </div>
                            </div>
                        </div>
                        <div className="item-1 item-4">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l">お支払い金額:</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r">
                                        {numberWithCommas(paymentAmount)} 円
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p className="note-ticket">
              ※クレジット情報入力画面へ行く前に必ず下記ご案内をお読みください。
                        </p>
                        <div className='wapper-button-payment d-flex align-items-center justify-content-between flex-gap-10 flex-wrap'>
                            <div className='bank-logo d-flex align-items-center flex-gap-10'>
                                <img src='/images/creditCard1.jpg' alt=''/>
                                <img src='/images/creditCard2.gif' alt=''/>
                                <img src='/images/creditCard3.webp' alt=''/>
                                <img src='/images/creditCard4.png' alt=''/>
                                <img src='/images/creditCard5.png' alt=''/>
                            </div>
                            <div className="button-payment link-link mg-t-0">
                                <button disabled={disableClick} onClick={handleOnSubmit}>クレジット情報入力</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const PaymentBottom = () => {
        return (
            <div className="content-Pay-ment-bottom">
                <div className="container">
                    <div className="content-1">
                        <h3 className="title-1">
              オンラインクレジット決済と解約についてご案内
                        </h3>
                        <p>
              本画面の次の画面にてお客様のクレジット情報をご入力いただき決済ボタンを押していただくと、即時決済完了となります。
                        </p>
                        <p>
              受講は決済当日から開始となります（23時59分以降の決済は翌日開始となります）。
                        </p>
                        <p>
                            {' '}
              なお、本システムはクレジット会社の審査をパスしている加盟店限定のサービスとなり、クレジットカード情報などの個人情報は暗号化通信（SSL）にて保護されておりますので、安心してご利用いただけます。
                        </p>
                        <p className='title-bottom'>【解約について】</p>
                        <p className='title-bottom'>
              本e-learning
              サービスは、特定商取引法のクーリングオフ対象外となります。決済後の解約は致しかねますので予めご了承ください。
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    if(!state?.id || (!userInfoHeader?.allowBuyTicket && !userInfoHeader?.enableSelectOptionsWhenUseCreditCard) || userInfoHeader?.registerNewCoursePeriod) return <NotFoundScreen/>;

    return (
        <div id="main" className="wrapper main-execute-completion main-Pay-ment payment-screen">
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li>
                            <a href={SYSTEM_PATH.HOME}>
                                <img src="../images/icon-home.svg" alt="" />
                            </a>
                        </li>
                        <li className="dash">\</li>
                        <li>お申込み内容</li>
                    </ul>
                </div>
            </div>

            <PaymentContent />
            <PaymentBottom />
        </div>
    );
});

export default PaymentScreen;
