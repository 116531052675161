
import { observer } from 'mobx-react';
import Breadcrumb from './Breadcrumb';
import RegistrationStep from './RegistrationStep';
import { useStore } from '../../../core/utils/hook';
import { useParams, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { SYSTEM_PATH } from '../../../core/configs/constants';

const RegistrationLayout = observer((props) => {
    const { children } = props;
    const params = useParams();
    const { pathname } = useLocation();

    const { authStore: {
        changeStepRegister
    } } = useStore();

    useEffect(() => {
        if(pathname == SYSTEM_PATH.REGISTRATION_FIRST_STEP) {
            changeStepRegister(1);
        }else if(pathname == SYSTEM_PATH.REGISTRATION_SECOND_STEP) {
            changeStepRegister(2);
        }else if(pathname == SYSTEM_PATH.REGISTRATION_THIRD_STEP) {
            changeStepRegister(3);
        }else {
            changeStepRegister(1);
        }

    }, [pathname])

    return (
        <div id="main"
            className="wrapper  main-account-trgisstration-1 
                main-account-trgisstration-2 
                main-account-trgisstration-4 
                main-account-trgisstration-3 
                main-account-trgisstration-5">
            <Breadcrumb />
            <div className="content-signin">
                <div className="container">
                    <h2 className="title-primary">新規アカウント登録</h2>
                    <RegistrationStep />
                    {children}
                </div>
            </div>
        </div>
    )
})

export default RegistrationLayout;