import { flow, makeObservable, observable } from 'mobx';
import { LectureApi } from '../apis';
import BaseStore from './BaseStore';

class LectureStore extends BaseStore {

    lectures = [];

    constructor(rootStore) {
        super();
        makeObservable(this, {
            lectures: observable,
            getAllLectures: flow.bound,
            getLectureDetail: flow.bound,
            getRelatedLectures: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new LectureApi();
    }

    *getAllLectures(searchParams) {
        try {
            const { size, page, sortDir, sortKey } = this.paging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllLectures, payload);
            if(res?.ok) {
                this.lectures = res?.data?.elements;
                this.setAttrObservable('paging', res?.data?.paginate, true, false);
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *getLectureDetail(id) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getLectureDetail, { id });
            return res?.data;
        } catch (error) {
            console.log('error: ', error);
            return;
        }
    }

    *getRelatedLectures(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getRelatedLectures, payload);
            return res?.data;
        } catch (error) {
            console.log('error: ', error);
            return;
        }
    }
}

export default LectureStore;