/* eslint-disable max-len */
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { BeatLoader, BarLoader } from 'react-spinners';
import './style.scss';

const LoadingSrc = observer(() => {

    // useEffect(() => {
    //     let width = 20;

    //     const interval = setInterval(function() {
    //         window.$('.progress-bar')
    //             .css('width', width + '%')
    //             .attr('aria-valuenow', width)
    //         if (width >= 100){
    //             clearInterval(interval);
    //         }
    //         else {
    //             width += 5;
    //         }
    //     }, 100);

    //     return () => clearInterval(interval);
    // }, [])

    return(
        <div id='loading-src' className='loading-src'>

            <img className='image-logo' src='/images/elephamt_logo.svg'/>
            {/* <BeatLoader className='mg-t-30' color='#36d7b7' margin={5} size={20}/> */}
            {/* <div className='progress'>
                <div className='progress-bar' role='progressbar' style={{width: '20%'}} aria-valuenow='0' aria-valuemin='0' aria-valuemax='100'></div>
            </div> */}
            <BarLoader className='mg-t-30' color='#1A2C44' width={300} height={5} speedMultiplier={0.75}/>
        </div>
    )
})

export default LoadingSrc;