import { observer } from 'mobx-react';
import {
    useNavigate
} from 'react-router-dom';
const RegistrationSendMailSecondStep = observer(() => {

    const navigate = useNavigate();

    return (
        <div className="content-trgisstration-3">
            <div className="icon">
                <img src="/images/icon-gmail.svg" alt="" />
            </div>
            <p className="desc">メールを送信しました。<br /> 送信メール内のURLから受講者情報の登録を完了してください。</p>
            <button onClick={() => navigate('/')}>ホームページへ戻る</button>
        </div>
    );
});

export default RegistrationSendMailSecondStep;