import { observer } from 'mobx-react';
import { USER_TYPE } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import NotFoundScreen from '../404/NotFoundScreen';
import GAHomeScreen from './GAHomeScreen';
import STDHomeScreen from './STDHomeScreen';

const HomeScreen = observer((props) => {

    // store
    const { authStore: { token, userInfo } } = useStore();

    if(token && userInfo?.roleId === USER_TYPE.GROUP_MANAGER) return <GAHomeScreen/>
    
    if(!token || (token && ( userInfo?.roleId === USER_TYPE.STUDENT_GROUP || userInfo?.roleId === USER_TYPE.STUDENT_INDIVIDUAL ))) 
        return <STDHomeScreen/>
    
    return <NotFoundScreen/>
})

export default HomeScreen;