import { flow, makeObservable, observable } from 'mobx';
import { TicketApi } from '../apis';
import BaseStore from './BaseStore';

class TicketStore extends BaseStore {

    tickets = [];

    constructor(rootStore) {
        super();
        makeObservable(this, {
            tickets: observable,
            getAllTickets: flow.bound,
            checkPromotionCode: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new TicketApi();
    }

    *getAllTickets(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllTickets, payload);
            if(res?.ok) {
                this.tickets = res?.data;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *checkPromotionCode(code, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.checkPromotionCode, { code, payload });
            if(res?.ok) {
                return res
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }
}

export default TicketStore;