import classNames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import { DATE_TIME_YMD, MSG, PAYMENT_STATUS, PAYMENT_STATUS_NAME, TYPE_EXPORT_PDF_INVOICE } from '../../../core/configs/constants';
import { numberWithCommas } from '../../../core/utils/common';
import { useStore } from '../../../core/utils/hook';
import { convertCoursePeriodDate } from '../../Payment/utils/payment.utils';
import { ModalFooter } from '../../../components';

const getSource = (status) => {
    let statusName = '';
    let className = '';
    switch(status) {
    case PAYMENT_STATUS.PAID:
        statusName = PAYMENT_STATUS_NAME.PAID;
        // statusName = 'PAID';
        className = 'item-purchasse-paid';
        break;
    case PAYMENT_STATUS.UNPAID:
        statusName = PAYMENT_STATUS_NAME.UNPAID;
        // statusName = 'UNPAID';
        className = 'item-purchasse-unpaid';
        break;
    case PAYMENT_STATUS.PROCESSING:
        statusName = PAYMENT_STATUS_NAME.PROCESSING;
        // statusName = 'PROCESSING';
        className = 'item-purchasse-processing';
        break;
    case PAYMENT_STATUS.CANCEL_REQUEST:
        statusName = PAYMENT_STATUS_NAME.CANCEL_REQUEST;
        // statusName = 'PROCESSING';
        className = 'item-purchasse-cancel-request';
        break;
    case PAYMENT_STATUS.CANCELED:
        statusName = PAYMENT_STATUS_NAME.CANCELED;
        // statusName = 'PROCESSING';
        className = 'item-purchasse-canceled';
        break;
    default:
        break;
    }

    return { statusName, className }
}


function PurchaseHistoryItem({ item, onDeletePurchase, onRequestCancelPurchase }) {
    const { statusName, className } = getSource(item.status);
    // store
    const {
        paymentStore: { pushDataToGMO, exportInvoicePDF },
        modalStore: { openWarningModal, show, hide },
        userStore: { getPurchaseHistories, setAttrObservable }
    } = useStore();

    // function
    const fetchData = (payload = {}) => {
        setAttrObservable('pagingPurchaseHistory', { page: 1 }, true, false);
        if (payload.ticketName) {
            payload.ticketName = payload.ticketName.trim()
        }
        getPurchaseHistories(payload);
    }

    const handleGoPayment = async () => {
        if (item.status !== PAYMENT_STATUS.PROCESSING) return;
        
        const body = {
            amount: item.amount,
            unitAmountTicket: item.unitAmountTicket || 0,
            unitPriceTicket: item.unitPriceTicket || 0,
            tax: item.tax,
            promoId: item.promotionId || null,
            promotionCode: item.promotionCode || null,
            subtotal: item.subtotal,
            discount: item.discount,
            totalPayment: item.totalPayment,
            ticketId: item.ticketId,
            paymentId: item.paymentId,
            isRePayment: true
        };
        const url = await pushDataToGMO(body);
        const newWindow = window.open(url, '_self');
        if (newWindow) newWindow.opener = null;
        return;
    }

    const onConfirmDeletePurchase = (item) => {
        openWarningModal(MSG['warning.student.delete_purchase'], () => {
            onDeletePurchase(item)
        })
    }

    const onConfirmRequestCancel = (item) => {
        openWarningModal(MSG['warning.student.request_cancel_purchase'], () => {
            onRequestCancelPurchase(item)
        })
    }

    const onShowConfirmExportInvoice = (id) => {
        let typeExport = TYPE_EXPORT_PDF_INVOICE.EXPORT_INVOICE_PDF_INDIVIDUAL;
        show({
            id: 'modal-warning',
            isOpen: true,
            header: '確認',
            onCancel: () => hide(),
            children: (
                <div className='text-center min-width-400'>
                    <div key={'modal-body'} className='modal-body d-flex flex-column pd-20'>
                        <span style={{'whiteSpace': 'pre-line'}}>{MSG['confirm.confirm_export_invoice_purchase_content']}</span>
                        <select
                            style={{ height: '44px', border: '1px solid #DADDE2', borderRadius: '4px' }}
                            className='mt-4 mb-4' 
                            onChange={(event) => {
                                typeExport = event.target.value;
                            }}>
                            <option
                                key={TYPE_EXPORT_PDF_INVOICE.EXPORT_INVOICE_PDF_GROUP}
                                value={TYPE_EXPORT_PDF_INVOICE.EXPORT_INVOICE_PDF_GROUP}>会社名で出力する</option>
                            <option 
                                key={TYPE_EXPORT_PDF_INVOICE.EXPORT_INVOICE_PDF_INDIVIDUAL} 
                                value={TYPE_EXPORT_PDF_INVOICE.EXPORT_INVOICE_PDF_INDIVIDUAL}>個人名で出力する</option>
                        </select>
                    </div>
                    <ModalFooter key={'modal-footer'}
                        saveButtonText={'領収書出力'}
                        onConfirm={ async() => {
                            hide();
                            await exportInvoicePDF(id, {
                                exportInvoiceType: Number(typeExport)
                            });
                            fetchData();
                        }}
                        cancelButtonText={'キャンセル '}
                        onCancel={() => hide()}
                        saveButtonClass='btn-default-2'
                    />
                </div>
            ),
            type: 'small'
        })
    };

    return (
        <div className={classNames('item-purchasse', item.status && className)}>
            <h4 className="title-3">
                <span>{item.code}</span>
                <span>{statusName}</span>
            </h4>
            <div className="nav-item-purchasse">
                <h5 className="title-4">{item.name}</h5>
                <div className="item">
                    <div className="item-1">
                        <p>
                            <span className="span-1">購入日付:</span>
                            <span className="span-2">{moment(item.createdAt).format(DATE_TIME_YMD)}</span>
                        </p>
                        <p className='pc-course-period'>
                            <span className="span-1">受講期間:</span>
                            <span className="span-2">{convertCoursePeriodDate(item?.coursePeriod)}</span>
                        </p>
                    </div>
                    <div className="item-1 item-2">
                        <p>
                            <span className="span-1">支払方法:</span>
                            <span className="span-2">クレジットカード</span>
                        </p>
                        <p>
                            <span className="span-1">割引:</span>
                            <span className="span-2">{numberWithCommas(item.discount)} 円</span>
                        </p>
                    </div>
                </div>
                <div className="item">
                    <div className="item-1">
                        <p>
                            <span className="span-1">価格:</span>
                            <span className="span-2">{numberWithCommas(item?.unitPriceTicket)} 円</span>
                        </p>
                        <p>
                            <span className="span-1">数量:</span>
                            <span className="span-2">{item.amount || 0}</span>
                        </p>
                        <p>
                            <span className="span-1">クーポンコード:</span>
                            <span className="span-2">{item.promotionCode}</span>
                        </p>
                    </div>
                    <div className="item-1 item-2">
                        <p>
                            <span className="span-1">10％消費税額:</span>
                            <span className="span-2">{numberWithCommas(item.tax)} 円</span>
                        </p>
                        <p className='price'>
                            <span className="span-1">合計金額:</span>
                            <span className="span-2">{numberWithCommas(item.totalPayment)} 円</span>
                        </p>
                    </div>
                </div>
                <div className='button-puchase-item d-flex align-item-center justify-content-end flex-gap-20'>
                    {
                        item.status === PAYMENT_STATUS.UNPAID &&
                        <div className='link-link'>
                            <button className='delete-purchase' onClick={() => onConfirmDeletePurchase(item)}>削除</button>
                        </div>
                    }
                    {
                        item.status === PAYMENT_STATUS.PROCESSING && 
                        <>
                            <div className='link-link'>
                                <button className='delete-purchase' onClick={() => onConfirmRequestCancel(item)}>キャンセルリクエスト</button>
                            </div>
                            <div className="link-link">
                                <button onClick={handleGoPayment}>再支払</button>
                            </div>
                        </>
                    }
                </div>
                {
                    item.status == PAYMENT_STATUS.PAID && (
                        <div className='button-puchase-item d-flex align-item-center justify-content-end flex-gap-20'>
                            <div className='link-link'>
                                <button 
                                    disabled={item.noExportInvoice} 
                                    onClick={() => onShowConfirmExportInvoice(item.paymentId)} 
                                    className='export-invoice-purchase'>{item.noExportInvoice ? '領収書出力済み' : '領収書出力'}</button>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default observer(PurchaseHistoryItem);
