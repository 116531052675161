import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import { useStore } from '../../core/utils/hook';
import Footer from './Footer';
import Header from './Header';
import './style.scss';

const ContainerBody = observer(() => {

    // store
    const { authStore: { userInfo } } = useStore();

    return(
        <>
            <Header/>
            <div className='wrapper-container'>
                <div className='wrapper-content'>
                    <Outlet/>
                </div>
            </div>
            <Footer/>
        </>
    )
})

export default ContainerBody;