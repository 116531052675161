import { observer } from 'mobx-react';
import { Navigate, Outlet } from 'react-router-dom';
import { SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import NotFoundScreen from '../../screens/404/NotFoundScreen';

const Authentication = observer((props) => {

    const { role, notAuth, alwaysAccess, redirectHome } = props;
    const { authStore: { token, userInfo } } = useStore();

    if(!notAuth) {
        if(token == null) return <Navigate to={SYSTEM_PATH.LOGIN}/>;

        if(!role.includes(userInfo?.roleId)) return <NotFoundScreen/>;
    } else {
        if(!alwaysAccess) {
            if(token) {
                if(redirectHome) return <Navigate to={SYSTEM_PATH.HOME}/>;
                else return <NotFoundScreen/>;
            }
        } else {
            if(role && token && !role.includes(userInfo?.roleId)) return <NotFoundScreen/>;
        }
    }

    return <Outlet/>
})

export default Authentication;