import FileSaver from 'file-saver';
import axios from 'axios';
import { getSavedInfo } from './browser';
import CONFIG from '../../core/configs/config';
import { MSG } from '../configs/constants';

export function handleSaveFile(response) {
    try {
        const filename = decodeURI(response.headers['content-disposition'].split('filename=')[1].split(';')[0]).replaceAll('"', '');
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type });
        FileSaver.saveAs(blob, filename);
    } catch (error) {
        console.log('error: ', error);
    }
}

export async function handleDownloadFile({ url, data = {}, method = 'post', headers = {}, cb }) {
    const http = CONFIG.ssl ? 'https' : 'http';
    const path = `${http}://${CONFIG.api_url}${url != null ? `/${url}` : ''}`;
    const token = getSavedInfo('token');
    const config = {
        url: path,
        method,
        headers: Object.assign(
            {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: token,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Expose-Headers': 'Content-Disposition',
                Pragma: 'no-cache'
            },
            headers
        ),
        responseType: 'blob'
    };

    if (['get', 'GET'].includes(method)) {
        config.params = data;
    } else {
        config.data = data;
    }

    try {
        const res = await axios.request(config);
        handleSaveFile(res);
        cb && cb();
    } catch (error) {
        console.log('error: ', error);
        let res = {};
        const responseData = await error?.response?.data?.text() || {};
        res = (typeof responseData === 'string') ? JSON.parse(responseData) : responseData;
        cb && cb(res?.message || MSG['api.response.server_error']);
    }


}
