import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SYSTEM_PATH } from '../../../core/configs/constants';
import { useStore } from '../../../core/utils/hook';
import { onLoadSwiper } from '../../../core/utils/main';
import RelatedLectureItem from './RelatedLectureItem';

function RelatedLectures({ courseId, lectureId, isOldCourse }) {
    const [lectures, setLectures] = useState([]);
    const {
        lectureStore: { getRelatedLectures }
    } = useStore();

    useEffect(() => {
        fetchData();
    }, [courseId, lectureId]);

    useEffect(() => {
        if (lectures?.length > 0) {
            // onLoadSwiper('slider-ls');
            onLoadSwiper('slider-ls', '.swiper-scrollbar', '#navigation-right', '#navigation-left', 392, false, false, 4, 2.5, 1.25, 24);
        }
    }, [lectures])

    const fetchData = async () => {
        try {
            const res = await getRelatedLectures({ courseId, lectureId });
            setLectures(res || []);
        } catch (error) {
            console.log('error: ', error);
        }
    }

    if (lectures.length === 0) return null;

    return (
        <section className="download-slide course-home other-slider-new releted-lecture">
            <div className="container">
                <h2 className="title-primary">講座内の他のコンテンツ</h2>
                <div className="wp-slide-ls slider-course" id="slider-course">
                    <div className='btn-slide btn-left-slide' id='navigation-left'>
                        <div className='img-btn-left'>
                            {/* <img src='/images/arrow.svg'> */}
                            prev
                        </div>
                    </div>
                    <div className='btn-slide btn-right-slide' id='navigation-right'>
                        <div className='img-btn-right'>
                            {/* <img src='/images/arrow.svg'> */}
                            next
                        </div>
                    </div>
                    <div className="swiper-container slide-ls" id="slider-ls">
                        <div className="swiper-wrapper">
                            {lectures.slice(0, 10).map((item, index) => {
                                return (
                                    <div key={index} className="swiper-slide">
                                        <RelatedLectureItem item={item} isOldCourse={isOldCourse}/>
                                    </div>
                                );
                            })}

                            <div className="swiper-slide">
                                <div className='item item-last'>
                                    <div className=' item-readMore'>
                                        <Link role={'button'} to={SYSTEM_PATH.COURSE + courseId}>もっと見る <img src='/images/icon3.svg' alt='' /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* scrollbar */}
                    <div className="swiper-scrollbar" />
                </div>
            </div>
        </section>
    );
}

export default observer(RelatedLectures);
