/* eslint-disable max-len */
import { observer } from 'mobx-react';
import { useStore } from '../../../core/utils/hook';
import { useEffect } from 'react';
import {
    USER_TYPE, SYSTEM_PATH,
    MSG, GENDER, GENDER_STRING, WORK_FORM, WORK_FORM_STRING, PREFECTURES, WORK_TYPE, USER_TYPE_STRING,
    DATE_YMD
} from '../../../core/configs/constants';
import moment from 'moment';
import { handleKeyPressForNumber,
    handleKeyPressKataNumberText, handlePasteKataNumberText, KataNumberReg, handlePasteForNumber
} from '../../../core/utils/common';
import { useForm } from 'react-hook-form';
import yup from '../../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import { reloadEventsDOMContentLoaded } from '../../../core/utils/browser';
import { useNavigate } from 'react-router-dom';
import CalendarCustom from '../../../components/Calendar/CalendarCustom';


const UpdateProfile = observer(() => {

    const navigate = useNavigate()
    const { userStore: { updateProfile, profile, getProfile }, modalStore: { openAlert } } = useStore();

    const validateSchema = yup.object().shape({
        firstName: yup.string().trim().required(MSG['error.required']).nullable(),
        lastName: yup.string().trim().required(MSG['error.required']).nullable(),
        firstNameFuri: yup.string().trim().required(MSG['error.required']).matches(KataNumberReg, MSG['error.format_hira_kata']).nullable(),
        lastNameFuri: yup.string().trim().required(MSG['error.required']).matches(KataNumberReg, MSG['error.format_hira_kata']).nullable(),
        groupName: yup.string().required(MSG['error.required']).nullable(),
        groupNameFuri: yup.string().trim().required(MSG['error.required']).matches(KataNumberReg,MSG['error.format_hira_kata']).nullable(),
        gender: yup.string().required(MSG['error.required']).nullable(),
        email: yup.string().required(MSG['error.required']).email(MSG['error.email_format']).nullable(),
        confirmEmail: yup.string().required(MSG['error.required'])
            .email(MSG['error.email_format']).oneOf([yup.ref('email')], MSG['error.email_confirm_not_match']),
        pharmacistNumber: yup.string().required(MSG['error.required']).typeError(MSG['error.number_positive']),
        confirmPharmacistNumber: yup.string().required(MSG['error.required']).
            oneOf([yup.ref('pharmacistNumber')], MSG['error.student_confirm_pharmacist_not_match_format']).typeError(MSG['error.number_positive']),
        phoneNumber: yup.string().required(MSG['error.required']).typeError(MSG['error.number_positive']),
        workForm: yup.string().required(MSG['error.required']).nullable(),
        zipCode: yup.string().required(MSG['error.required']).typeError(MSG['error.number_positive']),
        prefecture: yup.string().required(MSG['error.required']).nullable(),
        address1: yup.string().required(MSG['error.required']).nullable(),
        address2: yup.string().nullable(),
        workType: yup.string().required(MSG['error.required']).nullable(),
        birthday: yup.string().required(MSG['error.required']),
        roleId: yup.string().required(MSG['error.required'])
    })

    const { register, formState: { errors },
        getValues, setValue, handleSubmit, reset, trigger, watch } =
        useForm({ resolver: yupResolver(validateSchema), mode: 'onChange' })

    const watchRoleId = watch('roleId');

    const onBlurZipCode = (e) => {
        if (e.target.value) {
            let address = document.getElementById('address1').value || '';
            let address2 = document.getElementById('address2').value || '';
            let prefecture = document.getElementById('prefecture').value || '';
            setValue('address1', address, { shouldValidate: true });
            setValue('address2', address2, { shouldValidate: true });
            setValue('prefecture', prefecture, { shouldValidate: true });
        }
    }

    const onSubmit = (data) => {
        const { 
            firstName, firstNameFuri, lastName, lastNameFuri, gender, 
            birthday, workForm, phoneNumber, workType, zipCode, prefecture, address1, address2, 
            groupName, groupNameFuri, email, confirmEmail, pharmacistNumber, confirmPharmacistNumber, roleId
        } = data;
        updateProfile({ 
            firstName, firstNameFuri, lastName, lastNameFuri, gender, 
            birthday, workForm: parseInt(workForm), phoneNumber, workType, zipCode, prefecture, address1, address2, 
            groupName, groupNameFuri, email, confirmEmail, pharmacistNumber, confirmPharmacistNumber, userType: Number(roleId)
        }).then(res => {
            if (res?.ok) {
                if(email !== profile?.email) {
                    openAlert('新メールアドレスへ確認用メールを送信しました。', () => navigate('/profile'));
                } else {
                    navigate('/profile');
                }
                
            }
        });
    }

    useEffect(() => {
        reloadEventsDOMContentLoaded();
        getProfile();
    }, [])

    useEffect(() => {
        if (!profile) return;
        profile.roleId = profile?.roleId?.toString();
        profile.confirmEmail = profile?.email;
        profile.confirmPharmacistNumber = profile?.pharmacistNumber;
        reset(profile);
    }, [profile])

    // change email and pharmacist
    const onChangeEmail = async (field, value) => {
        setValue(field, value);
        if(getValues('email') && getValues('confirmEmail')) {
            trigger(['email', 'confirmEmail']);
        } else {
            trigger(field);
        }
    }

    const onChangePharmacistNumber = async (field, value) => {
        setValue(field, value);
        if(getValues('pharmacistNumber') && getValues('confirmPharmacistNumber')) {
            trigger(['pharmacistNumber', 'confirmPharmacistNumber']);
        } else {
            trigger(field);
        }
    }

    const onChangeUserType = () => {
        const { groupName, groupNameFuri } = profile;
        if(watchRoleId == USER_TYPE.STUDENT_INDIVIDUAL) {
            setValue('groupName', groupName);
            setValue('groupNameFuri', groupNameFuri);
        }
    }

    return (
        <div className="update-acount-left">
            <div className="title-title">
                <h2 className="title-2">ユーザー情報</h2>
            </div>
            <div className="note-cs-3 text-14 mg-t-20">
                <span className='text-12'>※ </span> 姓名、薬剤師名簿登録番号は、日本薬剤師研修センターへ受講者情報として提出いたしますので、薬剤師名簿に登録されている内容を正確にご入力ください。</div>
            <form className="h-adr" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-account mg-t-20">
                    <div className="box-form-account">
                        <div className="content-content">
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6 update-info-name">
                                        <div className="item-left">
                                            <label for="">姓: <span>*</span></label>
                                        </div>
                                        <div className="item-right">
                                            <input type="text"  {...register('firstName')} placeholder="学生" />
                                            {errors?.firstName?.message && <p className='text-error'>{errors?.firstName?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6 update-info-name">
                                        <div className="item-left">
                                            <label for="">名: <span>*</span></label>
                                        </div>
                                        <div className="item-right">
                                            <input type="text" {...register('lastName')} placeholder="学生" />
                                            {errors?.lastName?.message && <p className='text-error'>{errors?.lastName?.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6 update-info-name">
                                        <div className="item-left">
                                            <label for="">姓(フリガナ) <span>*</span></label>
                                        </div>
                                        <div className="item-right">
                                            <input type="text" {...register('firstNameFuri')} 
                                                onKeyPress={handleKeyPressKataNumberText}
                                                onPaste={handlePasteKataNumberText}
                                            />
                                            {errors?.firstNameFuri?.message && <p className='text-error'>{errors?.firstNameFuri?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6 update-info-name">
                                        <div className="item-left">
                                            <label for="">名(フリガナ)<span>*</span></label>
                                        </div>
                                        <div className="item-right">
                                            <input type="text"  {...register('lastNameFuri')} 
                                                onKeyPress={handleKeyPressKataNumberText}
                                                onPaste={handlePasteKataNumberText}
                                            />
                                            {errors?.lastNameFuri?.message && <p className='text-error'>{errors?.lastNameFuri?.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item ">
                                <div className="item-left">
                                    <label for="">性別 <span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <select {...register('gender')} id="gender">
                                        <option value="">選択して下さい</option>
                                        {GENDER && Object.keys(GENDER).map((key) =>
                                            <option key={key.toString()} value={GENDER[key]}>{GENDER_STRING[key]}</option>)
                                        }
                                    </select>
                                    {errors?.gender?.message && <p className='text-error'>{errors?.gender?.message}</p>}
                                </div>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-7 col-sm-8 col-12 bod-view">
                                        <div className="item-left">
                                            <label for="">生年月日 <span>*</span></label>
                                        </div>
                                        <div className="item-right item-bod">
                                            <CalendarCustom
                                                date={getValues('birthday') || null}
                                                displayMode={'date'}
                                                maxDate={new Date()}
                                                onChange={(date) => {
                                                    setValue('birthday', date ? moment(date).format(DATE_YMD) : '', { shouldValidate: true });
                                                }}
                                            />
                                            {/* <input type="date" {...register('birthday')} /> */}
                                            {errors?.birthday?.message && <p className='text-error'>{errors?.birthday?.message}</p>}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="item">
                                <div className="item-left">
                                    <label for="">メールアドレス<span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <input type="text" {...register('email')}
                                        onCopy={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                        onCut={(e) => e.preventDefault()}
                                        onChange={(e) => onChangeEmail('email', e.target.value)}
                                    />
                                    <div className='icon-verify-update-profile'>
                                        {
                                            !errors?.email ?
                                                <img src="/images/icon11.svg" alt="" />
                                                :
                                                <img src="/images/clear.svg" alt="" />
                                        }
                                    </div>
                                    {errors?.email?.message && <p className='text-error'>{errors?.email?.message}</p>}
                                    {
                                        profile?.newEmail &&
                                        <p className="note-3" style={{fontSize: '12px', lineHeight:'20px', marginTop: '8px'}}>
                                            <span>※ </span>メールアドレスを{profile.newEmail}に変更するように要求しました 。<br/>
                                            新しいメールアドレスの確認リンクを記載したメールが届きます。再ログインする前に、このメールを開いてリンクをクリックしてください。
                                        </p>
                                    }
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-left">
                                    <label for="">メールアドレスの確認<span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <input type="text" {...register('confirmEmail')}
                                        onCopy={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                        onCut={(e) => e.preventDefault()}
                                        onChange={(e) => onChangeEmail('confirmEmail', e.target.value)}
                                    />
                                    <div className='icon-verify-update-profile'>
                                        {
                                            !errors?.confirmEmail ?
                                                <img src="/images/icon11.svg" alt="" />
                                                :
                                                <img src="/images/clear.svg" alt="" />
                                        }
                                    </div>
                                    {errors?.confirmEmail?.message && <p className='text-error'>{errors?.confirmEmail?.message}</p>}
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-left">
                                    <label for="">薬剤師名簿登録番号<span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <input type="text" {...register('pharmacistNumber')}
                                        onCopy={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                        onCut={(e) => e.preventDefault()}
                                        onChange={(e) => onChangePharmacistNumber('pharmacistNumber', e.target.value)}
                                    />
                                    <div className='icon-verify-update-profile'>
                                        {
                                            !errors?.pharmacistNumber ?
                                                <img src="/images/icon11.svg" alt="" />
                                                :
                                                <img src="/images/clear.svg" alt="" />
                                        }
                                    </div>
                                    <p className="note-3" style={{fontSize: '12px', lineHeight:'20px', marginTop: '8px'}}>
                                        <span>※ </span>半角数字で入力してください。再度、薬剤師名簿登録番号に間違いがないかご確認ください。</p>
                                    {errors?.pharmacistNumber?.message && <p className='text-error'>{errors?.pharmacistNumber?.message}</p>}
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-left">
                                    <label for="">薬剤師名簿登録番号確認<span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <input type="text" {...register('confirmPharmacistNumber')}
                                        onCopy={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                        onCut={(e) => e.preventDefault()}
                                        onChange={(e) => onChangePharmacistNumber('confirmPharmacistNumber', e.target.value)}
                                    />
                                    <div className='icon-verify-update-profile'>
                                        {
                                            !errors?.confirmPharmacistNumber ?
                                                <img src="/images/icon11.svg" alt="" />
                                                :
                                                <img src="/images/clear.svg" alt="" />
                                        }
                                    </div>
                                    {errors?.confirmPharmacistNumber?.message && <p className='text-error'>{errors?.confirmPharmacistNumber?.message}</p>}
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-left">
                                    <label for="">沖/外選択<span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <select {...register('workForm')}>
                                        {WORK_FORM && Object.keys(WORK_FORM).map((key) =>
                                            <option key={key.toString()} value={WORK_FORM[key].toString()}>{WORK_FORM_STRING[key]}</option>)
                                        }
                                    </select>
                                    {errors?.workForm?.message && <p className='text-error'>{errors?.workForm?.message}</p>}
                                    <p className="note-3" style={{fontSize: '12px', lineHeight:'20px', marginTop: '8px'}}>
                                        <span>※ </span> 沖／外で始まる名簿登録番号の方は、こちらにて選択してください。</p>
                                </div>
                            </div>


                            <div className="item ">
                                <div className="item-left">
                                    <label for="">電話番号 <span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <input type="text" {...register('phoneNumber')} 
                                        onKeyPress={handleKeyPressForNumber} 
                                        onPaste={handlePasteForNumber}/>
                                    {errors?.phoneNumber?.message && <p className='text-error'>{errors?.phoneNumber?.message}</p>}
                                </div>
                            </div>
                            <div className="item ">
                                <div className="item-left">
                                    <span className="p-country-name" style={{ display: 'none' }}>Japan</span>
                                    <label for="">郵便番号 <span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <input type="text" className='p-postal-code' {...register('zipCode')} 
                                        onKeyPress={handleKeyPressForNumber} 
                                        onPaste={handlePasteForNumber}
                                        onBlur={onBlurZipCode} />
                                    <p className="note-3" style={{fontSize: '12px', lineHeight:'20px', marginTop: '8px'}}><span>※ </span>ハイフンなしで入力ください。</p>
                                    {errors?.zipCode?.message && <p className='text-error'>{errors?.zipCode?.message}</p>}
                                </div>
                            </div>
                            <div className="item ">
                                <div className="item-left">
                                    <label for="">都道府県 <span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <select id="prefecture" {...register('prefecture')} className="p-region">
                                        <option value="">選択して下さい</option>
                                        {PREFECTURES && PREFECTURES.map((item, idx) => <option key={idx.toString()} value={item}>{item}</option>)}
                                    </select>
                                    {errors?.prefecture?.message && <p className='text-error'>{errors?.prefecture?.message}</p>}
                                </div>
                            </div>
                            <div className="item ">
                                <div className="item-left">
                                    <label for="">住所 <span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <input type="text" id='address1' {...register('address1')}
                                                className="p-locality p-street-address" />
                                            {errors?.address1?.message && <p className='text-error'>{errors?.address1?.message}</p>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-6">
                                            <input type="text" {...register('address2')} id={'address2'} className="p-extended-address" />
                                            {errors?.address2?.message && <p className='text-error'>{errors?.address2?.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item ">
                                <div className="item-left">
                                    <label for="">勤務業態 <span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <select {...register('workType')}>
                                        <option value="">選択して下さい</option>
                                        {WORK_TYPE && Object.keys(WORK_TYPE).map((key) =>
                                            <option key={key} value={WORK_TYPE[key]}>{WORK_TYPE[key]}</option>)}
                                    </select>
                                    {errors?.workType?.message && <p className='text-error'>{errors?.workType?.message}</p>}
                                </div>
                            </div>
                            <div className="item ">
                                <div className="item-left">
                                    <label for="">ユーザーの種類 <span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <ul>
                                        <li>
                                            <input type="radio" {...register('roleId', {
                                                onChange: onChangeUserType
                                            })} value={USER_TYPE.STUDENT_INDIVIDUAL} id='individualStudent'/>
                                            <label role='button' htmlFor='individualStudent'>{USER_TYPE_STRING.STUDENT_INDIVIDUAL}</label>
                                        </li>
                                        <li>
                                            <input type="radio" {...register('roleId', {
                                                onChange: onChangeUserType
                                            })} value={USER_TYPE.STUDENT_GROUP} id='groupStudent'/>
                                            <label role='button' htmlFor='groupStudent'>{USER_TYPE_STRING.STUDENT_GROUP}</label>
                                        </li>
                                    </ul>
                                    {errors?.roleId?.message && <p className='text-error'>{errors?.roleId?.message}</p>}
                                </div>
                            </div>
                            <div className="item ">
                                <div className="item-left">
                                    <label for="">所属会社名 <span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <input type="text" {...register('groupName')}
                                        disabled={watchRoleId == USER_TYPE.STUDENT_GROUP} />
                                    {errors?.groupName?.message && <p className='text-error'>{errors?.groupName?.message}</p>}
                                </div>
                            </div>
                            <div className="item ">
                                <div className="item-left">
                                    <label for="">所属会社名 (フリガナ) <span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <input type="text"
                                        {...register('groupNameFuri')}
                                        onKeyPress={handleKeyPressKataNumberText}
                                        onPaste={handlePasteKataNumberText}
                                        disabled={watchRoleId == USER_TYPE.STUDENT_GROUP} />
                                    {errors?.groupNameFuri?.message && <p className='text-error'>{errors?.groupNameFuri?.message}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="link-button">
                        <button type='button' className="btn-1" onClick={() => navigate(SYSTEM_PATH.PROFILE)}>キャンセル</button>
                        <button className="btn-2" type='submit'>更新する</button>
                    </div>
                </div>
            </form>
        </div>
    )
})

export default UpdateProfile