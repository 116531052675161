import classNames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import { DATE_TIME_YMD, SYSTEM_PATH } from '../../../core/configs/constants';
import { truncateByWords } from '../../../core/utils/common';

function MessageItem({ item, onRead, onExtendCoursePeriod }) {
    const { showMore, text: description } = truncateByWords(item.content);
    return (
        <div onClick={onRead(item)} className={classNames('item-message cursor-pointer', item.isSeen && 'item-message-2')}>
            {/* 1 = GA, 0 = Jiho Admin */}
            {item.senderRole === 1 && <span className='title-secondary'>企業管理者より</span>}
            <p className="date">
                {item.category} | {moment(item.sendDate || item.createdAt).format(DATE_TIME_YMD)}
            </p>
            <h3 className="title-2">{item.title}</h3>
            
            {
                !item.isNotifyExpireCoursePeriod ? 
                    <p className="desc-1">
                        {description} {showMore && <a href={SYSTEM_PATH.MESSAGE + item?.id} className="read-more">もっと見る</a>}
                    </p> :
                    <p className="desc-1">
                        <span>受講期間終了まで残り{item.content}となりました。 下記より受講期間の更新が可能となり ますのでお早めにお手続きをお願いいたします。 </span>
                        <br/>
                        <span className='text-decoration-underline text-hover-link' onClick={onExtendCoursePeriod}>受講プランの申込み</span>
                    </p>
            }
        </div>
    );
}

export default observer(MessageItem);
