import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import yup from '../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import { MSG } from '../../core/configs/constants';
import { useEffect } from 'react';
import { ReactNotifications } from '../../components';
import { useStore } from '../../core/utils/hook';

const SendMessageScreen = observer(({selectedStudent, selectedRowIds, setSelectedStudent}) => {

    // store
    const { messageStore: { sendMessage } } = useStore();

    // state
    const validateSchema = yup.object().shape({
        title: yup.string().required(MSG['error.required']),
        content: yup.string().required(MSG['error.required']),
        sendTo: yup.array().min(1, 'メッセージを送信する受講者を選択してください。'),
        sendType: yup.number()
    })

    const defaultValues = { sendType: 1 };

    const { register, handleSubmit, 
        formState: { errors, isSubmitting }, setValue, clearErrors, reset 
    } = useForm({resolver: yupResolver(validateSchema), mode: 'onChange', defaultValues});

    // lifecycle
    useEffect(() => {
        window.$('#sendMessageScreenModal').modal({
            backdrop: 'static',
            keyboard: false
        });
    }, [])

    useEffect(() => {
        setValue('sendTo', Object.keys(selectedRowIds).map(e => Number(e)), { shouldValidate: true });
    }, [selectedRowIds])

    // function
    const onCloseModal = () => {
        window.$('#sendMessageScreenModal').modal('hide');
        reset(formValues => ({
            ...formValues,
            title: '',
            content: ''
        }))
        clearErrors();
    }

    const clearSelectedStudent = () => {
        setSelectedStudent([]);
        onCloseModal();
    }

    const onDeleteSelectedStudent = (id) => {
        const index = selectedStudent.findIndex(e => e.id = id);
        if(index > -1) {
            setSelectedStudent(state => ([
                ...state.slice(0, index),
                ...state.slice(index + 1)
            ]))
        }
    }

    const onSendMessage = async (data) => {
        const res = await sendMessage({ ...data });
        if(res) {
            onCloseModal();
            reset();
            setSelectedStudent([]);
            ReactNotifications('success', '正常にメッセージを送信しました。');
        }
    }

    return(
        <div className='modal' id='sendMessageScreenModal'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    {/* Modal Header */}
                    <div className='modal-header'>
                        <h3 className='title-1'>メッセージ送信</h3>
                        <button type='button' className='btn-close' onClick={onCloseModal}></button>
                    </div>
                    {/* Modal body */}
                    <div className='modal-body'>
                        <div className='content-pupop'>
                            <div className='content-left'>
                                <h4 className='titl-3'>合計: {selectedStudent?.length ?? 0}</h4>
                                {
                                    selectedStudent?.length > 0 &&
                                    <ul>
                                        {
                                            selectedStudent.map(e => (
                                                <li key={e.id}>
                                                    <a>{`${e?.firstName} ${e?.lastName}`}
                                                        <i className='fa fa-times' role={'button'} aria-hidden='true' 
                                                            onClick={() => onDeleteSelectedStudent(e.id)}/>
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                }
                                <p role={'button'} className='desc-1' onClick={clearSelectedStudent}>すべてのデータを削除する</p>
                            </div>
                            <div className='content-right'>
                                <form onSubmit={handleSubmit(onSendMessage)}>
                                    <label htmlFor='title'>タイトル <span className='text-danger'>*</span></label>
                                    <input type='text' id='title' {...register('title')}/>
                                    {
                                        errors?.title &&
                                        <div className='text-danger mg-t-5'>{errors.title?.message}</div>
                                    }
                                    <label htmlFor='content'>本文 <span className='text-danger'>*</span></label>
                                    <textarea {...register('content')} id='content' cols={30} rows={10}/>
                                    {
                                        errors?.content &&
                                        <div className='text-danger mg-t-5'>{errors.content?.message}</div>
                                    }
                                    {
                                        errors?.sendTo &&
                                        <div className='text-danger mg-t-5'>{errors.sendTo?.message}</div>
                                    }
                                    <div className='link-link'>
                                        <button className='link-1' type='button' onClick={onCloseModal}>
                                            キャンセル
                                        </button>
                                        <button className='link-2' type='submit'>送信</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default SendMessageScreen;