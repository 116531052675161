import ApiService from './ApiService';

class TicketApi extends ApiService {

    // constructor() {
    //     super('ticket');
    // }

    getAllTickets(payload) {
        return this.get('ticket', payload);
    }

    checkPromotionCode({ code, payload }) {
        return this.get(`promotion/${code}`, payload);
    }
}

export default TicketApi;