import { observer } from 'mobx-react';
import ReactPaging from '../../../components/Table/ReactPaging';

function Pagination({ paging, onFetch, hideTotal }) {
    if (!paging || paging.totalRecord === 0) return null;

    return (
        <div className="pagenavi">
            <div className="row">
                <div className="col-md-6 col-sm-6 col-12">
                    {!hideTotal && (
                        <div className="prev">
                            <span>
                                {paging.totalRecord} 件中 {paging.page === 1 ? 1 : (paging.page - 1) * paging.size + 1}～
                                {paging.page * paging.size > paging.totalRecord ? paging.totalRecord : paging.page * paging.size} 件を表示
                            </span>
                        </div>
                    )}
                </div>
                <div className="col-md-6 col-sm-6 col-12">
                    <div className="next my-paginagion">
                        <ReactPaging
                            forcePage={paging.page - 1}
                            pageCount={Math.ceil(paging.totalRecord / paging.size)}
                            onPageChange={({ selected }) => {
                                paging.page = selected + 1;
                                onFetch && onFetch(paging);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(Pagination);
