import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useStore, useTitle } from '../../core/utils/hook';
import { getGeneralSettingValueByType } from './utils/GeneralSetting.utils';
import ReactHtmlParser from 'react-html-parser';

const GeneralSettingScreen = observer(({ pageTitle, type }) => {
    // store
    const {
        generalSettingStore: { getGeneralSettingByType }
    } = useStore();
    const [value, setValue] = useState('');
    useTitle(pageTitle);

    React.useEffect(() => {
        fetchData();
    }, [type]);

    const fetchData = async () => {
        try {
            const res = await getGeneralSettingByType(type);
            const value = getGeneralSettingValueByType(res, type);
            setValue(value);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div id="main" className="wrapper main-general-setting">
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li>
                            <a href="/">
                                <img src="/images/icon-home.svg" alt="" />
                            </a>
                        </li>
                        <li className="dash">\</li>
                        <li>{pageTitle}</li>
                    </ul>
                </div>
            </div>
            <div className="content-Message-detail">
                <div className="container">
                    <h1 className="title-primary">{pageTitle}</h1>

                    <div className="content-content main-general-setting-content pre-wrap">{ReactHtmlParser(value)}</div>
                </div>
            </div>
        </div>
    );
});

export default GeneralSettingScreen;
