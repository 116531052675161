import PropTypes from 'prop-types';

function Breadcrumb({ title }) {
    return (
        <div className="bres-cus">
            <div className="container">
                <ul>
                    <li>
                        <a href="/"><img src="/images/icon-home.svg" alt="" /></a> 
                    </li>
                    <li className="dash">\</li>
                    <li>{title}</li>
                </ul>
            </div>
        </div>
    )
}

Breadcrumb.propTypes = {
    title: PropTypes.string.isRequired
}

export default Breadcrumb;