import ApiService from './ApiService';

class CourseApi extends ApiService {
    constructor() {
        super('course');
    }

    getCoursesByName(payload = {}) {
        return this.get('', payload);
    }

    getAllCourses(payload) {
        return this.get('all', payload);
    }

    getCourseById(payload) {
        return this.get(payload?.id, payload);
    }

    getCourseInternalById(payload) {
        return this.get(`${payload?.id}/internal`, payload);
    }

    getListCompletionTest(payload) {
        return this.get(`${payload?.courseId}/list-completion-tests`, { random: true, isStudent: true });
    }

    achievement({ courseId, payload }) {
        return this.put(`${courseId}/achievement`,payload);
    }

    getTestResultHistory(courseId) {
        let newApi = new ApiService(null, null)
        return newApi.get(`test-result-history/${courseId}`, {})
    }

    getLectureListByCourse(payload){
        return this.get(`${payload?.id}/lecture`,payload);
    }

    getLectureListByCourseInternal(payload){
        return this.get(`${payload?.id}/lecture/internal`,payload);
    }

    getCourseChart(courseId) {
        return this.get(`${courseId}/chart`);
    }

    studentBuyCourse(courseId){
        return this.post('addition', {courseId:courseId});
    }

    getAttendanceHistories(payload = {}){
        return this.post('attendance/history', payload);
    }

    getListCoursePeriod(payload = {}){
        return this.get('attendance/list-course-period', payload);
    }

    exportAttendanceHistories(payload = {}){
        return this.post('attendance/export-pdf', payload, undefined, 'blod');
    }

    getCourseSurvey(payload) {
        return this.get(`${payload?.courseId}/survey`);
    }

    sendCourseSurvey({ courseId, payload }) {
        return this.post(`${courseId}/survey`, payload);
    }
}

export default CourseApi;