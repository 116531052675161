import ApiService from './ApiService';

class PasswordApi extends ApiService {

    constructor() {
        super();
    }

    forgetPassword(payload) {
        return this.post('forget-password', payload);
    }

    checkUuid(payload) {
        return this.get('check-uuid', payload);
    }

    setPassword(payload) {
        return this.post('confirm-password', payload);
    }
}

export default PasswordApi;