import { action, flow, makeObservable, observable } from 'mobx';
import { GeneralSettingApi } from '../apis';
import BaseStore from './BaseStore';

class GeneralSettingStore extends BaseStore {

    generalSetting = {
        faqs: []
    };

    constructor(rootStore) {
        super();
        makeObservable(this, {
            generalSetting: observable,
            getGeneralSetting: flow.bound,
            getGeneralSettingByType: action.bound
        })
        this.rootStore = rootStore;
        this.api = new GeneralSettingApi();
    }

    *getGeneralSetting(payload, cb) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getGeneralSetting, payload || {});
            if(res?.ok) {
                this.generalSetting = {
                    ...res?.data,
                    faqs: res?.data?.faqs ? JSON.parse(res.data.faqs)?.faqs : ( this.generalSetting.faqs || []),
                    sampleVideos: res?.data?.sampleVideos?.length > 0 ? 
                        res.data.sampleVideos.filter(e => e?.url && !e?.converting) : ( this.generalSetting.sampleVideos || [])
                };
                cb && cb(res?.data);
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    async getGeneralSettingByType(type) {
        try {
            const res = await this.rootStore.apiStore.call(this.api, this.api.getGeneralSetting, { type });
            return res?.data || {}
        } catch (error) {
            console.log(error);
            return {};
        }
    }
}

export default GeneralSettingStore;