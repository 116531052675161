import { flow, makeObservable } from 'mobx';
import InquiryApi from '../apis/InquiryApi';

class InquiryStore {
    constructor(rootStore) {
        makeObservable(this, {
            sendInquiry: flow.bound
        });
        this.rootStore = rootStore;
        this.api = new InquiryApi();
    }

    *sendInquiry(payload,cb) {
        try {
            const res = yield this.rootStore.apiStore.call(
                this.api,
                this.api.sendInquiry,
                payload
            );

            if (res?.ok) {
                cb()
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }
}

export default InquiryStore;
