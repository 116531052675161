export const SYSTEM_PATH = {
    TBD_SCREEN: '/tbd',
    SET_PASSWORD: '/student/set-password',
    FORGOT_PASSWORD: '/forgot-password',
    LOGIN: '/login',
    HOME: '/',
    // register
    REGISTRATION_FIRST_STEP: '/registration/first-step',
    REGISTRATION_SECOND_STEP: '/registration/second-step',
    REGISTRATION_THIRD_STEP: '/registration/third-step',
    // mypage
    PROFILE:'/profile',
    PROFILE_EDIT: '/profile-edit',
    CHANGE_PASSWORD: '/change-password',
    PURCHASE_HISTORY: '/purchase-history',
    TOOL_CERTIFICATE: '/tool-certificate',
    // theme
    THEMES: '/course-list',
    THEME: '/theme/',
    // course
    COURSE_DETAIL: '/course/:id',
    COURSE: '/course/',
    // completion test
    COMPLETION_TEST: '/course/:courseId/completion-test',
    // lecture
    LECTURES: '/lectures',
    LECTURE: '/lecture/',
    LECTURE_DETAIL: '/lecture/:id',
    // message
    MESSAGES: '/messages',
    MESSAGE: '/message/',
    MESSAGE_DETAIL: '/message/:id',
    // ticket
    TICKETS: '/tickets',
    // payment
    PAYMENT: '/payment',
    PAYMENT_STATUS: '/student/payment/payment-status/:id',
    PAYMENT_FREE_STATUS: '/payment/payment-status/:id',
    // user guide
    USER_GUIDE: '/user-guide', 
    // faq
    FAQ_SCREEN: '/faqs',
    // contact us
    SEND_INQUIRY: '/contact-us',
    // student group list
    STUDENT_GROUP_LIST: '/student-of-group',
    STUDENT_DETAIL: '/student-detail/:id',
    COURSE_LIST_BY_STUDENT: '/course-list-by-student/:id',
    STUDENT_LIST_BY_ATTENDANCE_CODE: '/student-list-by-attendance-code/:id',
    STUDENT_LIST_BY_ATTENDANCE_LIST_CODE: '/student-list-by-attendance-list-code/:id',
    // about us
    ABOUT_US: '/about-us',
    // attendance history
    ATTENDANCE_HISTORY: '/attendance-history',
    TERM_OF_SERVICE: '/term-of-service',
    PRIVACY_POLICY: '/privacy-policy',
    CANCELLATION_POLICY: '/cancellation-policy',
    SPECIFIED_COMMERCIAL_TRANSACTION_LAW_DISPLAY: '/specified-commercial-transaction-law-display',
    RECOMMENDED_ENVIRONMENT: '/recommended-environment',
    ABOUT_COMPANY: '/about-elephamt',
    // credit
    CREDIT_HISTORY: '/credit-history',
    // notifications,
    NOTIFY_DETAIL: '/notification/:id',
    // confirm email
    CONFIRM_EMAIL: '/student/confirm-email',
    // test result history
    TEST_RESULT_HISTORY: '/test-result-history/:id',
    // survey
    SURVEY_SCREEN: '/survey/:courseId'
};

export const MSG = {
    //#region Api message
    'api.response.worng_format': '応答本文は正しい形式ではありません',
    'api.response.no_message': 'エラーメッセージの応答はありません',
    'api.response.server_error':
      'サーバ接続に問題が発生しました。お手数ですが、もう一度アクセスし直してください。',
    'api.response.no_network':
      'サーバ接続に問題が発生しました。お手数ですが、もう一度アクセスし直してください。',
    'api.response.authorization': '再度ログインして下さい。',
    //#endregion Api message

    //#region inform
    'inform.success.create': 'メッセージ送信を完了しました。',
    'inform.success.update': '編集は完了しました。',
    'inform.success.delete': '削除が完了しました。',
    'inform.success.import': '削除正常にインポート',
    'inform.success.update.profile': 'アカウント情報の変更が完了しました。',
    //#endregion

    //#region validate error message
    'error.required': '必須項目をご入力ください',
    'error.email_format': 'メールアドレスが正しくありません。',
    'error.email_confirm_not_match': '上記メールアドレスと一致しません。',
    'error.number_positive': '正数を入力してください。',
    'error.password_format': 'パスワードが正しくありません。',
    'error.confirm_password_not_match': '上記パスワードと一致しないため再入力をお願いします。',
    'error.promotion_code_not_match_format': '割引きクーポンコードが正しくありません。',
    'error.promotion_code_expires': '割引きクーポンコードの有効期限が切れています。',
    'error.password_format_detail': '８文字以上の半角英数字（１つ以上の大文字含める）',
    'error.format_latin_kanji': '漢字またはローマ字でご入力ください。',
    'error.format_hira_kata': 'フリガナでご入力ください。',
    //#endregion
    // user
    'confirm.confirm_active_user_title': '確認',
    'confirm.confirm_active_user_content': 'ステータスは有効にしてもよろしいですか。',
    'confirm.confirm_deactive_user_title': '確認',
    'confirm.confirm_deactive_user_content': 'ステータスは無効にしてもよろしいですか。',
    'confirm.confirm_delete_user_title': '確認',
    'confirm.confirm_delete_user_content': '削除してもよろしいですか？削除すれば、それに紐づく全 てのデータも削除されます',
    // student
    'confirm.confirm_delete_student_title': '確認',
    'confirm.confirm_delete_student_content': '削除してもよろしいですか？削除すれば、それに紐づく全てのデータも削除されます。',
    'confirm.confirm_active_student_title': '確認',
    'confirm.confirm_active_student_content': '選択した受講者を有効にしてよろしいですか。',
    'confirm.confirm_deactive_student_title': '確認',
    'confirm.confirm_deactive_student_content': '選択した受講者を無効にしてよろしいですか。',
    'error.student_confirm_pharmacist_not_match_format': '上記「薬剤師名簿登録番号」 と一致しません。',
    'error.student_accept_file': 'システムは CSV ファイルインポートのみサポートする',
    'error.student_empty_csv_file': 'CSVファイルにある全ての項目は必須項目となります。',
    //#region promotion
    'confirm.confirm_delete_promotion_title': '確認',
    'confirm.confirm_delete_promotion_content': '削除すると、それに紐づく全てのデータも削除されます。',
    'confirm.confirm_active_promotion_title': '確認',
    'confirm.confirm_active_promotion_content': 'ステータスを「有効」にしてもよろしいですか？',
    'confirm.confirm_deactive_promotion_title': '確認',
    'confirm.confirm_deactive_promotion_content': 'ステータスを「無効」にしてもよろしいですか？',
    'error.max_length_amount': 'Number of characters longer than 9',
    //#endregion

    //#region faq
    'confirm.confirm_delete_faq_title': '確認',
    'confirm.confirm_delete_faq_content': '選択したよくある質問を削除してよろしいですか。',
    //#endregion

    
    //#region course
    'confirm.confirm_delete_course_title': '確認',
    'confirm.confirm_delete_course_content': '選択された講座は削除してもよろしいですか。',

    'confirm.confirm_active_course_title': '確認',
    'confirm.confirm_active_course_content': '選択された講座は有効にしてもよろしいですか。',
    'confirm.confirm_deactive_course_title': '確認',
    'confirm.confirm_deactive_course_content': '選択された講座は無効にしてもよろしいですか。',
    'error.view_old_course': 'この講座を視聴する前に、一つの受講プランを追加してください。',
    //#endregion

    // lecture
    'error.maximum_image_file': 'ファイルサイズの上限は10MBです。',
    'error.invalid_image': 'ファイルの拡張子がサポートされていません。JPG(JPEG)、PNG、GIFのいずれかの拡張子で、ファイルをアップロードすることが可能。',
    'error.confirm_cancel_create': 'Are you sure you want to quit? All progress in this session will be lost.',
    'error.invalid_video': 'ファイルの拡張子がサポートされていません。MP4の拡張子ファイルをアップロードする必要がございます。',
    'error.maximum_video_file': 'ファイル容量の上限は2.5 GBです。',
    'msg.upload_video_title': '動画アップロード中',
    'msg.upload_video_desc': 'アップロードが完了するまでブラウザは閉じないでください。',
    'confirm.delete_lecture': '削除してもよろしいですか？削除すると、それに紐づく全てのデータも削除されます。',
    'confirm.change_lecture_to_active': 'ステータスは有効にしてもよろしいですか。',
    'confirm.change_lecture_to_inactive': 'ステータスは無効にしてもよろしいですか。',
    'confirm.confirm_export_invoice_purchase_content': '領収書を出力したい対象を選択してください。',

    // message
    'msg.send_to_all': 'すべてのユーザーに送信',


    'warning.student.apply.ticket': 'この操作を実行する前に、システムにログインしてください。',
    'confirm.student.send.email': 'メールを送信しました。ご確認ください！',
    'warning.student.view.lecture.detail': '詳細表示にはログインしてください',
    'warning.student.apply.course.none.ticket': '購入した受講プラン分の講座受講は全て完了しました。受講するには新たに受講プランを購入してください。',
    'warning.student.applyed.course.of.ticket': (courseAttended) => 
        `${courseAttended}つの講座は既に受講完了されているため、この受講プランを購入すると${courseAttended}単位分 がプラン数から差し引かれます。このプランを購入しますか?`,

    // purchase
    'warning.student.delete_purchase': 'この購入取引を削除しますが、よろしいですか。',
    'warning.student.request_cancel_purchase': 'この購入取引をキャンセルリクエストしますが、よろしいですか。',

    // tool certificate
    'tool.certificate.update.success': 'データが更新されました。'
};

export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_SIZE_OPTIONS = [10, 50, 100, 500, 1000];
// There are alot of table in system. Example: Document, Form difinition, User, Tenant.
export const TABLE_CODE = {
    DOCUMENT: 0
}

export const DATE_YMD = 'YYYY/MM/DD';
export const DATE_TIME_YMD = 'YYYY/MM/DD HH:mm';
export const DATE_TIME_FULL = 'YYYY/MM/DD HH:mm:ss';
export const DATE_YMD_ISO = 'YYYY-MM-DD';
export const DATE_TIME_YMD_ISO = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_FULL_ISO = 'YYYY-MM-DD HH:mm:ss'; 
export const DATE_YMD_JP = 'YYYY年MM月DD日';
export const DATE_MD_JP = 'MM月DD日';

//#region const student
export const GENDER = {
    MALE: 0,
    FEMALE: 1
}

export const GENDER_STRING = {
    MALE: '男性',
    FEMALE: '女性'
}

export const GENDER_MAP = {
    0: GENDER_STRING.MALE,
    1: GENDER_STRING.FEMALE
}

// 1 offshore, 2 outside
export const OUTSIDE_OFFSHORE = {
    OTHER:0,
    OFFSHORE: 1,
    OUTSIDE: 2
}

export const OUTSIDE_OFFSHORE_STRING = {
    OTHER:'選択なし',
    OFFSHORE: '沖',
    OUTSIDE: '外'
}

// 1 offshore, 2 outside
export const WORK_FORM = {
    OTHER:0,
    OFFSHORE: 1,
    OUTSIDE: 2
}

export const WORK_FORM_STRING = {
    OTHER:'選択なし',
    OFFSHORE: '沖',
    OUTSIDE: '外'
}

export const WORK_FORM_MAP = {
    0: WORK_FORM_STRING.OTHER,
    1: WORK_FORM_STRING.OFFSHORE,
    2: WORK_FORM_STRING.OUTSIDE
}

export const WORK_TYPE = {
    // 保険薬局,
    USER_DISPENSING_PHARMACY: '保険薬局',
    // Dgs
    USER_DGS: 'Dgs',
    // 病院
    USER_HOSPITAL: '病院',
    // 製薬企業
    USER_PHARMACEUTICAL_COMPANIES: '製薬企業',
    // 卸
    USER_WHOLESALER: '卸',
    // その他
    USER_OTHERS: 'その他'
}
//#endregion

export const PREFECTURES = [
    '北海道',
    '青森県',
    '岩手県',
    '宮城県',
    '秋田県',
    '山形県',
    '福島県',
    '茨城県',
    '栃木県',
    '群馬県',
    '埼玉県',
    '千葉県',
    '東京都',
    '神奈川県',
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '静岡県',
    '愛知県',
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '兵庫県',
    '奈良県',
    '和歌山県',
    '鳥取県',
    '島根県',
    '岡山県',
    '広島県',
    '山口県',
    '徳島県',
    '香川県',
    '愛媛県',
    '高知県',
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県'
];

export const FAQ_CATEGORY = [
    '①お申込みについて',
    '➁お支払いについて',
    '③受講関連について',
    '④コンテンツ内容について',
    '⑤単位取得について',
    '⑥操作方法について',
    '⑦その他'
]

export const MAX_IMAGE_SIZE = 10; // Mb
export const MAX_VIDEO_SIZE = 2.5 * 1000; // Mb
export const SAMPLE_VIDEOS_CAROUSEL_INTERVAL = 24 * 60 * 60 * 1000;
export const IMAGE_SUPPORT_FILES = ['jpg', 'jpeg', 'png', 'gif'];
//#region const course
export const COURSE_TYPE ={
    TRAINING_CERTIFIED_PHARMACIST: 0,
    SOCIETY_ACCREDITED_CREDITS: 1,
    HERBAL_MEDICINE: 2,
    DRUG_THERAPY_CERTIFIED_PHARMACIST: 3,
    NON_CERTIFICATE: -1
}

export const COURSE_TYPE_STRING ={
    TRAINING_CERTIFIED_PHARMACIST: '研修認定薬剤師',
    SOCIETY_ACCREDITED_CREDITS: '精神薬学会認定薬剤師',
    HERBAL_MEDICINE: '漢方薬・生薬認定薬剤師',
    DRUG_THERAPY_CERTIFIED_PHARMACIST: '小児薬物療法認定薬剤師',
    NON_CERTIFICATE: '認定制度対象外'
}
//#endregion
export const PATTERN_STRING_ONLY = /^\d+\.?\d*$/;

export const WIDTH_COLUMN_SELECT_TABLE = '50px';


export const USER_TYPE = {
    STUDENT_INDIVIDUAL: 3,
    STUDENT_GROUP: 4,
    JIHOU_ADMINISTRATOR: 1,
    GROUP_MANAGER: 2
}

export const USER_TYPE_STRING = {
    STUDENT_INDIVIDUAL: '個人受講者',
    STUDENT_GROUP: '団体受講者',
    JIHOU_ADMINISTRATOR: 'じほう管理者',
    GROUP_MANAGER: '団体管理者'
}

export const messageCategories = [
    {
        id: 1,
        name: '重要なお知らせ'
    },
    {
        id: 2,
        name: '受講期間終了のお知らせ'
    },
    {
        id: 3,
        name: '新講座のご案内'
    },
    {
        id: 4,
        name: 'おすすめ講座のご案内'
    },
    {
        id: 5,
        name: '講座変更のお知らせ'
    },
    {
        id: 6,
        name: '講座配信停止のお知らせ'
    },
    {
        id: 7,
        name: '単位未申請のお知らせ'
    },
    {
        id: 8,
        name: 'システムメンテナンスのお知らせ'
    }
];
export const COURSE_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
    VIDEO_PENDDING:2
}

export const COURSE_STATUS_STRING = {
    ACTIVE: '有効',
    INACTIVE:'無効',
    VIDEO_PENDDING: 'ビデオ変換中'
}

export const COURSE_STATUS_COMPLETE = {
    USER_COURSE_NOT_ATTENDED: 0, // CHƯA HỌC
    USER_COURSE_ATTENDING: 1, // ĐANG HỌC
    USER_COURSE_NOT_DO_TEST: 2, // CHƯA LÀM BÀI TEST
    USER_COURSE_APPLY_CERTIFICATE: 3 // ĐÃ APPLY
}

export const LECTURE_STATUS_OF_COURSE = {
    USER_LECTURE_NOT_ATTENDED: 0, // CHƯA HỌC
    USER_LECTURE_ATTENDING: 1, // ĐANG HỌC
    USER_LECTURE_COMPLETE: 2 // HOANG THÀNH
}

export const LECTURE_STATUS_OF_COURSE_MESSAGE = {
    USER_NOT_LOGIN: '詳細表示にはログインしてください',
    // USER_LECTURE_NOT_ATTENDED: 'このコンテンツを視聴するために講座に受講してください。', // CHƯA HỌC
    // USER_LECTURE_NOT_ATTENDED: 'このコンテンツを視聴するためには「受講ボタン」を押してください。', // CHƯA HỌC
    USER_LECTURE_NOT_ATTENDED: 'このコンテンツを視聴するためには「受講ボタン」を押してください。', // CHƯA HỌC
    USER_LECTURE_ATTENDING: '受講期間がまだ始まっておりません。受講期間をお確かめの上受講を開始してください。',
    USER_LECTURE_EXPIRED: '受講期限が切れました。このコンテンツを続けて視聴する為にチケットを購入してください。',
    COURSE_NOT_IN_TICKET_PLAN: '受講プランの該当講座しか受講できません。' 
}

export const PHARMACIST_CERTIFICATION ={
    TRAINING_CERTIFIED_PHARMACIST: 0,
    PSYCHIATRIC_SOCIETY_CERTIFIED_PHARMACIST: 1,
    CHINESE_HERBAL_MEDICINE_CRUDE_DRUG_CERTIFIED_PHARMACIST_FOR_RENEWAL_ONLY: 2,
    PEDIATRIC_DRUG_THERAPY_CERTIFIED_PHARMACIST_FOR_RENEWAL_ONLY: 3
}

export const PHARMACIST_CERTIFICATION_STRING ={
    TRAINING_CERTIFIED_PHARMACIST: '研修認定薬剤師',
    PSYCHIATRIC_SOCIETY_CERTIFIED_PHARMACIST: '精神薬学会認定薬剤師',
    CHINESE_HERBAL_MEDICINE_CRUDE_DRUG_CERTIFIED_PHARMACIST_FOR_RENEWAL_ONLY: '漢方薬・生薬認定薬剤師（更新のみ対象）',
    PEDIATRIC_DRUG_THERAPY_CERTIFIED_PHARMACIST_FOR_RENEWAL_ONLY: '小児薬物療法認定薬剤師（更新のみ対象）'
}

export const STATUS_STUDENT_GROUP = [
    {
        label: '全て',
        value: 0
    },
    {
        label: '未受講',
        value: 1
    },
    {
        label: '受講中',
        value: 2
    },
    {
        label: '受講完了',
        value: 3
    }
]

export const MESSAGE_CATEGORY = [
    {
        key: 1,
        value: '重要なお知らせ'
    },
    {
        key: 2,
        value: '受講期間終了のお知らせ'
    },
    {
        key: 7,
        value: '単位未申請のお知らせ'
    }
]

export const STUDENT_STATUS = {
    INACTIVE: 0,
    ACTIVE: 1,
    CONFIRMING: 2
}

export const STUDENT_STATUS_STRING = {
    ACTIVE: '有効',
    INACTIVE:'無効',
    CONFIRMING: '確認中'
}

export const COURSE_BY_STUDENT_STATUS = {
    ALL: {
        key: 0,
        label: '全て'
    },
    USER_COURSE_ATTENDING: {
        key: 1,
        label: '受講中',
        value: [0, 1, 2]
    },
    USER_COURSE_COMPLETE: {
        key: 2,
        label: '受講完了',
        value: [3]
    }
}

export const CREDIT_APPLICATION_STATUS = {
    NOT_COMPLETED: {
        value: COURSE_BY_STUDENT_STATUS.USER_COURSE_ATTENDING.value,
        label: ''
    },
    COMPLETED: {
        value: [...COURSE_BY_STUDENT_STATUS.USER_COURSE_COMPLETE.value],
        label: '単位申請済'
    }, 
    NO_APPLY: {
        label: '単位申請中'
    }
}

export const SOCKET_EVENTS = {
    NEW_MESSAGE: 'NEW_MESSAGE',
    JOIN_CONVERSATION: 'JOIN_CONVERSATION',
    LEAVE_CONVERSATION: 'LEAVE_CONVERSATION',
    TRACKING_NEW_MESSAGE: 'TRACKING_NEW_MESSAGE',
    LOGIN_ANOTHER_DEVICE: 'LOGIN_ANOTHER_DEVICE',
    REGISTER_CONFIRM_EMAIL: 'REGISTER_CONFIRM_EMAIL'
}

export const DOWNLOAD_ATTENDANCE_HISTORY_TYPE = {
    ALL: 0,
    SPECIAL: 1
}

export const PAYMENT_STATUS = {
    PROCESSING: 0,
    UNPAID: 1,
    PAID: 2,
    CANCEL_REQUEST: 3,
    CANCELED: 4
}

export const PAYMENT_STATUS_NAME = {
    PROCESSING: '処理中',
    UNPAID: '未払い',
    PAID: '支払済',
    CANCEL_REQUEST: 'キャンセルリクエスト中',
    CANCELED: 'キャンセル済'
}

export const COURSE_PERIOD_STATUS = {
    NOT_HAVE: 0,
    DUE: 1,
    ABOUT_TO_EXPIRE: 2,
    EXPIRED: 3
}

export const GENERAL_TYPE = {
    ABOUT_US: 'about_us',
    FAQS: 'faqs',
    USER_GUIDE: 'user_guide',
    RECOMMENDED_ENVIRONMENT: 'recommended_environment',
    TRANSACTION_LAW: 'transaction_law',
    CANCELLATION_POLICY: 'cancellation_policy',
    PRIVACY_POLICY: 'privacy_policy',
    TERM_SERVICE: 'term_service',
    ABOUT_COMPANY: 'about_company'
}

export const CREDIT_SELECT = {
    0: {
        label: '研修認定薬剤師',
        value: 0
    },
    1: {
        label: '精神薬学会認定薬剤師',
        value: 1
    },
    2: {
        label: '漢方薬・生薬認定薬剤師',
        value: 2
    },
    3: {
        label: '小児薬物療法認定薬剤師',
        value: 3
    }
}

export const CONFIRM_EMAIL_STATUS = {
    CONFIRM_EMAIL_SUCCESS: 1,
    CONFIRM_EMAIL_NOT_EXISTED_OR_EXPIRED_OR_ALREADY_CONFIRM: 2,
    CONFIRM_EMAIL_ALREADY_USE: 3
}

export const CONFIRM_EMAIL_STATUS_STRING = {
    1: 'メールアドレスが更新されました。これからは、新しいメールアドレスでログインして、システムを使用できます。',
    2: 'このリンクは有効期限が切れているか、すでに使用されています。',
    3: 'このメールアドレスはすでに別のアカウントで使用されています。新しいメールアドレスを更新してください。'
}

// type export pdf invoice
export const TYPE_EXPORT_PDF_INVOICE = {
    EXPORT_INVOICE_PDF_INDIVIDUAL: 0,
    EXPORT_INVOICE_PDF_GROUP: 1
}

// survey question type
export const SURVEY_QUESTION_TYPE = {
    TEXT: 1,
    RADIO: 2
}

// tool certificate
export const SETTING_TOOL_CERTIFICATE_TYPE = {
    NEW: 1,
    UPDATE: 2
}

export const SETTING_TOOL_CERTIFICATE_STATUS = {
    NOT_COMPLETE: 1,
    WARNING_COMPLETE: 2,
    COMPLETED: 3
}

export const SETTING_TOOL_CERTIFICATE_STATUS_MAPPER = {
    [SETTING_TOOL_CERTIFICATE_STATUS.NOT_COMPLETE]: {
        IMG: '/images/1-certificate-tool.svg',
        LABEL: 'C',
        TEXT_COLOR: 'red'
    },
    [SETTING_TOOL_CERTIFICATE_STATUS.WARNING_COMPLETE]: {
        IMG: '/images/2-certificate-tool.svg',
        LABEL: 'B',
        TEXT_COLOR: 'red'
    },
    [SETTING_TOOL_CERTIFICATE_STATUS.COMPLETED]: {
        IMG: '/images/3-certificate-tool.svg',
        LABEL: 'A',
        TEXT_COLOR: 'red'
    }
}

export const TIME_APPLY_COURSE_PERIOD = {
    IMMEDIATELY_AFTER_PURCHASE: {
        value: 1,
        label: '継続した受講期間を選択'
    },
    AFTER_THE_PREVIOUS_COURSE_PERIOD_EXPIRE: {
        value: 2,
        label: '継続した受講期間を選択',
        note: '※現在の受講期間終了後に新たな受講期間が自動的に開始されます。'
    },
    SELECT_COURSE_PERIOD: {
        value: 3,
        label: '今すぐ受講'
    }
}

export const THEME_TYPE = {
    NORMAL: 1,
    STORAGE: 2,
    NON_CERTIFICATE: 3
}

export const JOIN_ATTENDANCE_CODE_STEPS = {
    VALIDATE: 1,
    CONFIRM: 2
}
