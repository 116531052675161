import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'mobx-react';
import RootStore from './stores';
import 'react-circular-progressbar/dist/styles.css';
import momentTz from 'moment-timezone';

// set default timezone
momentTz.tz.setDefault('Asia/Tokyo');

// plugin css
import 'video.js/dist/video-js.css';

// global css
import './index.scss';
import './styles/fonts/font.css';
import './styles/navbar.css';
import './styles/style.css';
import './styles/responsive.css';
import './styles/custom.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={new RootStore()}>
        <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
