import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { messageCategories, SYSTEM_PATH } from '../../core/configs/constants';
// import { Breadcrumb } from '../../components';
import { useStore } from '../../core/utils/hook';
import Pagination from '../Courses/components/Pagination';
import MessageItem from './components/MessageItem';

function MessagesScreen() {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { isSubmitting }, getValues } = useForm();

    // store
    const {
        messageStore: { getAllMessages, allMessages, pagingAllMessage, cleanMessagePage },
        userStore: { onExtendCoursePeriod },
    } = useStore();

    useEffect(() => {
        cleanMessagePage();
        fetchData();
    }, []);

    const fetchData = (payload = {}) => {
        getAllMessages(payload, true);
    };

    // function
    const handleSearch = (data = {}) => {
        const { categoryId, notSeen, seen } = getValues();
        // let seenPayload = undefined;

        // if (notSeen) {
        //     seenPayload = '0';
        // }

        // if (seen) {
        //     seenPayload = '1';
        // }

        // if (seen && notSeen) {
        //     seenPayload = undefined;
        // }

        const payload = { ...data, categoryId, seen };

        fetchData(payload);
    }

    const handleReadMessage = (item) => () => {
        navigate(SYSTEM_PATH.MESSAGE + item.id);
    }

    return (
        <div id="main" className="wrapper  main-Message-list">
            {/* <Breadcrumb title='メッセージ一覧' /> */}
            <div className="content-message-list">
                <div className="container">
                    <h2 className="title-primary">メッセージ一覧</h2>
                    <div className="title-filter">
                        <div className="row">
                            <div className="col-md-3 col-sm-3 col-12">
                                <h4 className="title-3">合計: {pagingAllMessage?.totalRecord || 0}</h4>
                            </div>
                            <div className="col-md-9 col-sm-9 col-12">
                                <form onSubmit={handleSubmit(handleSearch)}>
                                    <div className="filter-right">
                                        <ul>
                                            <li className='msg-cate'>
                                                <select {...register('categoryId')}>
                                                    <option value="">全て</option>
                                                    {messageCategories.map(item => (<option key={item.id} value={item.id}>{item.name}</option>))}
                                                </select>
                                            </li>
                                            <li className='msg-seen'>
                                                <select {...register('seen')}>
                                                    <option value="">全て</option>
                                                    <option value="0">未読</option>
                                                    <option value="1">既読</option>
                                                </select>
                                            </li>
                                            <li className="li-filter">
                                                <button type="submit" disabled={isSubmitting}>検索</button>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-message">
                <div className="container">
                    {(allMessages || []).map((item) => {
                        return <MessageItem item={item} key={item.id} onRead={handleReadMessage} onExtendCoursePeriod={onExtendCoursePeriod}/>;
                    })}

                    {!allMessages?.length && <div className='no-data w-100'>
                        <p>データはありません</p>
                    </div>}

                    {allMessages?.length > 0 && <Pagination hideTotal paging={pagingAllMessage} onFetch={handleSearch} />}
                </div>
            </div>
        </div>
    );
}

export default observer(MessagesScreen);
