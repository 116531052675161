import moment from 'moment';
import {GENDER, WORK_FORM, WORK_FORM_STRING, GENDER_STRING, USER_TYPE, USER_TYPE_STRING,PATTERN_STRING_ONLY} from '../configs/constants';
/* eslint-disable no-useless-escape */
export const getStringAfterLastSlash = (str = '', char = '.', removeQuery = false) => {
    if (!str) return '';
    const n = str.lastIndexOf(char);
    let converted = str.substring(n + 1);
    if (removeQuery && converted.includes('?')) {
        converted = converted.split('?')[0] || ''
    }
    return converted;
};
  
export function generateRandomKey(max = 6) {
    // Declare a digits variable
    // which stores all digits
    var digits = '0123456789';
    let key = '';
    for (let i = 0; i < max; i++) {
        key += digits[Math.floor(Math.random() * 10)];
    }
    return key;
}

export function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function renameKeysObject(keysMap, objectData) {
    return Object.keys(objectData).reduce(
        (acc, key) => ({
            ...acc,
            ...{ [keysMap[key] || key]: objectData[key] }
        }),
        {}
    );
}

export function arrayUniqueByKey(arr = [], key = 'id') {
    return [...new Map(arr.map(item => [item[key], item])).values()];
}

export const handleKeyPressForNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^([0-9])*$/.test(keyValue)) {
        event.preventDefault();
    }
}

export const handlePasteForNumber = (event) => {
    if (!event.clipboardData.getData('text/plain').match(/^([0-9])*$/)) {
        event.preventDefault();
    }
}

export const handleKeyPressForHiragana = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^([-!$%^&*()_+|~=`{}[\]:\/;<>?,.@#0-9])+$/.test(keyValue)) {
        event.preventDefault();
    }
}

export const handlePasteForHiragana = (event) => {
    if (!event.clipboardData.getData('text/plain').match(/^[-!$%^&*()_+|~=`{}[\]:\/;<>?,.@#0-9]/)) {
        event.preventDefault();
    }
}

///Ký tự Hiragana, Katakana fullwidth 
export const handleKeyPressJapanText = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^[ぁ-んァ-ン]+$/.test(keyValue)) {
        event.preventDefault();
    }
}
///Ký tự Hiragana, Katakana fullwidth 
export const handlePasteJapanText = (event) => {
    if (!event.clipboardData.getData('text/plain').match(/^[ぁ-んァ-ン]+$/)) {
        console.log('bem');
        event.preventDefault();
    }
}

///Ký tự Hiragana, Katakana, number 
export const HKNumberReg = /^[ぁ-んァ-ン0-9]*$/;
export const handleKeyPressHKNumberText = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!HKNumberReg.test(keyValue)) {
        event.preventDefault();
    }
}

///Ký tự Hiragana, Katakana, number
export const handlePasteHKNumberText = (event) => {
    if (!event.clipboardData.getData('text/plain').match(HKNumberReg)) {
        event.preventDefault();
    }
}

///Ký tự Katakana, number 
export const KataNumberReg = /^(([ァ-ン]|ー)|[0-9])*$/;
export const handleKeyPressKataNumberText = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!KataNumberReg.test(keyValue)) {
        event.preventDefault();
    }
}

///Ký tự Katakana, number
export const handlePasteKataNumberText = (event) => {
    if (!event.clipboardData.getData('text/plain').match(KataNumberReg)) {
        event.preventDefault();
    }
}

///Ký tự Latin và Kanji
export const KanjiLatinhReg = /^([一-龥]|[a-zA-Z0-9 ])*$/;
export const handleKeyPressLatinText = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!KanjiLatinhReg.test(keyValue)) {
        event.preventDefault();
    }
}
///Ký tự Latin và Kanji
export const handlePasteLatinText = (event) => {
    if (!event.clipboardData.getData('text/plain').match(KanjiLatinhReg)) {
        event.preventDefault();
    }
}

export const getFileExtension = (filename) => {
    let ext = /^.+\.([^.]+)$/.exec(filename)
    return ext == null ? '' : `.${ext[1].toLowerCase()}`
}


export function isOtherNumber(c) {
    //eslint-disable-next-line
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (format.test(c)) {
        return format.test(c);
    }
    if (c.length != 1 || c == '_' || c == '$') return false;
    if (c.toUpperCase() != c.toLowerCase()) return true;
    if (c.charCodeAt(0) < 128) return false;
    try {
        eval('function ' + c + '(){}');
        return true;
    } catch {
        return false;
    }
}

export const spliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    while (arr.length > 0) {
        const chunk = arr.splice(0, chunkSize);
        res.push(chunk);
    }
    return res;
}

export const extractContent = (html = null) => {
    if(!html) return ''; 

    return new DOMParser()
        .parseFromString(html, 'text/html')
        .documentElement.textContent;
}

export function truncateByWords(str = '', index = 120) {
    if (!str) {
        return {
            showMore: false,
            text: ''
        };
    }

    return {
        showMore: str.length > index,
        text: str.length > index ?  str.substring(0, index) + '...' : str
    }
}

export const getGenderName = (status) => {
    switch (status) {
    case GENDER.MALE:
        return GENDER_STRING.MALE;
    case GENDER.FEMALE:
        return GENDER_STRING.FEMALE;
    default:
        return '';
    }
}

export const getWorkFormName = (workForm) => {
    switch (workForm) {
    case WORK_FORM.OFFSHORE:
        return WORK_FORM_STRING.OFFSHORE;
    case WORK_FORM.OUTSIDE:
        return WORK_FORM_STRING.OUTSIDE;
    case WORK_FORM.OTHER:
        return WORK_FORM_STRING.OTHER;
    default:
        return WORK_FORM_STRING.OTHER;
    }
}

export const getUserTypeName = (type) => {
    switch (type) {
    case USER_TYPE.GROUP_MANAGER:
        return USER_TYPE_STRING.GROUP_MANAGER;
    case USER_TYPE.JIHOU_ADMINISTRATOR:
        return USER_TYPE_STRING.JIHOU_ADMINISTRATOR;
    case USER_TYPE.STUDENT_GROUP:
        return USER_TYPE_STRING.STUDENT_GROUP;
    case USER_TYPE.STUDENT_INDIVIDUAL:
        return USER_TYPE_STRING.STUDENT_INDIVIDUAL;
    default:
        return '';
    }
}

export const handlePasteNumberField =(event) => {
    if (!event.clipboardData.getData('text/plain').match(PATTERN_STRING_ONLY)) {
        event.preventDefault();
    }
}

export const convertVideoDuration = (duration) => {
    return duration !== undefined && duration !== 0 ? numberWithCommas(Math.round(duration / 60)) : (duration || 0)
}

export const getDatesDifferenceTwoDates = (startDate, endDate) => {
    if(!startDate || !endDate) return 0;
    const duration = moment.duration(moment(endDate).diff(moment(startDate)));
    return duration.asDays();
}