import { observer } from 'mobx-react';
import { useStore } from '../../../core/utils/hook';
import { useEffect } from 'react';
import { SYSTEM_PATH, MSG } from '../../../core/configs/constants';
import { handleKeyPressForNumber, KataNumberReg, handlePasteForNumber } from '../../../core/utils/common';
import { useForm } from 'react-hook-form';
import yup from '../../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import { reloadEventsDOMContentLoaded } from '../../../core/utils/browser';
import { useNavigate } from 'react-router-dom';


const UpdateProfileGA = observer(() => {

    const navigate = useNavigate()
    const { userStore: { getProfile, updateProfile, profile } } = useStore();

    const validateSchema = yup.object().shape({
        firstName: yup.string().trim().required(MSG['error.required']).nullable(),
        lastName: yup.string().trim().required(MSG['error.required']).nullable(),
        firstNameFuri: yup.string().trim().required(MSG['error.required']).matches(KataNumberReg, MSG['error.format_hira_kata']).nullable(),
        lastNameFuri: yup.string().trim().required(MSG['error.required']).matches(KataNumberReg, MSG['error.format_hira_kata']).nullable(),
        groupName: yup.string().required(MSG['error.required']).nullable(),
        email: yup.string().required(MSG['error.required']).email(MSG['error.email_format']).nullable(),
        phoneNumber: yup.string().required(MSG['error.required']).typeError(MSG['error.number_positive'])
    })

    const { register, formState: { errors },
        getValues, setValue, handleSubmit, watch, trigger, reset } =
        useForm({ resolver: yupResolver(validateSchema), mode: 'onChange' })



    const onSubmit = (data) => {
        delete data.email;
        updateProfile(data).then(res => {
            if (res?.ok) {
                navigate('/profile')
            }
        });
    }

    useEffect(() => {
        reloadEventsDOMContentLoaded();
    }, [])


    useEffect(() => {
        if (!profile) return;
        let data = {
            firstName: profile?.firstName,
            lastName: profile?.lastName,
            firstNameFuri: profile?.firstNameFuri,
            lastNameFuri: profile?.lastNameFuri,
            groupName: profile?.groupName,
            email: profile?.email,
            phoneNumber: profile?.phoneNumber
        }
        reset(data);
    }, [profile])

    return (
        <div className="update-acount-left">
            <div className="title-title">
                <h2 className="title-2">受講管理者情報</h2>
            </div>
            <form className="h-adr" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-account">

                    <div className="box-form-account">
                        <div className="content-content">
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6">
                                        <div className="item-left">
                                            <label for="">姓: <span>*</span></label>
                                        </div>
                                        <div className="item-right">
                                            <input type="text"  {...register('firstName')} placeholder="学生" />
                                            {errors?.firstName?.message && <p className='text-error'>{errors?.firstName?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                        <div className="item-left">
                                            <label for="">名: <span>*</span></label>
                                        </div>
                                        <div className="item-right">
                                            <input type="text" {...register('lastName')} placeholder="学生" />
                                            {errors?.lastName?.message && <p className='text-error'>{errors?.lastName?.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6">
                                        <div className="item-left">
                                            <label for="">姓(フリガナ) <span>*</span></label>
                                        </div>
                                        <div className="item-right">
                                            <input type="text" {...register('firstNameFuri')} />
                                            {errors?.firstNameFuri?.message && <p className='text-error'>{errors?.firstNameFuri?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6">
                                        <div className="item-left">
                                            <label for="">名(フリガナ)<span>*</span></label>
                                        </div>
                                        <div className="item-right">
                                            <input type="text"  {...register('lastNameFuri')} />
                                            {errors?.lastNameFuri?.message && <p className='text-error'>{errors?.lastNameFuri?.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item item-11">
                                <div className="item-left">
                                    <label for="">メールアドレス<span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <input type="text" {...register('email')} disabled />
                                </div>
                            </div>
                            <div className="item ">
                                <div className="item-left">
                                    <label for="">電話番号 <span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <input type="text" {...register('phoneNumber')} 
                                        onKeyPress={handleKeyPressForNumber} 
                                        onPaste={handlePasteForNumber}/>
                                    {errors?.phoneNumber?.message && <p className='text-error'>{errors?.phoneNumber?.message}</p>}
                                </div>
                            </div>
                            <div className="item ">
                                <div className="item-left">
                                    <label for="">会社名 <span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <input type="text" {...register('groupName')}  disabled/>
                                    {errors?.groupName?.message && <p className='text-error'>{errors?.groupName?.message}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="link-button">
                        <button className="btn-1" onClick={() => navigate(SYSTEM_PATH.PROFILE)}>キャンセル</button>
                        <button className="btn-2" type='submit'>更新する</button>
                    </div>
                </div>
            </form>
        </div>
    )
})

export default UpdateProfileGA