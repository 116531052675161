import { observer } from 'mobx-react';
import { useStore } from '../../core/utils/hook';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProfileLayout from '../Profile/components/ProfileLayout';
import moment from 'moment';
import { DATE_TIME_YMD } from '../../core/configs/constants';
import QuestionItem from '../CompletionTest/components/QuestionItem';

const TestResultScreen = observer(() => {
    const { id } = useParams();

    // store
    const { courseStore: { getTestResultHistory, testResultHistory } } = useStore();

    // state
    const [testResult, setTestResult] = useState();
    const [testResults, setTestResults] = useState();

    // life cycle
    useEffect(() => {
        fetchData();
    }, [])

    // function
    const fetchData = async() => {
        const res = await getTestResultHistory(id);
        const sortTestResultHistory = res?.data?.testResultHistory.sort(compareDates);
        setTestResults(sortTestResultHistory);
        setTestResult(sortTestResultHistory[0]);
    }

    const compareDates = (a, b) => {
        const dateA = new Date(a.doTestDate);
        const dateB = new Date(b.doTestDate);
      
        return dateB - dateA;
    };

    const handleChangeSelectDoTestDate = (event) => {
        setTestResult(testResults[event.target.value]);
    }

    return (
        <ProfileLayout type='test-history-screen'>
            <div className='test-result-history'>
                <div className='test-result-title'>
                    <div className='title-primary text-left'><b>テスト結果履歴</b></div>
                </div>
                <div className='test-result-courseName mg-t-15'>
                    <div><b>講座名: </b><span>{`${testResult?.courseName}`}</span></div>
                </div>
                <div className='test-result-info d-flex align-items-center justify-content-between w-100 mg-t-5 height-80'>
                    <div className='test-result-percent'><b>正答率:</b> <b><span style={{ color: 'red'}}>{testResult?.achievement}%</span></b></div>
                    <div className='test-result-select' style={{fontSize: '16px'}}>
                        <b className='mg-r-18'>実施日:</b>
                        <select onChange={handleChangeSelectDoTestDate}>
                            {
                                testResults?.map((item, index) => {
                                    return (
                                        <option key={index} value={index}>{moment(item?.doTestDate).format(DATE_TIME_YMD)}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='test-result-detail wrapper main-execute-completion main-execute-completion-2'>
                    <div className='content-execute-completion'>
                        <div className='nav-execute-completion'>
                            {testResult?.questions?.map((item, index) => {
                                return (
                                    <QuestionItem
                                        isFinished={true}
                                        disabled={true}
                                        key={index} item={item} index={index} onChange={() => {}} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </ProfileLayout>
    )
})

export default TestResultScreen;