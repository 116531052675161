import { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Root from './routes/Root';
import { ReactNotifications } from 'react-notifications-component';
import { Loading, LoadingSrc, ReactModal, ScrollToTop } from './components';
import Modal from 'react-modal';
import SocketUtils from './core/utils/socket';
import { useStore } from './core/utils/hook';
import { observer } from 'mobx-react';

import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import './App.scss';


// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const App = observer(() => {

    const { 
        authStore: { userInfo, token, clearAuthentication, showLoadingSrc },
        modalStore: { openAlert },
        messageStore: { pushNewMessage },
        apiStore: { loading }
    } = useStore();

    useEffect(() => {
        window.history.scrollRestoration = 'manual';
    }, [])

    useEffect(() => {
        if (userInfo && token) {
            SocketUtils.getInstance().init({ token, userId: userInfo.id, onLogout, onNewMessage });
        }
    }, [userInfo, token]);

    const onLogout = () => {
        openAlert('重複してログインされたか、一定時間動作がないため自動的にログアウトしました。', () => {
            clearAuthentication()
        });
    }

    const onNewMessage = (payload) => {
        if (payload?.messageInfo && payload?.emails && payload.emails.includes(userInfo?.email)) {
            pushNewMessage(payload.messageInfo);
        }
    }

    return (
        <>
            <Suspense fallback=''>
                <ReactNotifications isMobile={true} breakpoint={500}/>
                <BrowserRouter>
                    <ScrollToTop>
                        <Routes>
                            <Route>
                                {/* public route - with out authenticate */}                         
                                {/* private route - with authenticate and base layout*/}
                                <Route path='/*' element={ <Root/> }/>
                            </Route>
                        </Routes>
                    </ScrollToTop>
                </BrowserRouter>
                {/* <Loading/> */}
                <ReactModal/>
                {
                    (showLoadingSrc || loading) && <LoadingSrc/>
                }
            </Suspense>
        </>
    );
})

export default App;
