import { useStore } from '../../../core/utils/hook';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useState } from 'react';
import { DATE_YMD_JP } from '../../../core/configs/constants';

const MessagePopup = observer(() => {
    // store
    const { messageStore: { messagePopupInfo, readMessagePopup }, modalStore: { hide } } = useStore()

    // state
    const [ isDonShowAgain, setIsDonShowAgain ] = useState();

    // function
    const handleClickClosePopup = () => {
        if (isDonShowAgain) {
            readMessagePopup(messagePopupInfo.id, null, true)
        }
        hide();
    }

    const handleCheckedDonShowAgain = (e) => {
        setIsDonShowAgain(e.target.checked)
    }

    return (
        <div className="message-popup-container modal-only-view-content">
            <div 
                style={{ fontFamily: '明朝体, Mincho, serif', color: 'white' }} 
                className="message-popup-title bg-warning height-45 d-flex align-items-center justify-content-center fs-22">
                <i className="fa-solid fa-bullhorn fs-30"></i><span className='mg-l-10 fw-bolder'>メッセージ</span>
            </div>
            <div className="message-popup-content pd-tb-20 pd-lr-15 max-height-400 max-width-600 overflow-auto">
                <div className='font-light fs-14'>{moment(messagePopupInfo.send_date).format(DATE_YMD_JP)}</div>
                <div className='font-bold fs-16'>{messagePopupInfo.title}</div>
                
                <div className="text-start mg-t-10 fs-16">{messagePopupInfo.content}</div>
            </div>
            <div className="message-popup-footer d-flex align-items-center justify-content-between border-top pd-lr-15 pd-tb-5">
                <div className='d-flex align-items-center'>
                    <input 
                        style={{height: '16px', width: '16px'}} 
                        className="mg-r-5" 
                        type="checkbox" 
                        id="isNoRepeat" 
                        onChange={(e) => handleCheckedDonShowAgain(e)} />
                    <label style={{fontSize: '16px'}} htmlFor="isNoRepeat"> 次回以降表示しない</label>
                </div>
                <button className="button-common d-flex align-items-center justify-content-center" onClick={handleClickClosePopup}>
                    <i className="fa-solid fa-xmark fs-22 mg-r-10"></i>
                    <span>閉じる</span>
                </button>
            </div>
        </div>
    )
})

export default MessagePopup;