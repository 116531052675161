import ApiService from './ApiService';

class MessageApi extends ApiService {

    constructor() {
        super('message');
    }

    getAllMessages(payload) {
        return this.get('', payload);
    }

    readMessages(payload = {}) {
        return this.put(payload.id + '/read', {});
    }

    getMessageDetail(payload = {}) {
        return this.get(payload.id, {});
    }

    sendMessage(payload = {}) {
        return this.post('send', payload);
    }

    getMessagePopup(payload) {
        return this.get('popup', payload)
    }

    readMessagePopup({ id, payload }) {
        return this.put(`read-popup/${id}`, {})
    }
}

export default MessageApi;