import io from 'socket.io-client';
import CONFIG from '../configs/config';
import { SOCKET_EVENTS } from '../configs/constants';

export default class SocketUtils {
    instance;
    socket = undefined;

    static getInstance() {
        if (!SocketUtils.instance) {
            SocketUtils.instance = new SocketUtils();
        }
        return SocketUtils.instance;
    }

    init({ token = '', userId, onLogout, onNewMessage }) {
        const http = CONFIG.ssl ? 'https' : 'http';
        const socketPath = http + '://' + CONFIG.socket_url;

        this.socket = io(socketPath, {
            transports: ['websocket'],
            forceNew: true,
            auth: { token },
            query: { userId },
            reconnection: true,
            reconnectionDelay: 500,
            // jsonp: false,
            agent: '-',
            pfx: '-',
            cert: '-',
            ca: '-',
            ciphers: '-',
            upgrade: false
        });

        this.socket.on('connect_error', (error) => {
            console.log('error: ', error, error.m);
            // ...
        });

        // this.socket.on('connect', () => {
        //     console.log('connect', this.socket.id); // ojIckSD2jqNzOqIrAGzL
        // });

        // this.socket.on('disconnect', () => {
        //     console.log('connect', this.socket.id); // ojIckSD2jqNzOqIrAGzL
        // });

        this.socket.on(SOCKET_EVENTS.NEW_MESSAGE, (payload) => {
            onNewMessage && onNewMessage(payload);
        })

        this.socket.on(SOCKET_EVENTS.LOGIN_ANOTHER_DEVICE, (payload) => {
            if (payload.userId === userId && token.replace('Bearer ', '') !== payload.newToken) {
                onLogout && onLogout();
            }
        })

        this.socket.on(SOCKET_EVENTS.REGISTER_CONFIRM_EMAIL, () => {
            onLogout && onLogout();
        })
    }

    joinRoom = (roomId) => {
        console.log('roomId: ', roomId);
        console.log('!this.socket: ', this.socket);
        if (!this.socket || !roomId) return;
        this.socket.emit(SOCKET_EVENTS.JOIN_CONVERSATION, roomId)
    }

    registerConfirmEmail=()=>{
        console.log(SOCKET_EVENTS.REGISTER_CONFIRM_EMAIL)
        // if (!this.socket) return;
        // console.log(SOCKET_EVENTS.REGISTER_CONFIRM_EMAIL)
        this.socket.emit(SOCKET_EVENTS.REGISTER_CONFIRM_EMAIL)
    }

    leaveRoom = (roomId) => {
        if (!this.socket || !roomId) return;
        this.socket.emit(SOCKET_EVENTS.LEAVE_CONVERSATION, roomId)
    }

    sendMessage = (payload) => {
        if (!this.socket) return;
        this.socket.emit(SOCKET_EVENTS.SEND_MESSAGE, payload)
    }
}
