import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormatNumber, VideoPlayer } from '../../components';
import { COURSE_PERIOD_STATUS, DATE_MD_JP, DATE_YMD_JP, SAMPLE_VIDEOS_CAROUSEL_INTERVAL, SYSTEM_PATH, USER_TYPE } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { onLoadAccordion, onLoadSwiper } from '../../core/utils/main';
import CourseCard from './components/CourseCard';
import NotifyList from './components/NotifyList';
import './style.scss';
import MessagePopup from './components/MessagePopup';
import moment from 'moment';

/* eslint-disable max-len */
const STDHomeScreen = observer((props) => {

    // other
    const navigate = useNavigate();

    // store
    const { 
        authStore: { token, userInfo }, 
        userStore: { getCoursesOfUser, coursesOfUser, userInfoHeader, coursePeriodStatus, coursePeriodRemaining, onAddTicketPlan, removeUserCourseByCoursePeriodExpireDate },
        themeStore: { getAllThemes, themes },
        courseStore: { getAllCourses, popularCourses, newCourses, cleanPagingHomeCourses },
        ticketStore: { getAllTickets, tickets },
        generalSettingStore: { getGeneralSetting, generalSetting: { faqs, sampleVideos } },
        modalStore: { show, hide },
        messageStore: { messagePopupInfo, getMessagePopup }
    } = useStore();

    // state
    const [ videoSamplePlayer, setVideoSamplePlayer ] = useState({});

    // lifecycle
    useEffect(() => {
        cleanPagingHomeCourses();
        getAllThemes();
        getAllCourses({ highlight: true, isHomeList: true }, 1);
        getAllCourses({ isHomeList: true }, 2);
        if(token) getCoursesOfUser();
        getAllTickets({ status: 1 });
        getGeneralSetting({type:'faqs'});
        if(!token) {
            getGeneralSetting({type:'sample_videos'});
        }
    }, [])

    useEffect(() => {
        // show message popup
        if (userInfo)  {
            fetchMessagePopup();
        }
    }, [])

    useEffect(() => {
        if(popularCourses?.length > 0) {
            onLoadSwiper('slider-ls');
        }
    }, [popularCourses])

    useEffect(() => {
        if(tickets?.length > 0) {
            onLoadSwiper('slider-price', '.swiper-scrollbar-price', '#navigation-right-1', '#navigation-left-1', 392, false, false, 4, 2.5, 1.25, 24);
        }
    }, [tickets])

    useEffect(() => {
        if(faqs?.length > 0) {
            onLoadAccordion();
        }
    }, [faqs])

    // function
    const fetchMessagePopup = async () => {
        const isGetMessagePopup = sessionStorage.getItem('isGetMessagePopup');
        if(isGetMessagePopup == 'true') {
            const res = await getMessagePopup();
            if (res?.data) {
                onShowMessagePopup();
            }
            sessionStorage.setItem('isGetMessagePopup', false);
        }
    }

    const navigateToPayment = (ticket) => {
        navigate(`/payment/${ticket.id}`,{ state: {...ticket} });
    }

    const onInitVideoPlayer = (player, index) => {
        setVideoSamplePlayer(prevState => ({
            ...prevState,
            [index]: player
        }))
    }

    const onChangeViewVideoSample = () => {
        if(videoSamplePlayer) {
            Object.values(videoSamplePlayer).forEach(player => player?.pause());
        }
    }

    const onShowMessagePopup = () => {
        show({
            id: 'modal-message-popup',
            isOpen: true,
            notButtonX: true,
            onCancel: () => hide(),
            children: (
                <MessagePopup />
            ),
            type: 'medium'
        })
    }

    return(
        <div id='main' className='wrapper'>
            <section className='Banner-Home'>
                {
                    token && !userInfoHeader?.numberTicket && userInfoHeader?.allowBuyTicket && (
                        !userInfoHeader?.totalTicket ? 
                            <div className='warning-not-ticket'>受講プランがまだございません。各講座を受講するために、<a role='button' className='color-link' onClick={onAddTicketPlan}>受講プランを購入</a>してください。</div>
                            :
                            <div className='warning-not-ticket'>受講プランがすべて使用されました。各講座を引き続き受講するために、<a role='button' className='color-link' onClick={onAddTicketPlan}>新しい受講プランを追加</a>してください。</div>

                    )
                }
                {
                    token && coursePeriodStatus === COURSE_PERIOD_STATUS.ABOUT_TO_EXPIRE &&
                    (
                        (userInfoHeader?.registerNewCoursePeriod && userInfoHeader?.coursePeriodNotApply) ? 
                            <div className='warning-not-ticket'>
                                残り{coursePeriodRemaining}日で受講期間満了となります。まだ単位数が残っている場合は受講を行ってください。受講中の講座は新たな受講期間で継続受講が可能です。
                            </div>
                            :
                            <div className='warning-not-ticket'>
                                残り{coursePeriodRemaining}日で受講期間満了となります。受講プランの全ての単位数の受講を完了させてください。
                            </div>
                    )
                }
                {
                    token && userInfoHeader?.registerNewCoursePeriod && userInfoHeader?.coursePeriodNotApply &&
                    <div className='warning-not-ticket'>
                        {moment(userInfoHeader.coursePeriodNotApply).format(DATE_YMD_JP)}よりご予約された新規の受講期間となります。
                    </div>
                }
                {
                    token && coursePeriodStatus === COURSE_PERIOD_STATUS.EXPIRED && removeUserCourseByCoursePeriodExpireDate &&
                    moment().isBefore(moment(removeUserCourseByCoursePeriodExpireDate)) && 
                    <div className='warning-not-ticket'>
                        {moment(removeUserCourseByCoursePeriodExpireDate).format(DATE_MD_JP)}までに受講プランをお申込みの場合、受講中で終了した講座の受講履歴から開始することができます。
                    </div>
                }
                {
                    !token &&
                    <>
                        <div className='img-banner banner-web'>
                            <a><img src='/images/banner-txt.png' alt='' /></a>
                        </div>
                        <div className='img-banner banner-mobile'>
                            <a><img src='/images/banner.png' alt='' /></a>
                        </div>
                        <div className='content-banner banner-mobile'>
                            <div className='content'>
                                <h2 className='title-1'>選ばれる薬剤師に一歩近づく！</h2>
                                <h2 className='title-1'>臨床力が身につくeラーニングサイト</h2>
                                <p className='desc'>elephamtのすべての配信コンテンツは研修認定薬剤師の単位対象講座となります。</p>
                            </div>
                        </div>
                    </>
                }
                {
                    token &&
                    <NotifyList/>
                }
            </section>
            {
                token &&
                <section className='new-home course-home course-of-user pd-t-75'>
                    <div className='container'>
                        <h2 className='title-primary'>受講中の講座</h2>
                        <div className='row'>
                            {
                                coursesOfUser?.length > 0 ?
                                    coursesOfUser.map(e => (
                                        <div key={e.id} className='col-md-4 col-sm-6 col-xs-12'>
                                            <CourseCard course={e}/>
                                        </div>
                                    ))
                                    :
                                    <div className='w-100'>
                                    データはありません
                                    </div>
                            }
                        </div>
                        {
                            coursesOfUser?.length > 0 &&
                            <div className=' item-readMore'>
                                <Link role={'button'} to={SYSTEM_PATH.ATTENDANCE_HISTORY}>もっと見る <img src='/images/icon3.svg' alt='' /></Link>
                            </div>
                        }
                    </div>
                </section>
            }
            <section id='theme-list' className='top-content'>
                <div className='container'>
                    <h2 className='title-primary'>テーマから講座を探す</h2>
                    <div className='content-top-content'>
                        <div className='row'>
                            {
                                themes?.length > 0 ?
                                    themes.map((theme, idx) => (
                                        <div key={theme.id} className='col-md-4 col-sm-6 col-xs-12 theme-item'>
                                            <Link role={'button'} className='text-decoration-none' to={`${SYSTEM_PATH.THEMES}?themeId=${theme.id}`}>{theme.name} ({theme.totalCourse})</Link>
                                        </div>
                                    ))
                                    :
                                    <div className='col-md-4 col-sm-4 col-xs-12'>
                                    データはありません
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            {/* SECTION SAMPLE VIDEOS */}
            {
                !token && sampleVideos?.length > 0 &&
                <section className='sample-videos'>
                    <h2 className='title-sample-video text-center mg-b-10'>サンプル動画</h2>
                    <div id='carouselSampleVideos' className='container carousel slide' data-bs-ride='carousel'>
                        {
                            sampleVideos.length > 1 && 
                            <div className='carousel-indicators'>
                                {
                                    sampleVideos.map((e, index) => (
                                        <button key={index} type='button' 
                                            data-bs-target='#carouselSampleVideos' data-bs-slide-to={index} 
                                            className={index === 0 ? 'active' : ''} 
                                            aria-current='true' 
                                            aria-label={`Slide ${index + 1}`}
                                            onClick={onChangeViewVideoSample}>
                                        </button>
                                    ))
                                }
                            </div>
                        }
                        <div className='carousel-inner'>
                            {
                                sampleVideos.map((video, index) => (
                                    <div key={index} className={classNames('carousel-item', index === 0 && 'active')} data-bs-interval={SAMPLE_VIDEOS_CAROUSEL_INTERVAL}>
                                        <VideoPlayer
                                            options={{
                                                sources: [
                                                    {
                                                        src: video.url,
                                                        type: video.url?.toLowerCase()?.includes('m3u8') ? 'application/x-mpegURL' : 'video/mp4'
                                                    }
                                                ]
                                            }}
                                            disableForward={false}
                                            onInitVideoPlayer={onInitVideoPlayer}
                                            indexVideoPlayer={index}
                                        />
                                    </div>
                                    
                                ))
                            }
                        </div>
                        <button className='carousel-control-prev' type='button' data-bs-target='#carouselSampleVideos' data-bs-slide='prev' onClick={onChangeViewVideoSample}>
                            <i className='carousel-control-icon fa-solid fa-chevron-left'></i>
                            <span className='visually-hidden'>Previous</span>
                        </button>
                        <button className='carousel-control-next' type='button' data-bs-target='#carouselSampleVideos' data-bs-slide='next' onClick={onChangeViewVideoSample}>
                            <i className='carousel-control-icon fa-solid fa-chevron-right'></i>
                            <span className='visually-hidden'>Next</span>
                        </button>
                    </div>
                </section>
            }
            {/* SECTION DOWNLOAD + SLIDE */}
            <section className='download-slide course-home'>
                <div className='container'>
                    <h2 className='title-primary'>人気の講座から探す</h2>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='wp-slide-ls slider-course'>
                                {
                                    popularCourses?.length > 0 ?
                                        <>
                                            <div className='btn-slide btn-left-slide' id='navigation-left'>
                                                <div className='img-btn-left'>
                                                    {/* <img src='/images/arrow.svg'> */}
                                                    prev
                                                </div>
                                            </div>
                                            <div className='btn-slide btn-right-slide' id='navigation-right'>
                                                <div className='img-btn-right'>
                                                    {/* <img src='/images/arrow.svg'> */}
                                                    next
                                                </div>
                                            </div>
                                            <div className='swiper-container slide-ls' id='slider-ls'>
                                                <div className='swiper-wrapper'>
                                                    {
                                                        popularCourses.map(e => (
                                                            <div key={e.id} className='swiper-slide'>
                                                                <CourseCard course={e}/>
                                                            </div>
                                                        ))
                                                    }
                                                    <div className='swiper-slide'>
                                                        <div className='item item-last'>
                                                            <div className='item-readMore'>
                                                                <Link role={'button'} to={SYSTEM_PATH.THEMES}>もっと見る <img src='/images/icon3.svg' alt='' /></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* scrollbar */}
                                            <div className='swiper-scrollbar' />
                                        </>
                                        :
                                        <div className='w-100'>
                                            データはありません
                                        </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='new-home course-home'>
                <div className='container'>
                    <h2 className='title-primary'>新しい講座から探す</h2>
                    <div className='row'>
                        {
                            newCourses?.length > 0 ?
                                newCourses.map(e => (
                                    <div key={e.id} className='col-md-4 col-sm-6 col-xs-12'>
                                        <CourseCard course={e}/>
                                    </div>
                                ))
                                :
                                <div className='w-100'>
                                データはありません
                                </div>
                        }
                    </div>
                    {
                        newCourses?.length > 0 &&
                        <div className=' item-readMore'>
                            <Link role={'button'} to={SYSTEM_PATH.THEMES}>もっと見る <img src='/images/icon3.svg' alt='' /></Link>
                        </div>
                    }
                </div>
            </section>
            {
                !token && 
                <section className='Price-home'>
                    <div className='container'>
                        {/* <div className='description'>
                        <p>弊社は著作権者からの委託を受け，書籍・雑誌の複製利用の諾否および複製使用料の回収業務を「一般社団法人出版者著作権管理機構（JCOPY）」に再委託しております。 </p>
                        <p>弊社書籍・雑誌を複製利用される際は，JCOPY にご連絡いただき，所定の手続きを行ってくださいますようお願いいたします弊社は著作権者からの委託を受け，書籍・雑誌の複製利用の諾否および複製使用料の回収業務を「一般社団法人出版者著作権管理機構（JCOPY）」に再委託しております。</p>
                    </div> */}
                        <div className='d-flex flex-gap-10 flex-wrap align-items-end'>
                            <h2 className='title-primary'>受講プランのご案内</h2>
                            <div className='warning-not-ticket pd-t-0 px-0 text-start'>＊お申込みの方は先に右上の「新規お申し込み」からアカウント登録をお願いします。</div>
                        </div>
                        <div className='content-price'>
                            {
                                tickets?.length > 0 ?
                                    <>
                                        <div className='btn-slide btn-left-slide' id='navigation-left-1'>
                                            <div className='img-btn-left'>
                                                {/* //<img src='/images/arrow.svg'> */}
                                            </div>
                                        </div>
                                        <div className='btn-slide btn-right-slide' id='navigation-right-1'>
                                            <div className='img-btn-right'>
                                                {/* //<img src='/images/arrow.svg'> */}
                                            </div>
                                        </div>
                                        <div className='slider'>
                                            <div className='slider-price' id='slider-price'>
                                                <div className='swiper-wrapper'>
                                                    {
                                                        tickets.map(e => (
                                                            <div key={e.id} className='swiper-slide'>
                                                                <div className='item ticket-card'>
                                                                    <h3>
                                                                        <div className='name-ticket'>{e.name}</div>
                                                                        <div>（{e.amount !== null ? <><FormatNumber value={e.amount}/>講座</> : '無制限'}）</div>
                                                                    </h3>
                                                                    <div className='nav-item'>
                                                                        <p className='price price-ticket'>
                                                                            <span><FormatNumber value={(e.price || 0) * 11 / 10}/>円</span>
                                                                            <span className='note-price-ticket'>(税込)</span>
                                                                        </p>
                                                                        <p className='desc-1'>受講期間: <span><FormatNumber value={e.duration}/>カ月</span></p>
                                                                        <p className='desc-2 height-desc-2 text-justify-inter-word'>{e.description}</p>
                                                                        {e.allowBuy ? <div role={'button'} className='read-more' onClick={()=> navigateToPayment(e)}><span>今すぐ申込む </span><img src='/images/ar1.png' /></div> : <></>}
                                                                        {/* <Link role={'button'} className='read-more' to={`/payment/${e.id}`}><span>今すぐ申込む </span><img src='/images/ar1.png' alt='' /></Link> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    
                                                    }
                                                    <div className={classNames('swiper-slide')}>
                                                        <div className='item item-last'>
                                                            <div className=' item-readMore'>
                                                                <Link role={'button'} to={SYSTEM_PATH.TICKETS}>もっと見る <img src='/images/icon3.svg' alt='' /></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            {/* scrollbar */}
                                            <div className='swiper-scrollbar-price' />
                                        </div>
                                    </>
                                    :
                                    <div className='mg-t-30 mg-b-95'>
                                データはありません
                                    </div>
                            }
                        </div>
                    </div>
                </section>
            }
            <section className='section-fqa'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3 col-sm-3 col-xs-12'>
                            <h2 className='title-primary'>よくある質問</h2>
                            {
                                faqs?.length > 0 &&
                                <div className=' item-readMore'>
                                    <Link role={'button'} to={SYSTEM_PATH.FAQ_SCREEN}>もっと見る <img src='/images/icon3.svg' alt='' /></Link>
                                </div>
                            }
                        </div>
                        <div className='col-md-9 col-sm-9 col-xs-12'>
                            <div className='section-fqa-right'>
                                {/* Accordion */}
                                <div className='acc-container'>
                                    {
                                        faqs?.length > 0 ?
                                            faqs.slice(0, 5).map(e => (
                                                <div key={e.id} className='acc'>
                                                    <div className='acc-head'>
                                                        <p className='title-2'><span className='faq-question'>Q</span>{e.question}</p>
                                                    </div>
                                                    <div className='acc-content'>
                                                        <p className='title-3'> <span className='faq-answer'>A</span><div dangerouslySetInnerHTML={{__html: e.answer}}></div></p>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className='mg-t-20'>データはありません</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
})

export default STDHomeScreen;