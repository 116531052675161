import ApiService from './ApiService';

class LectureApi extends ApiService {
    // constructor() {
    //     super('lecture');
    // }

    getAllLectures(payload) {
        return this.get('lecture/all', payload);
    }

    getLectureDetail(payload) {
        return this.get('lecture/' + payload?.id + '/internal', {});
    }

    getRelatedLectures(payload = {}) {
        let uri = 'lecture-related/course/' + payload.courseId;
        if (payload.lectureId) {
            uri += `?lectureId=${payload.lectureId}`;
        }
        return this.get(uri, {});
    }

    updateVideoTimeViewed(payload = {}) {
        return this.put(`lecture/${payload.lectureId}/time-view`, { timeView: payload.timeView });
    }
}

export default LectureApi;