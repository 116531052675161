const TBDScreen = () => {

    return (
        // This function is still under development
        <div className='pd-30'>
            本機能がまだ開発されています。
        </div>
    );
}

export default TBDScreen;
