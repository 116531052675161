import { action, flow, makeObservable, observable } from 'mobx';
import { UserApi } from '../apis';
import BaseStore from './BaseStore';
import ReactNotifications from '../components/Notifications/ReactNotifications';
import { COURSE_PERIOD_STATUS, DATE_YMD_ISO, MSG, SYSTEM_PATH } from '../core/configs/constants';
import { getDatesDifferenceTwoDates } from '../core/utils/common';
import moment from 'moment';
import AddTicketPlanModal from '../components/Container/AddTicketPlanModal';

class UserStore extends BaseStore {

    userInfoHeader = {};
    coursePeriodStatus = COURSE_PERIOD_STATUS.NOT_HAVE;
    coursePeriodRemaining = 0;
    removeUserCourseByCoursePeriodExpireDate = null;

    coursesOfUser = [];
    pagingCoursesOfUser = {
        ...this.paging,
        page: 1,
        size: 6
    }

    listPurchaseHistories = [];
    pagingPurchaseHistory = {
        ...this.paging,
        page: 1,
        size: 6
    }

    profile = null;

    toolCertificate = null;

    constructor(rootStore) {
        super();
        makeObservable(this, {
            userInfoHeader: observable,
            coursePeriodStatus: observable,
            coursePeriodRemaining: observable,
            removeUserCourseByCoursePeriodExpireDate: observable,
            coursesOfUser: observable,
            pagingCoursesOfUser: observable,
            profile:observable,
            toolCertificate: observable,
            getUserInfoHeader: flow.bound,
            getCoursesOfUser: flow.bound,
            getProfile: flow.bound,
            updateProfile: flow.bound,
            addAttendanceCode: flow.bound,
            validateAddAttendanceCode: flow.bound,
            changePassword: flow.bound,
            // purchase history
            getPurchaseHistories: flow.bound,
            deletePurchase: flow.bound,
            cancelPurchase: flow.bound,
            listPurchaseHistories: observable,
            pagingPurchaseHistory: observable,
            // tool certificate
            getToolCertificate: flow.bound,
            getSettingToolCertificate: flow.bound,
            saveToolCertificate: flow.bound,
            resetToolCertificate: flow.bound,
            onAddTicketPlan: action.bound,
            onExtendCoursePeriod: action.bound
        })
        this.rootStore = rootStore;
        this.api = new UserApi();
    }

    *getUserInfoHeader(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getUserInfoHeader, payload);
            if(res?.ok) {
                this.userInfoHeader = res?.data;
                if(!res?.data?.coursePeriodStart || !res?.data?.coursePeriodEnd) {
                    this.coursePeriodStatus = COURSE_PERIOD_STATUS.NOT_HAVE;
                } else {
                    const diffDate = getDatesDifferenceTwoDates(moment().format(DATE_YMD_ISO), res?.data?.coursePeriodEnd);
                    if(diffDate >= 0 && diffDate <= 30) {
                        this.coursePeriodStatus = COURSE_PERIOD_STATUS.ABOUT_TO_EXPIRE;
                        this.coursePeriodRemaining = diffDate;
                    } else if (diffDate < 0) {
                        this.coursePeriodStatus = COURSE_PERIOD_STATUS.EXPIRED;
                        this.removeUserCourseByCoursePeriodExpireDate = moment(res?.data?.coursePeriodEnd).add(1, 'month').add(2, 'days');
                    } else {
                        this.coursePeriodStatus = COURSE_PERIOD_STATUS.DUE;
                    }
                }
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *getCoursesOfUser(searchParams) {
        try {
            const { size, page, sortDir, sortKey } = this.pagingCoursesOfUser;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getCoursesOfUser, payload);
            if(res?.ok) {
                this.coursesOfUser = res?.data?.elements;
                this.setAttrObservable('pagingCoursesOfUser', res?.data?.paginate, true, false);
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *getProfile() {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getProfile,{});
            if(res?.ok) {
                this.profile = res?.data;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *updateProfile(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateProfile,payload || {});
            if(res?.ok) {
                ReactNotifications('success', MSG['inform.success.update.profile']);
            }
            return res;
        } catch (error) {
            console.log(error);
        }
    }

    *getPurchaseHistories(searchParams) {
        try {
            const { size, page, sortDir, sortKey } = this.pagingPurchaseHistory;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getPurchaseHistories, payload);
            if(res?.ok) {
                this.listPurchaseHistories = res?.data?.elements;
                this.setAttrObservable('pagingPurchaseHistory', res?.data?.paginate, true, false);
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *addAttendanceCode(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.addAttendanceCode, payload);
            if(res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *validateAddAttendanceCode(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.validateAddAttendanceCode, payload);
            if(res?.ok) {
                return res?.data || {};
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *changePassword(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.changePassword, payload);
            if(res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *deletePurchase(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.deletePurchase, payload);
            if(res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *cancelPurchase(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.cancelPurchase, payload);
            if(res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    onAddTicketPlan() {
        const { allowBuyTicket, displayAttendanceCode } = this.userInfoHeader;
        if(displayAttendanceCode) {
            this.rootStore.modalStore.hideAll();
            this.rootStore.modalStore.show({
                id: 'modal-add-ticket-plan',
                isOpen: true,
                header: '受講プランを購入',
                onCancel: () => this.rootStore.modalStore.hide(),
                children: (
                    <AddTicketPlanModal method={{ ticketMethod: allowBuyTicket, attendanceMethod: true }}/>
                ),
                type: 'small'
            })
        } else {
            window.location.href = SYSTEM_PATH.TICKETS;
        }
    }

    onExtendCoursePeriod = (e) => {
        e.stopPropagation();
        const { coursePeriodIsAboutToExpire, registerNewCoursePeriod } = this.userInfoHeader;
        if(coursePeriodIsAboutToExpire) {
            this.rootStore.modalStore.hideAll();
            if(!registerNewCoursePeriod) {
                this.rootStore.modalStore.show({
                    id: 'modal-add-ticket-plan',
                    isOpen: true,
                    header: '受講プランを購入',
                    onCancel: () => this.rootStore.modalStore.hide(),
                    children: (
                        <AddTicketPlanModal method={{ ticketMethod: true, attendanceMethod: true }}/>
                    ),
                    type: 'small'
                })
            } else {
                this.rootStore.modalStore.openAlert('一つの受講プランが既に申込まれたため、別の受講プランを申込むことができません。');
            }
        }
        
    }

    // tool certificate
    *getToolCertificate(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getToolCertificate, payload);
            if(res?.ok) {
                this.toolCertificate = res?.data;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *getSettingToolCertificate(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getSettingToolCertificate, payload);
            if(res?.ok) {
                this.toolCertificate = res?.data;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *saveToolCertificate(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.saveToolCertificate, payload);
            if(res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *resetToolCertificate(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.resetToolCertificate, payload);
            if(res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }
}

export default UserStore;