import ApiService from './ApiService';

class PaymentApi extends ApiService {

    constructor() {
        super();
    }

    pushToGMO(payload) {
        return this.post('payment/url', payload);
    }

    pushToPaymentWhenOrderFree(payload) {
        return this.post('payment/payment-callback', payload);
    }

    checkStatusOrder(orderID) {
        return this.get(`payment/${orderID}`);
    }

    exportInvoicePDF({ id, payload }) {
        return this.post(`payment/export-invoice/${id}`, payload, null, 'blob')
    }
}

export default PaymentApi;