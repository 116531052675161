import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { MSG, SYSTEM_PATH } from '../../core/configs/constants';
import { isOtherNumber } from '../../core/utils/common';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import { useParams } from 'react-router-dom';

const selectOthers = 'その他'

export const SendInquiryScreen = observer(() => {
    // store
    const {
        modalStore: { openAlert },
        inquiryStore: { sendInquiry },
        authStore:{ token, userInfo },
        userStore:{ profile , getProfile}
    } = useStore();

    const [optionSelect, setOptionSelect] = useState([
        {
            id: 1,
            value: 'お申込みについて'
        },
        {
            id: 2,
            value: 'お支払いについて'
        },
        {
            id: 3,
            value: '受講関連について'
        },
        {
            id: 4,
            value: 'コンテンツ内容について'
        },
        {
            id: 5,
            value: '単位取得について'
        },
        {
            id: 6,
            value: '操作方法について'
        },
        {
            id: 8,
            value: 'その他'
        }
    ])

    useEffect(() => {
        token && getProfile()
    }, [])

    useEffect(() => {
        if(token) return;
        const optionSelectWhenForgotPassword = [...optionSelect]
        optionSelectWhenForgotPassword[5] = {
            id: 7,
            value: 'メールアドレス忘れまたは変更について'
        }
        setOptionSelect(optionSelectWhenForgotPassword)
    }, [])
    

    const navigate = useNavigate();

    const validateSendInquirySchema = yup.object().shape({
        lastName: yup.string().required(MSG['error.required']),
        firstName: yup.string().required(MSG['error.required']),
        email: yup.string().required(MSG['error.required']).email(MSG['error.email_format']),
        phone: yup.string(),
        pharmaNumber: yup.string(),
        optionSelected: yup.string(),
        message: yup.string().required(MSG['error.required']),
        others: yup.string().when('optionSelected', {
            is: selectOthers,
            then: yup.string().required(MSG['error.required']),
            otherwise: yup.string()
        })
    });
    const [optionSelected, setOptionSelected] = useState(optionSelect[0].value);

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setValue,
        resetField
    } = useForm({
        resolver: yupResolver(validateSendInquirySchema),
        mode: 'onChange'
    });

    const onSendInquiry = async (data) => {
        data.title = optionSelected;
        const {firstName,email,lastName,message,phone,title,others,pharmaNumber} = data

        const body = {
            userId: profile?.id || null,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phone || '',
            title: title == selectOthers ? others : (title || optionSelected),
            content: message,
            pharmaNumber: pharmaNumber || null
        };

        await sendInquiry(body,()=>{
            openAlert(MSG['inform.success.create'], () => {
                resetField('message')
                resetField('others')
                resetField('phone')
                resetField('pharmaNumber')
                setOptionSelected(optionSelect[0].value)
                setValue('optionSelected', optionSelect[0].value);
                // navigate(SYSTEM_PATH.HOME);
            });
        })
    };

    useEffect(() => {
        setValue('lastName', profile?.lastName)
        setValue('firstName', profile?.firstName)
        setValue('email', profile?.email)
        setValue('phone', profile?.phone)
    }, [profile])
    
    // 

    const setValueSelectOption = (e) => {
        const itemSelected = e.target;
        setOptionSelected(itemSelected.value);
        setValue('optionSelected', itemSelected.value);
    };

    if(token && profile == null) return;

    return (
        <div
            id="main"
            className="wrapper main-account-trgisstration-1 main-account-trgisstration-2 main-account-trgisstration-4 main-update main-send-inquiry"
        >
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li>
                            <a href={SYSTEM_PATH.HOME}>
                                <img src="/images/icon-home.svg" alt="" />
                            </a>
                        </li>
                        <li className="dash">\</li>
                        <li>お問い合わせ</li>
                    </ul>
                </div>
            </div>
            <div className="content-signin">
                <div className="container">
                    <div className="title-title">
                        <h2 className="title-2">お問い合わせ</h2>
                        <p className="desc-2">
              まずは、<a role={'button'} href onClick={()=>navigate(SYSTEM_PATH.FAQ_SCREEN)} >「よくある質問」</a>
              をお読みいただき、その他のご質問、ご不明な点がございましたら、下記に必要項目をご記入ください。
                        </p>
                        {token && <p className="desc-2">
              送信前には、必ず <a role={'button'} href onClick={()=>navigate(SYSTEM_PATH.PRIVACY_POLICY)}>「個人情報保護方針」</a>
              をご確認ください。
                        </p>}
                    </div>
                    <div className="form-account">
                        <div className="box-form-account" id='box-form-account-inquiry'>
                            <div className="content-content">
                                <div className="item">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <div className="item-left">
                                                <label htmlFor>
                          姓 <span>*</span>
                                                </label>
                                            </div>
                                            <div className="item-right">
                                                <input
                                                    type="text"
                                                    placeholder
                                                    disabled={!!profile}
                                                    defaultValue={profile && profile.firstName}
                                                    {...register('firstName')}
                                                />
                                            </div>
                                            {errors?.firstName && (
                                                <div className="text-danger mg-t-5">
                                                    {errors.firstName?.message}
                                                </div>
                                            )}
                                            
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <div className="item-left">
                                                <label htmlFor>
                          名 <span>*</span>
                                                </label>
                                            </div>
                                            <div className="item-right">
                                                <input
                                                    type="text"
                                                    placeholder
                                                    disabled={!!profile}
                                                    defaultValue={profile && profile.lastName}
                                                    {...register('lastName')}
                                                />
                                            </div>
                                            {errors?.lastName && (
                                                <div className="text-danger mg-t-5">
                                                    {errors.lastName?.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <div className="item-left">
                                                <label htmlFor>
                          メールアドレス<span>*</span>
                                                </label>
                                            </div>
                                            <div className="item-right">
                                                <input 
                                                    type="text"
                                                    placeholder 
                                                    disabled={!!profile}
                                                    defaultValue={profile && profile.email}
                                                    {...register('email')}
                                                />
                                            </div>
                                            {errors?.email && (
                                                <div className="text-danger mg-t-5">
                                                    {errors.email?.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <div className="item-left">
                                                <label htmlFor>電話番号 </label>
                                            </div>
                                            <div className="item-right">
                                                <input
                                                    type="text"
                                                    placeholder
                                                    maxLength={20}
                                                    defaultValue={profile && profile.phone}
                                                    {...register('phone')}
                                                    onKeyPress={(e) => {
                                                        if (isOtherNumber(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item item-11">
                                    <div className="item-left">
                                        <label htmlFor>
                      カテゴリーをお選びください <span>*</span>
                                        </label>
                                    </div>
                                    <div className="item-right">
                                        <select
                                            value={optionSelected}
                                            name
                                            id={'selectOption'}
                                            onChange={setValueSelectOption}
                                        >
                                            {optionSelect.map((option) => {
                                                return (
                                                    <option key={option.id} value={option.value}>{option.value}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                {optionSelected == 'メールアドレス忘れまたは変更について' && (
                                    <div className="item item-11">
                                        <div className="item-left">
                                            <label htmlFor>
                                            薬剤師名簿登録番号
                                            </label>
                                        </div>
                                        <div className="item-right">
                                            <input
                                                className='bg-white'
                                                type="text"
                                                onCopy={(e) => e.preventDefault()}
                                                onPaste={(e) => e.preventDefault()}
                                                onCut={(e) => e.preventDefault()}
                                                maxLength={100}
                                                placeholder
                                                // defaultValue={profile && profile.phone}
                                                {...register('pharmaNumber')}
                                                // onKeyPress={(e) => {
                                                //     if (isOtherNumber(e.key)) {
                                                //         e.preventDefault();
                                                //     }
                                                // }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {optionSelected == 'その他' && (
                                    <div className="item ">
                                        <div className="item-left">
                                            <label htmlFor>
                                            問合せ件名をご入力ください <span>*</span>
                                            </label>
                                        </div>
                                        <div className="item-right">
                                            <textarea
                                                name
                                                id
                                                cols={30}
                                                rows={10}
                                                maxLength={999}
                                                defaultValue={''}
                                                {...register('others')}
                                                style={{
                                                    maxHeight:200,
                                                    minHeight:100,
                                                    lineHeight:'normal',
                                                    paddingLeft:'20px',
                                                    paddingRight:'20px',
                                                    paddingTop:'10px'
                                                }}
                                            />
                                        </div>
                                        {errors?.others && (
                                            <div className="text-danger mg-t-5">
                                                {errors.others?.message}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="item ">
                                    <div className="item-left">
                                        <label htmlFor>
                      メッセージ <span>*</span>
                                        </label>
                                    </div>
                                    <div className="item-right">
                                        <textarea
                                            name
                                            id
                                            cols={30}
                                            rows={10}
                                            defaultValue={''}
                                            maxLength={999}
                                            placeholder={'メッセージをご記入ください'}
                                            {...register('message')}
                                            style={{
                                                maxHeight:200,
                                                minHeight:100,
                                                lineHeight:'normal',
                                                paddingLeft:'20px',
                                                paddingRight:'20px',
                                                paddingTop:'10px'
                                            }}
                                        />
                                    </div>

                                    {errors?.message && (
                                        <div className="text-danger mg-t-5">
                                            {errors.message?.message}
                                        </div>
                                    )}
                                </div>
                                <div className="link-button">
                                    <button
                                        role={'button'}
                                        onClick={handleSubmit(onSendInquiry)}
                                        className="btn-2"
                                    >
                    メッセージ送信
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
