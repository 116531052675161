import { observer } from 'mobx-react';
import ProfileLayout from './components/ProfileLayout';
import { useStore } from '../../core/utils/hook';
import { useEffect } from 'react';
import { USER_TYPE, SYSTEM_PATH, DATE_YMD } from '../../core/configs/constants';
import moment from 'moment';
import { getWorkFormName, getGenderName, getUserTypeName } from '../../core/utils/common';
import { Link } from 'react-router-dom';

const ProfileViewScreen = observer(() => {

    const { userStore: { getProfile, profile } } = useStore();

    useEffect(() => {
        getProfile();
    }, []);

    return (
        <ProfileLayout>
            <div className="detail-count-info-right">
                <div className="title-title">
                    <h2 className="title-2"> {profile?.roleId == USER_TYPE.GROUP_MANAGER ? '受講管理者情報': 'ユーザー情報'}</h2>
                    <Link to={SYSTEM_PATH.PROFILE_EDIT} className="edit"><i className="fa-regular fa-pen-to-square"></i>編集する</Link>
                </div>
                <div className="content-student-detail">
                    <div className="item">
                        <div className="left">
                            <span>名前</span>
                        </div>
                        <div className="right">
                            <span>{`${profile?.firstName} ${profile?.lastName}`}</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="left">
                            <span>名前(フリガナ)</span>
                        </div>
                        <div className="right">
                            <span>{`${profile?.firstNameFuri} ${profile?.lastNameFuri}`}</span>
                        </div>
                    </div>
                    {profile?.roleId != USER_TYPE.GROUP_MANAGER &&
                        <>
                            <div className="item">
                                <div className="left">
                                    <span>性別</span>
                                </div>
                                <div className="right">
                                    <span>{getGenderName(profile?.gender)}</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="left">
                                    <span>生年月日</span>
                                </div>
                                <div className="right">
                                    <span>{profile?.birthday ? moment(profile?.birthday).format(DATE_YMD) : ''}</span>
                                </div>
                            </div>
                        </>
                    }
                    <div className="item">
                        <div className="left">
                            <span>メールアドレス</span>
                        </div>
                        <div className="right">
                            <span>{profile?.email}</span>
                        </div>
                        {
                            profile?.newEmail &&
                            <div className="text-danger fs-error mg-t-5 left w-100" 
                                style={{fontSize: '12px', fontWeight: 'bolder'}}>※ メールアドレスを{profile.newEmail}に変更するように要求しました 。<br/>
                                新しいメールアドレスの確認リンクを記載したメールが届きます。再ログインする前に、このメールを開いてリンクをクリックしてください。
                            </div>
                        }
                    </div>
                    {profile?.roleId != USER_TYPE.GROUP_MANAGER &&
                        <>
                            <div className="item">
                                <div className="left">
                                    <span>薬剤師名簿登録番号</span>
                                </div>
                                <div className="right">
                                    <span>{profile?.pharmacistNumber}</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="left">
                                    <span>沖/外選択</span>
                                </div>
                                <div className="right">
                                    <span>{getWorkFormName(profile?.workForm)}</span>
                                </div>
                            </div>
                        </>
                    }
                    <div className="item">
                        <div className="left">
                            <span>電話番号</span>
                        </div>
                        <div className="right">
                            <span>{profile?.phoneNumber}</span>
                        </div>
                    </div>
                    {profile?.roleId == USER_TYPE.GROUP_MANAGER &&
                        <div className="item">
                            <div className="left">
                                <span>会社名</span>
                            </div>
                            <div className="right">
                                <span>{profile?.groupName}</span>
                            </div>
                        </div>
                    }

                    {profile?.roleId != USER_TYPE.GROUP_MANAGER &&
                        <>
                            <div className="item">
                                <div className="left">
                                    <span>郵便番号</span>
                                </div>
                                <div className="right">
                                    <span>{profile?.zipCode}</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="left">
                                    <span>都道府県</span>
                                </div>
                                <div className="right">
                                    <span>{profile?.prefecture}</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="left">
                                    <span>住所</span>
                                </div>
                                <div className="right">
                                    <span>{`${profile?.address1} ${profile?.address2}`}</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="left">
                                    <span>勤務業態</span>
                                </div>
                                <div className="right">
                                    <span>{profile?.workType}</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="left">
                                    <span>ユーザーの種類</span>
                                </div>
                                <div className="right">
                                    <span>{getUserTypeName(profile?.roleId)}</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="left">
                                    <span>所属会社名</span>
                                </div>
                                <div className="right">
                                    <span>{profile?.groupName}</span>
                                </div>
                            </div>

                            <div className="item">
                                <div className="left">
                                    <span>所属会社名(フリガナ)</span>
                                </div>
                                <div className="right">
                                    <span>{profile?.groupNameFuri}</span>
                                </div>
                            </div>
                            {/* <div className="item">
                                <div className="left">
                                    <span>取得を希望する薬剤師認定制度</span>
                                </div>
                                <div className="right">
                                    <span>{getCertifiCationOptionName(profile?.pharmacistCertification)}</span>
                                </div>
                            </div> */}
                        </>
                    }
                </div>
            </div>
        </ProfileLayout>)
})

export default ProfileViewScreen;