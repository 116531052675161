/* eslint-disable max-len */
import classNames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import LectureApi from '../../apis/LectureApi';
import { VideoPlayer } from '../../components';
import { DATE_YMD, SYSTEM_PATH } from '../../core/configs/constants';
import { useIsActiveTab } from '../../core/utils/tabs';
import LectureContent from './components/LectureContent';
import RelatedLectures from './components/RelatedLectures';
import NotFoundScreen from '../404/NotFoundScreen';

function LectureDetailScreen() {
    const [lecture, setLecture] = useState({});
    const api = new LectureApi();

    // const {
    //     lectureStore: { getLectureDetail }
    // } = useStore();

    const params = useParams();
    const { checkActiveTab, isActiveTab } = useIsActiveTab();

    useEffect(() => {
        if (!checkActiveTab) {
            fetchData(params?.id);
        }
    }, [params?.id, checkActiveTab]);

    const fetchData = async (id) => {
        try {
            const lecture = await api.getLectureDetail({ id });
            setLecture(lecture?.data?.data || null);
        } catch (error) {
            setLecture(null);
            console.log('error: ', error);
        }
    };

    const handleRefresh = () => {
        fetchData(params?.id);
    }

    const handleTimeUpdate = useCallback(
        async (timeView, refresh) => {
            if (params?.id) {
                const lectureId = params.id;
                try {
                    const res = await api.updateVideoTimeViewed({ timeView, lectureId });
                    if (refresh && res.status !== 2) {
                        fetchData(params?.id)
                    }
                } catch (error) {
                    setLecture(null);
                    console.log('error: ', error);
                }
            }
        },
        [params?.id]
    );

    const isM3u8 = useMemo(() => {
        return lecture?.video && lecture.video.toLowerCase().includes('m3u8');
    }, [lecture]);

    if (isActiveTab) {
        return <div className="pd-30">同時に複数のコンテンツが開いています。 連続視聴するには、関連するタブを閉じてページを更新してください。</div>;
    }

    if (!lecture) return <div style={{ height: '68vh' }}><NotFoundScreen/></div>;

    return (
        <div id="main" className="wrapper main-execute-completion main-view-lecture">
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li>
                            <a href="/">
                                <img src="/images/icon-home.svg" alt="" />
                            </a>
                        </li>
                        {/* selected theme name */}
                        <li className="dash">\</li>
                        <li>
                            <a href={SYSTEM_PATH.THEMES + `?themeId=${lecture.themeId}`}>{lecture.themeName}</a>
                        </li>
                        {/* selected course name */}
                        <li className="dash">\</li>
                        <li>
                            <a href={SYSTEM_PATH.COURSE + lecture.courseId}>{lecture.courseName}</a>
                        </li>
                        <li className="dash">\</li>
                        <li>{lecture.name}</li>
                    </ul>
                </div>
            </div>

            <div className="content-view-lecture">
                <div className="container">
                    <h2 className={classNames('title-primary', lecture?.isNew && 'icon-lecture-new')}>{lecture.name}</h2>
                    <p className="date">
                        <span>公開日: </span>
                        {moment(lecture.publicDate).format(DATE_YMD)}
                    </p>
                    {lecture.video && (
                        <div className="iframe-youtube">
                            {
                                !lecture?.isOldCourse ? 
                                    <VideoPlayer
                                        options={{
                                            sources: [
                                                {
                                                    src: lecture.video,
                                                    type: isM3u8 ? 'application/x-mpegURL' : 'video/mp4'
                                                }
                                            ]
                                        }}
                                        onTimeUpdate={handleTimeUpdate}
                                        lastTimeViewed={lecture?.lastTimeViewed}
                                        videoLength={lecture?.videoLength}
                                        // totalLecturesActive={lecture?.totalLecturesActive}
                                        isFinalLecture={lecture?.isFinalLecture}
                                        showCompleteText={!lecture?.completionDate && lecture?.statusUserCourse !== 2}
                                        linkGoTest={!lecture?.completionDate && lecture?.statusUserCourse === 2 && `/course/${lecture?.courseId}/completion-test`}
                                        onRefresh={handleRefresh}
                                    /> :
                                    <VideoPlayer
                                        options={{
                                            sources: [
                                                {
                                                    src: lecture.video,
                                                    type: isM3u8 ? 'application/x-mpegURL' : 'video/mp4'
                                                }
                                            ]
                                        }}
                                        disableForward={false}
                                    />
                            }
                            
                        </div>
                    )}
                    {/* lecturer Introduction */}
                    <LectureContent title="講師紹介" content={lecture.lecturerIntroduction} />

                    {/* content */}
                    <LectureContent title="コンテンツ内容" content={lecture.content} />
                </div>
            </div>

            {lecture.courseId && <RelatedLectures courseId={lecture.courseId} lectureId={params?.id} isOldCourse={lecture?.isOldCourse}/>}
        </div>
    );
}

export default observer(LectureDetailScreen);
