import ApiService from './ApiService';

class UserApi extends ApiService {

    constructor() {
        super();
    }

    getUserInfoHeader(payload) {
        return this.get('info', payload);
    }

    getCoursesOfUser(payload) {
        return this.get('course-of-user', payload);
    }

    getProfile() {
        return this.get();
    }

    updateProfile(payload) {
        return this.put('', payload);
    }

    getPurchaseHistories(payload) {
        return this.get('purchase-history', payload);
    }

    addAttendanceCode(payload) {
        return this.post('company-attendance/attendance', payload);
    }

    validateAddAttendanceCode(payload) {
        return this.post('company-attendance/attendance-validate', payload);
    }

    changePassword(payload) {
        return this.put('password', payload);
    }
    
    deletePurchase({ id }) {
        return this.delete(`purchase/${id}`, {});
    }

    cancelPurchase({ id }) {
        return this.put(`cancel-purchase/${id}`, {});
    }

    getToolCertificate(payload) {
        return this.get('tool-certificate', payload)
    }

    getSettingToolCertificate(payload) {
        return this.get('setting-tool-certificate', payload)
    }

    saveToolCertificate(payload) {
        return this.post('tool-certificate', payload)
    }

    resetToolCertificate() {
        return this.post('reset-tool-certificate', {})
    }
}

export default UserApi;