import { observer } from 'mobx-react';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DATE_TIME_YMD, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import NotFoundScreen from '../404/NotFoundScreen';

const NotifyDetailScreen = observer(props => {

    // other
    const navigate = useNavigate();

    // params
    const { id } = useParams();

    // store
    const { notifyStore: { notify, getNotifyDetail } } = useStore();

    // lifecycle
    useEffect(() => {
        if(id) getNotifyDetail(id);
    }, [id])

    if(!id) return <NotFoundScreen/>

    return(
        <div id='main' className='wrapper notify-detail-screen main-Message-detail'>
            <div className='bres-cus'>
                <div className='container'>
                    <ul>
                        <li>
                            <a href={SYSTEM_PATH.HOME}>
                                <img src='/images/icon-home.svg' alt='' />
                            </a>
                        </li>
                        {/* <li className='dash'>\</li>
                        <li>
                            <a href={SYSTEM_PATH.HOME}>お知らせ</a>
                        </li> */}
                        <li className='dash'>\</li>
                        <li>通知の詳細</li>
                    </ul>
                </div>
            </div>
            <div className='content-Message-detail'>
                <div className='container'>
                    <h1 className='title-primary'>{notify?.title}</h1>
                    <p className='date'>
                        {moment(notify?.createdAt).format(DATE_TIME_YMD)}
                    </p>
                    <div className='content-content pre-wrap' dangerouslySetInnerHTML={{__html: notify?.content}}></div>
                    <div className='link-link'>
                        <button onClick={() => navigate(SYSTEM_PATH.HOME)}>ホーム画面へ</button>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default NotifyDetailScreen;