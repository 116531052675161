/* eslint-disable max-len */
import React, { useState } from 'react'
import { useStore } from '../../core/utils/hook';
import { observer } from 'mobx-react';
import { SYSTEM_PATH } from '../../core/configs/constants';
import ReactHtmlParser from 'react-html-parser';
import { debounce } from 'lodash';
import classNames from 'classnames';

const FaqScreen = observer(() => {

    // store
    const {
        generalSettingStore: { getGeneralSetting }
    } = useStore();

    const [lstCategory, setLstCategory] = useState([]);
    const [lstFaqs, setLstFaqs] = useState([]);
    const [lstFaqsCache, setLstFaqsCache] = useState([]);
    const [categorySelected, selectCategory] = useState(-1);

    React.useEffect(() => {
        getFaqs();
    }, [])

    const getFaqs = async() =>{
        await getGeneralSetting({ type:'faqs' },(e) => {
            let { faqs } = JSON.parse(e.faqs);
            const faqsWithSort = faqs.sort((a,b) => {
                if(a.displayOrder == b.displayOrder) {
                    return new Date(b.create_at) - new Date(a.create_at);
                }
                return a.displayOrder - b.displayOrder;
            })
            setLstFaqs(faqsWithSort);
            setLstFaqsCache(faqsWithSort);

            const category = [...new Set(faqsWithSort.map(e => e.category))];
            setLstCategory(category);
        });
    }
    
    const ItemAccordion = ({item,index})=>{
        const [showAnswer, setShowAnswer] = useState(false);

        const onShowAnswer = () =>{
            setShowAnswer(!showAnswer);
        }
        return (
            <div key={index} className="acc">
                <div className="faq-acc-head rowContainer" role={'button'}  onClick={onShowAnswer}>
                    <p className="title-2 col-10"><span>Q</span>{item?.question}</p>
                    <div className='col-2 btnImg'>
                        <img src={showAnswer ? '/images/icon5.png' : '/images/icon6.png'}  width={35} height={35} />
                    </div>
                </div>
                {showAnswer && (
                    <div className='faq-acc-content'>
                        <div className='a-label'>A</div>
                        <div className='faq-acc-content-right'>
                            {ReactHtmlParser(item?.answer)}
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const Accordion = () =>{
        const lstQuestion = (categorySelected == -1) ? lstFaqs : lstFaqs.filter((item)=> item.category == categorySelected);
     
        return (
            lstQuestion?.length > 0 ?
                lstQuestion.map((item,index)=>{
                    return (
                        <ItemAccordion item={item} index={index} key={index}/>
                    )
                }) :
                <div className='text-center mg-t-20'>データはありません</div>
        )
    }

    const onSearchFaqs = debounce(async (e) => {
        const { value } = e.target;
        const filterFaqs = lstFaqsCache.filter(faq => faq?.question?.includes(value) || faq?.answer?.includes(value))
        setLstFaqs(filterFaqs);
        const category = [...new Set(filterFaqs.map(e => e.category))];
        setLstCategory(category);
        selectCategory(-1);
    }, 400)
    
    return (
        <div id="main" className="wrapper main-fqa">
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li>
                            <a href={SYSTEM_PATH.HOME}><img src="/images/icon-home.svg" alt="" /></a> 
                        </li>
                        <li className="dash">\</li>
                        <li>よくある質問</li>
                    </ul>
                </div>
            </div>
            <section className="section-fqa">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 col-sm-2 col-xs-12"></div>
                        <div className="col-md-10 col-sm-10 col-xs-12 pd-b-25">
                            <div className="section-fqa-right section-fqa-right-title d-flex align-items-center justify-content-between">
                                <h1 className="title-primary pd-b-0">よくある質問</h1> 
                                <div className='search-faqs-input d-flex align-items-center flex-gap-10'>
                                    <span>フリーワード検索：</span>
                                    <input onChange={onSearchFaqs}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2 col-sm-2 col-xs-12">
                            <div className="acc">
                                {
                                    lstCategory?.length > 0 ? lstCategory?.map((item,index)=>{
                                        return (
                                            <div key={index} role={'button'} onClick={()=> selectCategory(item)} className={classNames('acc__card', categorySelected == item && 'active')}>
                                                <div className="acc__title">
                                                    <p className='m-0 w-95 pd-r-20'>{item}</p>
                                                </div>
                                            </div>
                                        )
                                    }) : <></>
                                }
                            </div>
                        </div>
                        <div className="col-md-10 col-sm-10 col-xs-12">
                            <div className="section-fqa-right">
                                <div className="acc-container">
                                    <div className='faq-head'></div>
                                    {<Accordion />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
})

export default FaqScreen