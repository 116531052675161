/* eslint-disable max-len */
import { action, flow, makeObservable, observable, toJS } from 'mobx';
import { CourseApi } from '../apis';
import BaseStore from './BaseStore';

class CourseStore extends BaseStore {

    // course by type
    popularCourses = [];
    pagingPopularCourses = {
        ...this.paging,
        page: 1,
        size: 10
    };
    newCourses = [];
    pagingNewCourses = {
        ...this.paging,
        page: 1,
        size: 6,
        sortKey: 'public_date',
        sortDir: 'DESC'
    };

    allCourses = [];
    pagingAllCourses = {
        ...this.paging,
        page: 1,
        size: 10
    }

    lectureByCourse = [];
    pagingLectureListByCourse = {
        ...this.paging,
        page: 1,
        size: 10
    }

    attendanceHistories = [];
    totalStatusApplyAttendance = {};
    selectedAttendances = [];
    pagingAttendanceHistory = {
        ...this.paging,
        page: 1,
        size: 10
    }

    coursDetail = null;

    dataChartCourse = null;

    courseBreadcrumbText = '';

    listCoursePeriod = []

    searchCourseInfo = null;

    testResultHistory = '';

    listCompletionTest = {};

    courseSurvey = {};

    keepTestResultState = {};

    constructor(rootStore) {
        super();
        makeObservable(this, {
            popularCourses: observable,
            newCourses: observable,
            pagingPopularCourses: observable,
            pagingNewCourses: observable,
            pagingAllCourses: observable,
            allCourses: observable,
            coursDetail: observable,
            lectureByCourse: observable,
            pagingLectureListByCourse: observable,
            dataChartCourse: observable,
            courseBreadcrumbText: observable,
            totalStatusApplyAttendance: observable,
            listCompletionTest: observable,
            courseSurvey: observable,
            keepTestResultState: observable,
            searchCourseInfo: observable,
            getAllCourses: flow.bound,
            getCourseById: flow.bound,
            getCourseInternalById: flow.bound,
            getListCompletionTest: flow.bound,
            achievement: flow.bound,
            getLectureListByCourse: flow.bound,
            getLectureListByCourseInternal: flow.bound,
            getCourseChart: flow.bound,
            studentBuyCourse: flow.bound,
            updateBreadcrumb: action.bound,
            getCoursesByName: flow.bound,
            setSearchCourseInfo: action.bound,
            // attandance history
            getAttendanceHistories: flow.bound,
            getListCoursePeriod: flow.bound,
            exportAttendanceHistories: flow.bound,
            getCourseSurvey: flow.bound,
            sendCourseSurvey: flow.bound,
            cleanAttendanceHistory: action.bound,
            cleanSelectedAttendance: action.bound,
            handleToggleCheckAll: action.bound,
            handleToggleCheckItem: action.bound,
            attendanceHistories: observable,
            selectedAttendances: observable,
            listCoursePeriod: observable,
            pagingAttendanceHistory: observable,
            // reset
            cleanPagingHomeCourses: action.bound,
            // themes page
            cleanCourseData: action.bound,
            // test result history
            testResultHistory: observable,
            getTestResultHistory: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new CourseApi();
    }

    // popular course: 1, new course: 2, taking course: 3
    *getAllCourses(searchParams, type) {
        try {
            const { size, page, sortDir, sortKey } = type === 1 ? this.pagingPopularCourses : type === 2 ? this.pagingNewCourses : this.pagingAllCourses;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllCourses, payload);
            if (res?.ok) {
                if (type === 1) {
                    this.popularCourses = res?.data?.elements;
                    this.setAttrObservable('pagingPopularCourses', res?.data?.paginate, true, false);
                } else if (type === 2) {
                    this.newCourses = res?.data?.elements;
                    this.setAttrObservable('pagingNewCourses', res?.data?.paginate, true, false);
                } else {
                    this.allCourses = res?.data?.elements;
                    this.setAttrObservable('pagingAllCourses', res?.data?.paginate, true, false);
                }
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *getCoursesByName(searchParams) {
        try {
            const payload = { size: -1, page: 1, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getCoursesByName, payload, false, null, true);
            return res?.data?.elements || []
        } catch (error) {
            console.log(error);
            return []
        }
    }

    *getCourseById(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getCourseById, payload);
            if (res?.ok) {
                this.coursDetail = res?.data;
                this.courseBreadcrumbText = res?.data?.name;
            }
            return res;
        } catch (error) {
            return error;
        }
    }

    *getCourseInternalById(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getCourseInternalById, payload);
            if (res?.ok) {
                this.coursDetail = res?.data;
            }
            return res;
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    *getListCompletionTest(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getListCompletionTest, payload);
            this.listCompletionTest = res?.data;
            return res?.data;
        } catch (error) {
            console.log(error);
        }
    }

    *getTestResultHistory(courseId) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getTestResultHistory, courseId);
            if (res?.ok) {
                this.testResultHistory = res?.data?.testResultHistory;
            }
            return res;
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    *achievement( courseId, payload ) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.achievement, { courseId, payload });
            return !res?.data ? res?.message : undefined;
        } catch (error) {
            console.log(error);
            return error?.message;
        }
    }

    *getLectureListByCourse(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getLectureListByCourse, payload);
            if (res?.ok) {
                this.lectureByCourse = res?.data || {};
                this.setAttrObservable('pagingLectureListByCourse', res?.data?.listLecture?.paginate, true, false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getLectureListByCourseInternal(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getLectureListByCourseInternal, payload);
            if (res?.ok) {
                this.lectureByCourse = res?.data || {};
                this.setAttrObservable('pagingLectureListByCourse', res?.data?.listLecture?.paginate, true, false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getCourseChart(courseId) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getCourseChart, courseId);
            if (res?.ok) {
                console.log('res:', res);
                this.dataChartCourse = res?.data || {};
            }
        } catch (error) {
            console.log(error);
        }
    }

    *studentBuyCourse(courseId) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.studentBuyCourse, courseId);
            return res;
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    updateBreadcrumb(text) {
        this.courseBreadcrumbText = text;
    }

    *getAttendanceHistories(searchParams = {}) {
        try {
            const { size, page, sortDir, sortKey } = this.pagingAttendanceHistory;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAttendanceHistories, payload);
            let selectedAttendances = toJS(this.selectedAttendances);
            if (res?.ok) {
                let data = res?.data?.elements || [];
                // let page = res?.data.paginate?.page || 1;
                data = data.map((item) => {
                    const rowId = page + '.' + item.courseId;
                    // const rowId = item.courseId;
                    const existId = selectedAttendances.find(e => e.rowId === rowId);
                    // return { ...item, rowId: page + '.' + i, isChecked: !!existId }
                    return { ...item, rowId, isChecked: !!existId }
                })
                this.attendanceHistories = data;
                this.setAttrObservable('pagingAttendanceHistory', res?.data?.paginate, true, false);
                this.totalStatusApplyAttendance = res?.data?.total || {};
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    handleToggleCheckAll(event) {
        const val = event.target.checked;
        let selectedAttendances = toJS(this.selectedAttendances);
        let pagingAttendanceHistory = toJS(this.pagingAttendanceHistory);
        let page = pagingAttendanceHistory?.page || 1;
        let attendanceHistories = toJS(this.attendanceHistories);

        if (!val) {
            selectedAttendances = selectedAttendances.filter(e => !e.rowId.includes(page + '.'))
        }

        this.attendanceHistories = [...attendanceHistories].map(item => {
            if (val) {
                const existId = selectedAttendances.find(e => e.rowId === item.rowId);
                if (!existId) {
                    selectedAttendances.push(item);
                }
            }
            return { ...item, isChecked: val }
        })

        this.selectedAttendances = selectedAttendances;
    }

    handleToggleCheckItem(rowId, val) {
        let selectedAttendances = toJS(this.selectedAttendances);
        if (!val) {
            selectedAttendances = selectedAttendances.filter(e => e.rowId !== rowId);
        }

        const attendanceHistories = [...this.attendanceHistories].map(item => {
            if (item.rowId === rowId) {
                if (val) {
                    const existId = selectedAttendances.find(e => e.rowId === rowId);
                    if (!existId) {
                        selectedAttendances.push(item);
                    }
                }
                return { ...item, isChecked: val }
            }
            return item;
        });


        this.attendanceHistories = attendanceHistories;
        this.selectedAttendances = selectedAttendances;
    }

    *getListCoursePeriod(cb) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getListCoursePeriod);
            this.listCoursePeriod = res?.data || [];
            cb && cb(res?.data || []);
            return false;
        } catch (error) {
            console.log(error);
            cb && cb([]);
        }
    }

    *exportAttendanceHistories(payload) {
        try {
            yield this.rootStore.apiStore.call(this.api, this.api.exportAttendanceHistories, payload);
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *getCourseSurvey(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getCourseSurvey, payload);
            if (res?.ok) {
                this.courseSurvey = res?.data || {};
                return true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *sendCourseSurvey ({ courseId, payload }) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.sendCourseSurvey, { courseId, payload });
            if (res?.ok) {
                return true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    cleanAttendanceHistory() {
        this.attendanceHistories = [];
        this.selectedAttendances = [];
        this.pagingAttendanceHistory = {
            ...this.paging,
            page: 1,
            size: 10
        }
    }

    cleanSelectedAttendance() {
        this.selectedAttendances = [];
    }

    cleanPagingHomeCourses() {
        this.pagingPopularCourses = {
            ...this.paging,
            page: 1,
            size: 10
        };
        this.pagingNewCourses = {
            ...this.paging,
            page: 1,
            size: 6,
            sortKey: 'public_date',
            sortDir: 'DESC'
        };
    }

    cleanCourseData() {
        this.allCourses = [];
        this.pagingAllCourses = {
            ...this.paging,
            page: 1,
            size: 10
        }
    }

    setSearchCourseInfo(name) {
        this.searchCourseInfo = name;
    }
}

export default CourseStore;