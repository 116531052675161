import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { MSG, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';

function ForgotPasswordScreen() {
    const navigate = useNavigate();

    const validateLoginSchema = yup.object().shape({
        email: yup.string().required('メールアドレスを入力する必要があります。')
    });
    
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm({ resolver: yupResolver(validateLoginSchema), mode: 'onChange' });
    

    // store
    const {
        modalStore: { openAlert , hide},
        passwordStore: { forgetPassword }
    } = useStore();

    const handleForgetPassword = (payload) => {
        forgetPassword(payload, () => {
            openAlert(MSG['confirm.student.send.email'], ()=> {
                navigate(SYSTEM_PATH.LOGIN);
                hide();
            })
        });
    };

    const navigateInquire = (payload) => {
        navigate(SYSTEM_PATH.SEND_INQUIRY,{ state: { isForgotPassword : true }})
    }

    return (
        <div id="main" className="wrapper main-signin main-Forgot-Password">
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li  >
                            <a href={SYSTEM_PATH.HOME}>
                                <img src="/images/icon-home.svg" alt="" />
                            </a>
                        </li>
                        <li className="dash">\</li>
                        <li>パスワード再発行</li>
                    </ul>
                </div>
            </div>
            {/* <a className='hover-link'>ログインできない方はこちら</a> */}

            <div className="content-signin">
                <div className="container">
                    <div className="content-form">
                        <h2 className="title-primary">パスワード再発行</h2>
                        <p className="note-3 mt-4">
                            登録メールアドレスを入力してください。
                            <p className="note-3">お忘れの場合、<a className='hover-link' style={{ color:'#0077B6' }} onClick={navigateInquire}>こちら</a>
                                のフォームよりお問い合わせください。
                            </p>
                        </p>
                        <form onSubmit={handleSubmit(handleForgetPassword)}>
                            <input
                                {...register('email')}
                                id="email"
                                type={'text'}
                                placeholder="メールアドレスを入力してください..."
                            />
                            <button  disabled={isSubmitting} type='button' onClick={handleSubmit(handleForgetPassword)}>
                                <img src="/images/icon10.svg" alt="" />
                            </button>
                        </form>
                        {errors?.email && (
                            <div className="text-danger mg-t-5 fs-error">
                                {errors.email?.message}
                            </div>
                        )}
                        <div className="linklink">
                            <a href={SYSTEM_PATH.LOGIN}>ログイン画面に戻る</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(ForgotPasswordScreen);
