export const onLoadNavbar = () => {
    // window.$('#loading-src').removeClass('hide');

    var $main_nav = window.$('#main-nav');
    var $toggle = window.$('.toggle');

    var defaultData = {
        maxWidth: false,
        // customToggle: $toggle,
        // navTitle: 'All Categories',
        levelTitles: true,
        pushContent: '#container',
        levelOpen: 'expand',
        levelSpacing: 20
    };

    // add new items to original nav
    $main_nav.find('li.add').children('a').on('click', function() {
        var $this = window.$(this);
        var $li = $this.parent();
        var items = eval('(' + $this.attr('data-add') + ')');

        $li.before('<li class="new"><a>'+items[0]+'</a></li>');

        items.shift();

        if (!items.length) {
            $li.remove();
        }
        else {
            $this.attr('data-add', JSON.stringify(items));
        }

        Nav.update(true);
    });

    // call our plugin
    var Nav = $main_nav.hcOffcanvasNav(defaultData);

    // demo settings update

    const update = (settings) => {
        if (Nav.isOpen()) {
            Nav.on('close.once', function() {
                Nav.update(settings);
                Nav.open();
            });

            Nav.close();
        }
        else {
            Nav.update(settings);
        }
    };

    window.$('.actions').find('a').on('click', function(e) {
        e.preventDefault();

        var $this = window.$(this).addClass('active');
        var $siblings = $this.parent().siblings().children('a').removeClass('active');
        var settings = eval('(' + $this.data('demo') + ')');

        update(settings);
    });

    window.$('.actions').find('input').on('change', function() {
        var $this = window.$(this);
        var settings = eval('(' + $this.data('demo') + ')');

        if ($this.is(':checked')) {
            update(settings);
        }
        else {
            var removeData = {};
            window.$.each(settings, function(index, value) {
                removeData[index] = false;
            });

            update(removeData);
        }
    });

    // window.$('#loading-src').addClass('hide');
}

export const onLoadSwiper = (
    swiperContainerId, 
    scrollbarClass = '.swiper-scrollbar',
    nextElId = '#navigation-right',
    prevElId = '#navigation-left',
    dragSize = 392, 
    freeMode = false, 
    loop = false, 
    slidesDesktop = 3, 
    slidesTablet = 2.5, 
    slidesMobile = 1.25,
    spaceBetween = 40
) => {
    // window.$('#loading-src').removeClass('hide');

    const $swiperSelectorls = window.$(`#${swiperContainerId}`);

    $swiperSelectorls.each(function(index) {
        const $this = window.$(this);
        $this.addClass(`swiper-slider-${swiperContainerId}` + index);

        // eslint-disable-next-line no-undef
        const swiper = new Swiper(`.swiper-slider-${swiperContainerId}` + index, {
            direction: 'horizontal',
            loop: loop,
            freeMode: freeMode,
            spaceBetween: spaceBetween,
            slidesPerView: slidesDesktop,
            breakpoints: {
                1920: {
                    slidesPerView: slidesDesktop
                },
                992: {
                    slidesPerView: slidesTablet
                },
                736: {
                    slidesPerView: slidesMobile,
                    spaceBetween: 20,
                    scrollbar: {
                        el: scrollbarClass,
                        draggable: true,
                        dragSize: 50
                    }
                },
                320: {
                    slidesPerView: slidesMobile
          
                }
            },
            scrollbar: {
                el: scrollbarClass,
                draggable: true,
                dragSize: dragSize
            },
            navigation: {
                nextEl: nextElId,
                prevEl: prevElId
            }
        });
    });

    // window.$('#loading-src').addClass('hide');
}

export const onLoadAccordion = () => {
    // window.$('#loading-src').removeClass('hide');

    window.$('.acc-head').unbind('click');

    window.$('.acc-head').on('click', function() {
        if(window.$(this).hasClass('active')) {
            window.$(this).siblings('.acc-content').slideUp();
            window.$(this).removeClass('active');
        }
        else {
            window.$('.acc-content').slideUp();
            window.$('.acc-head').removeClass('active');
            window.$(this).siblings('.acc-content').slideToggle();
            window.$(this).toggleClass('active');
        }
    }); 

    // window.$('#loading-src').addClass('hide');
}

export const onLoadPlusMinusQuantity = () => {
    window.$('.quantity').unbind('click');

    window.$('.quantity').on('click', '.plus', function(e) {
        const $input = window.$(this).prev('input.qty');
        let val = parseInt($input.val());
        $input.val( val + 1 ).change();
    });

    window.$('.quantity').on('click', '.minus', function(e) {
        const $input = window.$(this).next('input.qty');
        let val = parseInt($input.val());
        if (val > 0) {
            $input.val( val - 1 ).change();
        } 
    });
}

export const onLoadAccordionTitle = () => {
    window.$('.acc__title').unbind('click');

    window.$('.acc__title').click(function(j) {
        const dropDown = window.$(this).closest('.acc__card').find('.acc__panel');
        window.$(this).closest('.acc').find('.acc__panel').not(dropDown).slideUp();
    
        if (window.$(this).hasClass('active')) {
            window.$(this).removeClass('active');
        } else {
            window.$(this).closest('.acc').find('.acc__title.active').removeClass('active');
            window.$(this).addClass('active');
        }
    
        dropDown.stop(false, true).slideToggle();
        j.preventDefault();
    });
}