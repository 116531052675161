/* eslint-disable max-len */
import { toJS } from 'mobx';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { VideoPlayer } from '../../../components';
import { COURSE_STATUS_COMPLETE, DATE_TIME_YMD, DATE_YMD, MSG, SYSTEM_PATH } from '../../../core/configs/constants';
import { convertVideoDuration, extractContent, numberWithCommas } from '../../../core/utils/common';
import { useStore } from '../../../core/utils/hook';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Pie } from 'react-chartjs-2';
import moment from 'moment';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { isShowCompletionTest, isShowCourseStatus, isShowJoinCourse, isShowReTest, isViewOldCourse } from '../ultis/courses.ultis';

const data = {
    labels: ['受講中または受講完了', ' 未受講'],
    datasets: [
        {
            label: '受講割合',
            data: [0, 0],
            backgroundColor: [
                '#fbb03b',
                '#0077b6'
            ]
        }
    ]
};

const CourseCard = ({ course, onFetchData }) => {
    const [dataPieChart, setDataPieChart] = useState(data)
    const [showMore, setShowMore] = useState(false);
    const navigate = useNavigate();

    const {
        courseStore: { studentBuyCourse },
        authStore: { token, userInfo },
        modalStore: { hide, openAlert },
        userStore: { userInfoHeader, getUserInfoHeader, onAddTicketPlan, coursePeriodStatus }
    } = useStore();

    useEffect(() => {
        if (!course) return;
        let totalNotAttended = (course?.statusUserCourse === COURSE_STATUS_COMPLETE.USER_COURSE_APPLY_CERTIFICATE) ? 0 : ((course.totalTimeCourse || 0) - (course.totalTimeJoinCourse || 0));
        totalNotAttended = totalNotAttended < 1 ? 0 : totalNotAttended;
        const totalAttended = course.totalTimeJoinCourse || 0;

        const dataset = {
            label: '受講割合',
            data: [totalAttended, totalNotAttended],
            backgroundColor: [
                '#fbb03b',
                '#0077b6'
            ]
        };

        setDataPieChart({
            ...dataPieChart,
            datasets: [
                dataset
            ]
        })
    }, [course])

    const courseStatusName = useMemo(() => {
        try {
            if (!course?.type?.length) return false;
            const types = [...course.type].map(e => Number(e));

            if (types.includes(0) && types.includes(1)) {
                return '精神薬学会認定薬剤師';
            }

            if (types.includes(0) && types.includes(2)) {
                return '漢方薬・生薬認定薬剤師';
            }

            if (types.includes(0) && types.includes(3)) {
                return '小児薬物療法認定薬剤師';
            }

            return false;
        } catch (error) {
            return false
        }
    }, [course?.type])

    const isM3u8 = useMemo(() => {
        return course?.sampleVideo && course.sampleVideo.toLowerCase().includes('m3u8');
    }, [course]);

    const handleBuyCourse = (event) => {
        event.stopPropagation();

        const id = course?.id;
        if (!token) {
            openAlert(MSG['warning.student.apply.ticket'], () => {
                hide();
            })
        } else {
            if (userInfoHeader?.numberTicket == 0) {
                onAddTicketPlan();
            } else {
                studentBuyCourse(id).then(res => {
                    if (res?.ok) {
                        if (token) {
                            getUserInfoHeader();
                        }
                        onFetchData && onFetchData();
                    }
                });
            }
        }
    }

    const handleClickCompletionTest = (event) => {
        event.stopPropagation();
        navigate(`/course/${course?.id}/completion-test`);
    }

    const handleClickReTest = (event) => {
        event.stopPropagation();
        navigate(`/course/${course?.id}/completion-test`);
    }

    const handleShowMore = (event) => {
        event.stopPropagation();
        setShowMore(prev => !prev);
    }

    const handleViewOldCourse = (event) => {
        event.stopPropagation();
        if(course?.isOldCourse) {
            if(userInfoHeader?.coursePeriodStart && userInfoHeader?.coursePeriodEnd &&
                moment().isSameOrBefore(moment(userInfoHeader?.coursePeriodEnd), 'date')) {
                navigate(`/course/${course?.id}`);
            } else {
                openAlert(MSG['error.view_old_course'], () => {
                    hide();
                }, 'auto', '閉じる');
            }
        }
    }

    const handleNavigateCourseDetail = (event) => {
        event.stopPropagation();
        if(token) {
            if(isShowCourseStatus(token, course)) {
                navigate(`/course/${course?.id}`);
            } else {
                handleViewOldCourse(event);
            }
        }
    }

    const courseRes = useMemo(() => {
        const courseDetail = toJS(course);
        const status = courseDetail?.statusUserCourse;
        let name = '';
        let color = '';
        /**
            未受講 = null
            受講中 = 0,1,2
            受講済 = 3
         */
        switch (status) {
        case COURSE_STATUS_COMPLETE.USER_COURSE_NOT_ATTENDED: // 0
        case COURSE_STATUS_COMPLETE.USER_COURSE_ATTENDING: // 1
        case COURSE_STATUS_COMPLETE.USER_COURSE_NOT_DO_TEST: // 2
            name = '受講中';
            color = '#FFC108';
            break;
        case COURSE_STATUS_COMPLETE.USER_COURSE_APPLY_CERTIFICATE: // 3
            name = '受講完了';
            color = '#28A745';
            break;
        default:
            name = '未受講';
            color = '#6D757D';
            break;
        }

        return { name, color };
    }, [course])

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'right',
                labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                    padding: 5
                }
            }
        }
    };

    const isShowAchievement = course?.completionDate !== null;
    const isShowPieChart = course?.statusUserCourse !== null && course?.statusUserCourse !== undefined;
    const isShowOverlay = useMemo(() => {
        if (extractContent(course?.lecturerIntroduction || '').length > 10 ||
            extractContent(course?.description || '').length > 10 || isShowPieChart || isShowAchievement) {
            return true;
        }

        return false;
    }, [course, isShowPieChart, isShowAchievement])

    return (
        <div className="item item-theme" role={'button'}>
            <div className="img-course">
                <p className="title-course-sample">サンプルコンテンツ</p>
                {course?.sampleVideo ? (<VideoPlayer
                    options={{
                        sources: [
                            {
                                src: course?.sampleVideo,
                                type: isM3u8 ? 'application/x-mpegURL' : 'video/mp4'
                            }
                        ]
                    }}
                    width={320}
                    height={180}
                    disableForward={false}
                />) : (<div className='not-show-video'>
                    <img src={course?.thumbnail || '/images/img-1.png'} alt='' style={{'height': '100%', 'objectFit': 'fill'}}/>
                    <p>
                        <Link to={SYSTEM_PATH.LOGIN}>ログイン</Link>後にご覧いただけます。
                    </p>
                </div>)
                }
            </div>
            <div className={classNames('nav-img course-content', (!isShowOverlay || showMore) && 'course-content-full', !token && 'cursor-pointer-none')}
                onClick={handleNavigateCourseDetail}>
                <p className="title-category">{course?.themeName}</p>

                <h3 className={classNames('title-3', course?.isNew && 'icon-lecture-new')}>
                    <a>{course?.name}</a>
                </h3>

                <div className='d-flex justify-content-between'>
                    {
                        isShowCourseStatus(token, course) ? 
                            <span className='item-course-status' style={{ background: courseRes.color }}>{courseRes.name}</span>
                            :
                            <span className='item-course-status'></span>
                    }

                    {isShowJoinCourse(token, course, userInfo) &&
                        <button type='button' onClick={handleBuyCourse} className="join-course">受講ボタン</button>}
                    {isViewOldCourse(token, course, userInfo) &&
                        <button type='button' onClick={handleViewOldCourse} className="join-course">講座視聴</button>}
                    {isShowCompletionTest(token, course, coursePeriodStatus) &&
                        <button type='button' onClick={handleClickCompletionTest} className="join-course">修了テスト</button>}
                    {isShowReTest(token, course, coursePeriodStatus) &&
                        <button type='button' onClick={handleClickReTest} className="join-course">再テストの実施</button>}

                </div>
                <div className="clock-video">
                    <ul>
                        <li className='li-0'>
                            <b>公開日:</b> {moment(course?.publicDate).format(DATE_YMD)}
                        </li>
                        <li className='li-1'>
                            <b>動画合計時間:</b> 約 {convertVideoDuration(course?.totalTime)} 分
                        </li>
                        <li className='li-0'><b>コンテンツ数:</b> {numberWithCommas(course?.totalLecture)}</li>
                        {
                            courseStatusName && 
                            <li className="red ic-last li-1">
                                <span>認定種別: </span>
                                <span>{courseStatusName}</span>
                            </li>
                        }
                    </ul>
                </div>

                {!!course?.description && <>
                    <h3 className='title-intro mg-t-10'>講座内容</h3>
                    <p className="dessc-2">{ReactHtmlParser(course?.description || '')}</p>
                </>}

                {!!course?.lecturerIntroduction && <>
                    <h3 className='title-intro mg-t-10'>講師紹介</h3>
                    <p className="dessc-2">{ReactHtmlParser(course?.lecturerIntroduction || '')}</p>
                </>}

                {token && <div className='row mg-t-10 chart-theme'>
                    {isShowAchievement && <div className="col-md-6 col-sm-6 col-12 c1">
                        <h3 className='title-intro text-center'>正答率</h3>
                        <div className="chart-area chart-area1" style={{ width: 300, height: 200, margin: 'auto', paddingTop: 20 }}>
                            <div className='chart-area1-item' style={{ width: 150, height: 150, margin: 'auto' }}>
                                <CircularProgressbar
                                    strokeWidth={12}
                                    value={course?.achievement || 0}
                                    maxValue={100} text={`${course?.achievement || 0}%`}
                                    styles={buildStyles({
                                        pathColor: '#FCB03B', textColor:
                                            '#FCB03B', fontWeigth: 'bold'
                                    })} />
                            </div>
                            {course?.completionDate &&
                                <p className="desc text-center"><span>実施日: </span>
                                    {moment(course?.completionDate).format(DATE_TIME_YMD)}
                                </p>
                            }
                        </div>
                    </div>}
                    {isShowPieChart && <div className="col-md-6 col-sm-6 col-12">
                        <h3 className='title-intro pd-l-60'>受講割合</h3>
                        <div className="chart-area" style={{ width: 350, height: 300, margin: 'auto', marginTop: -55 }}>
                            <Pie data={dataPieChart} options={options} width={250}
                                height={250} />
                        </div>
                    </div>}
                </div>}

                {isShowOverlay && !showMore && <div className='course-overlay'></div>}
            </div>
            {isShowOverlay && <div className='btn-more-wrapper'>
                <p onClick={handleShowMore} className='btn-more'>{!showMore ? 'より詳細内容へ' : '短縮する'}</p></div>}
            <div className="clearfix"></div>
        </div>
    )
}

export default CourseCard;