/* eslint-disable max-len */
import moment from 'moment';
import { DATE_YMD_ISO } from '../../../core/configs/constants';

export const getDateAfter1Year = (date = new Date()) => {
    const dateAfter1Year = moment(date).add(1, 'years').subtract(1, 'd').format(DATE_YMD_ISO);
    return dateAfter1Year;
}

export const convertCoursePeriodDate = (date) => {
    if(!date) return '';
    return `${moment(date).format('YYYY')}年${moment(date).format('MM')}月${moment(date).format('DD')}日～${moment(getDateAfter1Year(date)).format('YYYY')}年${moment(getDateAfter1Year(date)).format('MM')}月${moment(getDateAfter1Year(date)).format('DD')}日 (1 年間)`;
}

export const convertCoursePeriodDateFirst = (date) => {
    return `${moment(date).format('YYYY')}年${moment(date).format('MM')}月${moment(date).format('DD')}日`;
}

export const convertCoursePeriodDateLast = (date) => {
    return `～${moment(getDateAfter1Year()).format('YYYY')}年${moment(getDateAfter1Year()).format('MM')}月${moment(getDateAfter1Year()).format('DD')}日 (1 年間)`;
}