import { observer } from 'mobx-react';
import { useStore } from '../../core/utils/hook';
import { useEffect, useState } from 'react';
import { MSG, SURVEY_QUESTION_TYPE, SYSTEM_PATH } from '../../core/configs/constants';
import yup from '../../core/utils/yupValidate';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { ModalFooter, ReactNotifications } from '../../components';

const SurveyScreen = observer(() => {

    // other
    const { courseId } = useParams(); 
    const navigate = useNavigate();

    // store
    const {
        courseStore: { courseSurvey, getCourseSurvey, sendCourseSurvey, keepTestResultState },
        modalStore: { hide, show }
    } = useStore();

    // state
    const validateSchema = yup.object().shape({
        questions: yup.array().of(yup.object().shape({
            required: yup.boolean(),
            answer: yup.mixed().transform((originalValue, originalObject) => {
                if (originalValue === null) {
                    return '';
                }
                return originalValue;
            }).when('required', {
                is: false,
                then: yup.string().nullable(),
                otherwise: yup.string().required(MSG['error.required'])
            })
        }))
    })

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        trigger,
        reset
    } = useForm({ resolver: yupResolver(validateSchema), mode: 'onChange' });

    const [isConfirmDisable, setIsConfirmDisable] = useState(false);
 
    // life cycle
    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        if (courseSurvey) {
            reset({ questions: courseSurvey?.survey?.questions });
        }
    }, [courseSurvey])

    // function
    const fetchData = async () => {
        try {
            await getCourseSurvey({courseId});
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const onSendSurvey = async (data) => {
        const payload = {
            questions: courseSurvey?.survey?.questions,
            answers: data.questions.map(item => item.answer.trim())
        }
        const res = await sendCourseSurvey({ courseId, payload })
        if (res) {
            onSendSuccessSurvey();
            ReactNotifications('success', MSG['inform.success.update'])
        }
    }

    const onSendSuccessSurvey = () => {
        show({
            id: 'modal-survey-success',
            isOpen: true,
            onCancel: () => hide(),
            children: (
                <div className='text-center min-width-400'>
                    <div key={'modal-body'} className='modal-body d-flex flex-column pd-t-0'>
                        <i className='fa-regular fa-circle-check text-green text-50 mg-b-15'></i>
                        <div>ご回答いただきありがとうございます。</div>
                        <div>今後の講座作成、サービス改善に活かして参ります。</div>
                    </div>
                    <ModalFooter key={'modal-footer'}
                        saveButtonText={'受講履歴を確認する'}
                        onConfirm={ async() => {
                            hide();
                            navigate(SYSTEM_PATH.ATTENDANCE_HISTORY);
                        }}
                        cancelButtonText={'講座に戻る'}
                        onCancel={() => {
                            hide();
                            navigate(`/course/${courseId}`)
                        }}
                        saveButtonClass='btn-default-2'
                    />
                </div>
            ),
            type: 'small'
        })
    }

    const handleGoBack = () => {
        navigate(`/course/${courseId}/completion-test`, { replace: true, state: { keepTestResultState: JSON.stringify(keepTestResultState) } });
    };
    
    const onClickConfirmSurvey = (event) => {
        event.preventDefault();
        trigger();
        if (isValid) {
            setIsConfirmDisable(!isConfirmDisable);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    return (
        <div id="main" className="wrapper main-execute-completion main-execute-completion-2">
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li>
                            <a href="/">
                                <img src="/images/icon-home.svg" alt="" />
                            </a>
                        </li>
                        {
                            courseSurvey?.theme && 
                            <>
                                <li className="dash">\</li>
                                <li>
                                    <a href={SYSTEM_PATH.THEMES + `?themeId=${courseSurvey?.theme?.id}`}>
                                        {courseSurvey?.theme?.name}
                                    </a>
                                </li>
                            </>
                        }
                        <li className="dash">\</li>
                        <li>
                            <a href={SYSTEM_PATH.COURSE + courseId}>{courseSurvey?.course?.name}</a>
                        </li>
                        <li className="dash">\</li>
                        <li>アンケート</li>
                    </ul>
                </div>
            </div>
            <div className="content-execute-completion">
                <div className="container">
                    <div className="top-title-date">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="title-primary">受講アンケートの回答</h2>
                            </div>
                        </div>
                    </div>

                    {
                        isConfirmDisable && (
                            <div className='text-error font-bold mg-t-20'>
                                回答内容をご確認の上、送信してください。
                            </div>
                        )
                    }

                    <form onSubmit={handleSubmit(onSendSurvey)} className="survey-content-screen nav-execute-completion">
                        {
                            courseSurvey?.survey?.questions?.map((question, index) =>
                                <>
                                    <QuestionItem
                                        register={register}
                                        key={index} 
                                        question={question} 
                                        disabled={isConfirmDisable}
                                        index={index} />
                                    {
                                        errors.questions?.[index]?.answer &&
                                        <div 
                                            className='mg-t-5' 
                                            style={{ color: 'red', marginLeft: '100px'}}>{errors.questions?.[index].answer?.message}</div>
                                    }
                                </>
                            )
                        }
                        <div className="link-button">
                            {
                                !isConfirmDisable ? (
                                    <>
                                        <button onClick={handleGoBack} className="btn-1">戻る</button>
                                        <button onClick={onClickConfirmSurvey} className="btn-2">
                                            確認
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button onClick={onClickConfirmSurvey} className="btn-1">回答を編集</button>
                                        <button type='submit' className="btn-2">
                                            送信
                                        </button>
                                    </>
                                )
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
})

const RadioAnswers = ({answers, index, register, disabled}) => {
    return (
        answers?.map((item, answerIdx) => {
            return (
                <li>
                    <input type="radio" {...register(`questions.${index}.answer`)} value={item} id={`${answerIdx} ${index}`} disabled={disabled} />
                    <label className='cursor-pointer' htmlFor={`${answerIdx} ${index}`}>{item}</label>
                </li>
            )
        })
    )
}

const TextAnswers = ({ index, register, disabled }) => {
    return (
        <textarea className='answer-text' {...register(`questions.${index}.answer`)} disabled={disabled} />
    )
}

function QuestionItem({ question, index, register, key, disabled }) {
    return (
        <div className="item-execute">
            <h4 className="title-4">
                <span className={question.required ? 'field-required' : ''}>{index + 1}</span> {question.content}
            </h4>
            <div className="list-execute">
                <ul>
                    {
                        question?.type === SURVEY_QUESTION_TYPE.RADIO ? (
                            <RadioAnswers answers= {question.answers} index={index} register={register} disabled={disabled} />
                        ) : (
                            <TextAnswers index={index} register={register} disabled={disabled} />
                        )
                    }
                </ul>
            </div>
        </div>
    );
}

export default SurveyScreen;