import { observer } from 'mobx-react';
import RegistrationLayout from './components/RegistrationLayout';
import { useNavigate } from 'react-router-dom';
import {SYSTEM_PATH} from '../../core/configs/constants';

const RegistrationThirdStepScreen = observer(() => {
    const navigate = useNavigate();
    return (
        <RegistrationLayout>
            <div className="content-trgisstration-3">
                <div className="icon">
                    <img src="/images/v2.svg" alt="" />
                </div>
                <p className="desc">ご登録いただきありがとうございます。<br /> 登録メールアドレス完了メールを送信しました。</p>
                <button onClick={()=> navigate(SYSTEM_PATH.LOGIN)}>ログイン</button>
            </div>
        </RegistrationLayout>
    )
})

export default RegistrationThirdStepScreen;