import ApiService from './ApiService';

class ThemeApi extends ApiService {

    constructor() {
        super('theme');
    }

    getAllThemes(payload) {
        return this.get('all', payload);
    }
}

export default ThemeApi;