/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import { useStore } from '../../../core/utils/hook';
import { numberWithCommas } from '../../../core/utils/common';
import { SYSTEM_PATH } from '../../../core/configs/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { convertCoursePeriodDate } from '../utils/payment.utils';

const PaymentStatus = observer(() => {
    // store
    const {
        paymentStore: { statusOrder }
    } = useStore();

    const [isPaymentStatus, setIsPaymentStatus] = useState(0); // 0: processing 1: unsuccessful 2: successful

    const [orderPayment, setOrderPayment] = useState({});

    const navigate = useNavigate()

    let { id } = useParams();

    const handleOnSubmit = async () => {
        navigate(SYSTEM_PATH.PURCHASE_HISTORY);
    };

    const checkStatusOrder = async () => {
        const order = await statusOrder(id);
        if(order) {
            setIsPaymentStatus(order.status);
            setOrderPayment(order);
        } else {
            setOrderPayment(null);
        }
    };

    useEffect(() => {
        checkStatusOrder();
    }, []);

    const StatusPayment = () => {
        switch (isPaymentStatus) {
        case 0:
            return <></>;
        case 1:
            return (
                <h2 className="title-payment-status" style={{ color: '#EC4339' }}>
            決済が失敗しました!
                </h2>
            );
        case 2:
            return (
                <h2 className="title-payment-status" style={{ color: '#4BB643' }}>
            決済は完了いたしました
                </h2>
            );
        }
    };

    const PaymentContent = () => {
        const {
            id,
            code,
            price,
            subtotal,
            amount,
            promotionCode,
            tax,
            discount,
            totalPayment,
            ticketName,
            duration,
            coursePeriod
        } = orderPayment;
     
        return (
            <div className="content-Pay-ment payment-status-screen">
                <div className="container">
                    <StatusPayment />
                    <div className="content-content">
                        <h3 className="title-2">{ticketName}</h3>
                        <div className="item-1">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-4">
                                    <p className="title-l">Order ID</p>
                                </div>
                                <div className="col-md-7 col-sm-7 col-8">
                                    <p className="title-r">{code}</p>
                                </div>
                            </div>
                        </div>
                        <div className="item-1">
                            <div className="row">
                                <div className="col-md-4 col-sm-4 col-4">
                                    <p className="title-l">受講期間</p>
                                </div>
                                <div className="col-md-8 col-sm-8 col-8">
                                    <p className="title-r">{convertCoursePeriodDate(coursePeriod)}</p>
                                </div>
                            </div>
                        </div>

                        <div className="item-1">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l">価格</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r">{numberWithCommas(price)} 円</p>
                                </div>
                            </div>
                        </div>

                        <div className="item-1">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l">数量</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r">{amount}</p>
                                </div>
                            </div>
                        </div>
                        <div className="item-1">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l">クーポンコード</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r">{promotionCode}</p>
                                </div>
                            </div>
                        </div>

                        <div className="item-2-ticket">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l-ticket">決済方法</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r-ticket">クレジットカード</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="item-1">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l">税抜金額</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r">{numberWithCommas(subtotal)} 円</p>
                                </div>
                            </div>
                        </div>
                        <div className="item-1">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l">割引金額</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r">{numberWithCommas(discount)} 円</p>
                                </div>
                            </div>
                        </div>
                        <div className="item-1">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l">10％消費税額</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r">{numberWithCommas(tax)} 円</p>
                                </div>
                            </div>
                        </div>
                        <div className="item-1 item-4">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-l">お支払い金額</p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <p className="title-r">{numberWithCommas(totalPayment)} 円</p>
                                </div>
                            </div>
                        </div>

                        <div className="btn-save-payment mg-t-20">
                            <button className="" onClick={handleOnSubmit}>
                保存及び購入履歴へ移動
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    if(!orderPayment) return <div className="pd-30">この購入取引は削除されました。詳細については、管理者にお問い合わせください。</div>

    return (
        <div id="main" className="wrapper main-execute-completion main-Pay-ment">
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li>
                            <a href={SYSTEM_PATH.HOME}>
                                <img src="../../../images/icon-home.svg" alt="" />
                            </a>
                        </li>
                        <li className="dash">\</li>
                        <li>お支払</li>
                    </ul>
                </div>
            </div>

            <PaymentContent />
        </div>
    );
});

export default PaymentStatus;
