/* eslint-disable no-useless-escape */
import * as yup from 'yup';

yup.addMethod(yup.string, 'noSpecialCharacter', function(message, mapper = a => a) {
    return this.test('noSpecialCharacter', message, function(str) {
        const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if(format.test(str)){
            return false;
        } else {
            return true;
        }
    });
});

export default yup;