import ApiService from './ApiService';

class InquiryApi extends ApiService {

    constructor() {
        super('inquiry');
    }

    sendInquiry(payload) {
        return this.post('', payload);
    }
}

export default InquiryApi;