import { flow, makeObservable, observable } from 'mobx';
import { ThemeApi } from '../apis';
import BaseStore from './BaseStore';
import { COURSE_PERIOD_STATUS, COURSE_STATUS_COMPLETE } from '../core/configs/constants';

class ThemeStore extends BaseStore {

    themes = [];
    themeTree = [];
    canExpandIds = [];

    constructor(rootStore) {
        super();
        makeObservable(this, {
            themes: observable,
            themeTree: observable,
            canExpandIds: observable,
            getAllThemes: flow.bound,
            getAllThemesTreeView: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new ThemeApi();
    }

    *getAllThemes(payload, isCoursePage = false) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllThemes, payload);
            if(res?.ok) {
                if (isCoursePage) {
                    this.themes = res?.data || [];
                } else {
                    this.themes = res?.data;
                }
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *getAllThemesTreeView(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllThemes, payload);
            if(res?.ok) {
                let indexId = 0;
                this.themeTree = res?.data?.length > 0 ? res.data.map(theme => {
                    indexId += 1;
                    this.canExpandIds.push(indexId);
                    return {
                        name: theme.name,
                        metadata: {
                            ...theme,
                            hasClick: true,
                            isTheme: true,
                            className: 'theme-node'
                        },
                        children: theme.courses.map(course => {
                            indexId += 1;
                            this.canExpandIds.push(indexId);
                            return {
                                name: course.name,
                                metadata: {
                                    ...course,
                                    hasClick: true,
                                    isCourse: true,
                                    isAttend: course?.isAttend,
                                    className: 'course-node'
                                },
                                ...(this.rootStore.authStore.token ? { children: course.lectures.map(lecture => {
                                    indexId += 1;
                                    const hasClick = course?.isAttend && this.rootStore.userStore.coursePeriodStatus !== COURSE_PERIOD_STATUS.EXPIRED;
                                    return {
                                        name: lecture.name,
                                        metadata: {
                                            ...lecture,
                                            hasClick,
                                            isLecture: true,
                                            isAttend: hasClick,
                                            className: 'lecture-node'
                                        }
                                    }
                                })} : {})
                            }
                        })
                    }}
                ) : [];
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }
}

export default ThemeStore;