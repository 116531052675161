import ApiService from './ApiService';

class CreditApi extends ApiService {

    constructor() {
        super();
    }

    getCredits(payload) {
        return this.get('apply-course/history', payload);
    }
}

export default CreditApi;