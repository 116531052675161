import { flow, makeObservable } from 'mobx';
import { PasswordApi } from '../apis';

class PasswordStore {
    constructor(rootStore) {
        makeObservable(this, {
            forgetPassword: flow.bound,
            checkUuid: flow.bound,
            setPassword: flow.bound
        });
        this.rootStore = rootStore;
        this.api = new PasswordApi();
    }

    *forgetPassword(payload, cb) {
        try {
            const res = yield this.rootStore.apiStore.call(
                this.api,
                this.api.forgetPassword,
                payload
            );

            if (res?.ok) {
                cb()
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *checkUuid(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(
                this.api,
                this.api.checkUuid,
                payload,
                false,
                null,
                false,
                true
            );
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *setPassword(payload) {
        const {password, passwordConfirm ,uuid, isForgetPassword} = payload;

        const paramsIsForget = {
            'password': password, 
            'passwordConfirm': passwordConfirm, 
            'uuid': uuid, 
            'isForgetPassword': true
        }
        const paramsIsSetPass = {
            'password': password, 
            'passwordConfirm': passwordConfirm, 
            'uuid': uuid 
        }

        try {
            const res = yield this.rootStore.apiStore.call(
                this.api, 
                this.api.setPassword, 
                isForgetPassword == true ? paramsIsForget : paramsIsSetPass
            );
            
            if(res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }
}

export default PasswordStore;
