import { action, flow, makeObservable, observable } from 'mobx';
import { CreditApi } from '../apis';

class CreditStore {

    credits = [];

    constructor(rootStore) {
        makeObservable(this, {
            credits: observable,
            getCredit: flow.bound
        });
        this.rootStore = rootStore;
        this.api = new CreditApi();
    }

    *getCredit(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getCredits, payload);
            if(res?.ok) {
                this.credits = res?.data || [];
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export default CreditStore;
