import { useNavigate } from 'react-router-dom';
import { COURSE_TYPE } from '../../../core/configs/constants';
import { convertVideoDuration, extractContent } from '../../../core/utils/common';
import { useStore } from '../../../core/utils/hook';

const CourseCard = (props) => {

    const navigate = useNavigate();
    const {
        courseStore: { setSearchCourseInfo }
    } = useStore();


    const {
        name,
        themeName,
        totalLecture,
        totalTime,
        type,
        thumbnail,
        description
    } = props.course

    const onRefCourseListScreen = () => {
        setSearchCourseInfo(props.course);
        navigate('/course-list');
    }

    return (
        <div className='item'>
            {/* <div role={'button'} className='img' onClick={() => navigate(`/course/${id}`)}> */}
            <div role={'button'} className='img' onClick={() => onRefCourseListScreen()}>
                <a><img src={thumbnail || '/images/img-1.png'} alt='' /></a>
            </div>
            <h3 role={'button'} className='title-2' onClick={() => onRefCourseListScreen()}><a>{name ?? ''}</a></h3>
            <h4 className='title-category'><a>{themeName ?? ''}</a></h4>
            <div className='clock-video'>
                <ul>
                    <li>
                        <img src='/images/icon1.svg' alt='' /> 約 {convertVideoDuration(totalTime)} 分
                    </li>
                    <li> <img src='/images/icon2.svg' alt='' />{totalLecture ?? 0} コンテンツ</li>

                </ul>
                <ul className={type?.length > 1 ? 'visible' : 'invisible'}>
                    <li className='red'><img src='/images/icon4.svg' alt='' />
                        {
                            type?.length > 1 && (
                                type?.includes(COURSE_TYPE.SOCIETY_ACCREDITED_CREDITS) ? '精神薬学会認定薬剤師'
                                    : type?.includes(COURSE_TYPE.HERBAL_MEDICINE) ? '漢方薬・生薬認定薬剤師'
                                        : type?.includes(COURSE_TYPE.DRUG_THERAPY_CERTIFIED_PHARMACIST) ? '小児薬物療法認定薬剤師'
                                            : ''
                            )
                        }
                    </li>
                </ul>
            </div>
            <p className='desc text-justify-inter-word'>{description ? extractContent(description) : ''}</p>
            <a role={'button'} className='read-more' onClick={() => onRefCourseListScreen()}>もっと見る</a>
        </div>
    )
}

export default CourseCard;