import { Table } from '../../components';
import { convertVideoDuration } from '../../core/utils/common';
import { useStore } from '../../core/utils/hook';

const CourseListForTicketPlanModal = ({ ticket }) => {
    
    // store
    const { modalStore: { hide } } = useStore();

    const courses = ticket?.courses?.map(courses => {
        return {
            courseName: courses?.name,
            totalTime: `約 ${convertVideoDuration(courses?.lectures?.reduce((total,lecture) => total += lecture.video_length, 0))} 分`,
            totalLecture: courses?.lectures?.length,
            type: courses?.type
        }
    })

    // columns
    const courseListColumns = [
        {
            Header: 'No.',
            accessor: 'no',
            disableSortBy: true,
            Cell: ({ row: { index } }) => {
                return <div>{ index + 1}</div>
            },
            width: '5%'
        },
        {
            Header: '講座名',
            accessor: 'courseName',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <div>{original.courseName}</div>
                    </div>
                );
            },
            width: '22%'
        },
        {
            Header: '動画合計時間',
            accessor: 'totalTime',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <div>{original.totalTime}</div>
                    </div>
                );
            },
            width: '16%'
        },
        {
            Header: 'コンテンツ数',
            accessor: 'totalLecture',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <div>{original.totalLecture}</div>
                    </div>
                );
            },
            width: '16%'
        },
        {
            Header: '認定種別',
            accessor: 'type',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {

                const types = [...original.type].map(e => Number(e));
                let certificationType = '研修認定薬剤師';
                if (types.includes(1)) {
                    certificationType = '精神薬学会認定薬剤師';
                }

                if (types.includes(2)) {
                    certificationType = '漢方薬・生薬認定薬剤師';
                }

                if (types.includes(3)) {
                    certificationType = '小児薬物療法認定薬剤師';
                }

                return (
                    <div>
                        <div>{certificationType}</div>
                    </div>
                );
            },
            width: '30%'
        }
    ]

    return (
        <div className='course-list-ticketPlan-modal pd-20'>
            <div className='d-flex align-items-center flex-gap-15 flex-wrap'> 
                <div className='font-bold'>受講プラン</div>
                <div className='font-normal'>{ticket?.name}</div>
            </div>
            <div className='row justify-content-start mg-t-15'>
                <h4><b>講座一覧</b></h4>
            </div>
            <Table
                disableSelectionPageSize={true}
                disablePaging={true}
                enableServerSidePaging={false}
                columns={courseListColumns}
                data={courses}
                scrollHorizontalWidth={'768px'}
                className="mg-t-10"
            />
            <div className="text-center mg-t-20">
                <button type={'button'} className={'btn btn-background-primary text-white width-150 btn-icon-green mg-r-15'}
                    onClick={()=> hide()}>{'閉める'}</button>
            </div>
        </div>
    )
}

export default CourseListForTicketPlanModal;