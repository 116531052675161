/* eslint-disable max-len */
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { COURSE_TYPE, COURSE_TYPE_STRING, SYSTEM_PATH } from '../../core/configs/constants';
import { useQuery, useStore } from '../../core/utils/hook';
import CourseCard from './components/CoursCard';
import Pagination from './components/Pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { toJS } from 'mobx';
import { TreeViewCustom } from '../../components';

function CoursesScreen() {

    const navigate = useNavigate();
    const location = useLocation();
    const expandedIdsState = location.state?.expandedIds?.length > 0 ? location.state.expandedIds : []; 

    const [coursePublicDate, setCoursePublicDate] = useState();

    // store
    const {
        courseStore: { getAllCourses, allCourses, pagingAllCourses, setAttrObservable, cleanCourseData, searchCourseInfo, setSearchCourseInfo },
        themeStore: { themeTree, getAllThemesTreeView, canExpandIds },
        authStore: { token }
    } = useStore();
    const query = useQuery();
    const themeId = query.get('themeId') || undefined;

    // state
    const { register, handleSubmit, getValues, reset, setValue } = useForm({
        defaultValues: {
            courseType: ''
        }
    });

    const [ expandedIds, setExpandedIds ] = useState([]);
    const [ highlightExpand, setHighlightExpand ] = useState({
        key: 'themeId',
        value: themeId
    })

    useEffect(() => {
        cleanCourseData();
        getAllThemesTreeView({ treeView: true });
        history.replaceState({}, '');
    }, [])

    useEffect(() => {
        if(searchCourseInfo) {
            setValue('courseName', searchCourseInfo.name || '');
            const values = getValues();
            const payload = { ...values, coursePublicDate, isCourseList: true };
            getAllCourses(payload);
            setSearchCourseInfo(null);
        } else {
            getAllCourses({ themeId, courseType: getValues('courseType'), isCourseList: true });
        }
        // getAllThemes({}, true);
    }, [themeId]);

    useEffect(() => {
        if(themeTree?.length > 0) {
            if(expandedIdsState?.length > 0) {
                if(expandedIdsState.every(id => canExpandIds.includes(id))) {
                    setExpandedIds(expandedIdsState);
                }
            }
        }
    }, [themeTree])

    // useEffect(() => {
    //     if (themes?.length > 0 && themeId) {
    //         reset({ themeId: Number(themeId) })
    //     }
    // }, [themeId, themes])

    useEffect(() => {
        setHighlightExpand({
            key: 'themeId',
            value: themeId
        })
    }, [themeId])

    const handleSearch = () => {
        setAttrObservable('pagingAllCourses', { page: 1 }, true, false);
        const values = getValues();
        
        if (values.courseStatus === false) {
            delete values.courseStatus;
        }

        if (values.courseName) {
            values.courseName = values.courseName.trim();
        }
        const payload = { ...values, coursePublicDate };

        if (payload.coursePublicDate === false) {
            delete payload.coursePublicDate 
        }
        
        if (payload.courseStatus === false) {
            delete payload.courseStatus 
        }

        getAllCourses(payload);

        setHighlightExpand({
            key: 'themeId',
            value: undefined
        })
    };

    const handleChangePage = () => {
        const values = getValues();
        if (values.courseName) {
            values.courseName = values.courseName.trim();
        }
        const payload = { ...values, coursePublicDate };
        if (payload.coursePublicDate === false) {
            delete payload.coursePublicDate 
        }
        
        if (payload.courseStatus === false) {
            delete payload.courseStatus 
        }
        window.$('html, body').animate({scrollTop: 0 }, 250, 'easeInOutExpo');
        getAllCourses(payload);
    };

    const onClickTreeName = (element) => {
        if(element?.metadata) {
            if(element.metadata.isTheme) {
                onHandLeExpand(element, true);
                onResetSearch();
                if(themeId != element.metadata.themeId) {
                    navigate(`${SYSTEM_PATH.THEMES}?themeId=${element.metadata.themeId}`);
                } else {
                    getAllCourses({ themeId, page: 1, isCourseList: true });
                    setHighlightExpand({
                        key: 'themeId',
                        value: themeId
                    })
                }
            } else if(element.metadata.isCourse) {
                if(token) {
                    navigate(`/course/${element.metadata.courseId}`, { state: { expandedIds } });
                } else {
                    onResetSearch();
                    setHighlightExpand({
                        key: 'courseId',
                        value: element.metadata.courseId
                    })
                    getAllCourses({
                        courseId: element.metadata.courseId,
                        page: 1
                    })
                }
            } else if(element.metadata.isLecture && element.metadata.isAttend) {
                navigate(`/lecture/${element.metadata.lectureId}`)
            }
        }
    }

    const onHandLeExpand = (e, alwaysAdd = false) => {
        if(alwaysAdd || !expandedIds.includes(e.id)) {
            setExpandedIds([...expandedIds, e.id]);
        } else {
            if(e.children?.length > 0) {
                const closeIds = [e.id, ...e.children]; 
                setExpandedIds([...expandedIds].filter(id => !closeIds.includes(id)));
            }
        }
    }

    const elementClass = (element) => {
        if(highlightExpand.key && highlightExpand.value) {
            if(element?.metadata?.[highlightExpand.key] == highlightExpand.value) {
                return 'tree-name-highlight';
            }
        } return '';
    }

    const onResetSearch = (payload = {}) => {
        reset({
            courseName: '',
            keySearch: '',
            courseType: '',
            ...payload
        })
        setCoursePublicDate(undefined);
    }

    const onSelectAllTheme = () => {
        onResetSearch();
        if(themeId) {
            navigate(SYSTEM_PATH.THEMES);
        } else {
            handleSearch();
        }
    }

    const onDownloadCoursePdf = () => {
        window.open(`${window.location.origin}/files/elephamt講座一覧.pdf`, '_blank');
    }

    return (
        <div
            id="main"
            className="wrapper main-student-list main-Attended-couse main-view-course main-view-theme course-list-screen">
            {/* <Breadcrumb title='テーマ一覧' /> */}
            <div className="content-view-course content-main-view-course">
                <div className="container">
                    {/* filter */}
                    <section className='top-student-list'>
                        <form className="h-adr" onSubmit={handleSubmit(handleSearch)}>
                            <div className="content-top-student theme-filter">
                                <h3 className="title-secondary mt-0 mg-b-20">講座検索</h3>
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-12 ignore-css">
                                        <div className="item item-22 ignore-css flex-wrap">
                                            <label htmlFor="t-course-name">講座名</label>
                                            <input type='text' id='t-course-name' {...register('courseName')} />
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-12 ignore-css">
                                        <div className="item item-22 ignore-css flex-wrap">
                                            <label htmlFor="t-keySearch"> フリーワード</label>
                                            <input type='text' id='t-keySearch' {...register('keySearch')} />
                                            {/* <select className='select-theme' {...register('themeId')} id='t-themeId'>
                                                <option value={''}>全て</option>
                                                {
                                                    themes?.length > 0 &&
                                                    themes.map(e => <option key={e.id} value={e.id}>{e.name}</option>)
                                                }
                                            </select> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-12 ignore-css">
                                        <div className="item item-22 ignore-css flex-wrap">
                                            <label htmlFor="t-courseType">認定制度別</label>
                                            <select className='select-course-type ignore-css' {...register('courseType')} id='t-courseType'>
                                                <option value={''}>全て</option>
                                                <option value={COURSE_TYPE.TRAINING_CERTIFIED_PHARMACIST}>{COURSE_TYPE_STRING.TRAINING_CERTIFIED_PHARMACIST}</option>
                                                <option value={COURSE_TYPE.SOCIETY_ACCREDITED_CREDITS}>{COURSE_TYPE_STRING.SOCIETY_ACCREDITED_CREDITS}</option>
                                                <option value={COURSE_TYPE.HERBAL_MEDICINE}>{COURSE_TYPE_STRING.HERBAL_MEDICINE}</option>
                                                <option value={COURSE_TYPE.DRUG_THERAPY_CERTIFIED_PHARMACIST}>{COURSE_TYPE_STRING.DRUG_THERAPY_CERTIFIED_PHARMACIST}</option>
                                                <option value={COURSE_TYPE.NON_CERTIFICATE}>{COURSE_TYPE_STRING.NON_CERTIFICATE}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className="item item-22 item-23 item-23-custom ignore-css">
                                            <label htmlFor="">公開日順</label>
                                            <ul className="list-check justify-content-start flex-wrap flex-gap-20 pd-l-0 ignore-css">
                                                <li className='search-checkbox'>
                                                    <input
                                                        id="st-0"
                                                        type="checkbox"
                                                        checked={coursePublicDate === 0}
                                                        onChange={event => {
                                                            const checked = event.target.checked;
                                                            if (checked) {
                                                                setCoursePublicDate(0);
                                                            } else {
                                                                setCoursePublicDate(undefined);
                                                            }
                                                        }}
                                                    />
                                                    <label className='cursor-pointer' htmlFor='st-0'>新しい順</label>
                                                </li>
                                                <li className='search-checkbox'>
                                                    <input
                                                        id="st-1"
                                                        type="checkbox"
                                                        checked={coursePublicDate === 1}
                                                        onChange={event => {
                                                            const checked = event.target.checked;
                                                            if (checked) {
                                                                setCoursePublicDate(1);
                                                            } else {
                                                                setCoursePublicDate(undefined);
                                                            }
                                                        }}
                                                    />
                                                    <label className='cursor-pointer' htmlFor='st-1'>古い順</label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                                {token && <div className='row'>
                                    <div className="col-md-9 col-sm-9 col-12 last-filter">
                                        <div className="item item-22 item-24 item-23-custom ignore-css">
                                            <label className='last-filter-label'>受講ステータス</label>
                                            <ul className="list-check justify-content-start flex-wrap flex-gap-20 ignore-css">
                                                <li className='search-checkbox'>
                                                    <input id="cs-0" type="checkbox" {...register('courseStatus')} value={0} />
                                                    <label className='cursor-pointer' htmlFor='cs-0'>未受講</label>
                                                </li>
                                                <li className='search-checkbox'>
                                                    <input id="cs-1" type="checkbox"  {...register('courseStatus')} value={1} />
                                                    <label className='cursor-pointer' htmlFor='cs-1'>受講中</label>
                                                </li>
                                                <li className='search-checkbox'>
                                                    <input id="cs-2" type="checkbox"  {...register('courseStatus')} value={2} />
                                                    <label className='cursor-pointer' htmlFor='cs-2'>受講完了</label>
                                                </li>
                                                {/* <li>
                                                    <input id="cs-3" type="checkbox"  {...register('courseStatus')} value={3} />
                                                    <label className='cursor-pointer' htmlFor='cs-3'>期限切れ</label>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>}
                                
                                <div className="text-center">
                                    <input type="submit" value="検索" />
                                </div>
                            </div>
                        </form>
                    </section>
                    {/* end filter */}

                    <div className='content-view-course-bottom'>
                        <div className='theme-tree-view'>
                            <div className='tree-all-node fw-bold' onClick={onSelectAllTheme} style={{fontSize: '15px'}}>すべてのテーマ</div>
                            <TreeViewCustom
                                data={{
                                    name: 'すべてのテーマ',
                                    children: toJS(themeTree)
                                }}
                                onClickTreeName={onClickTreeName}
                                onHandLeExpand={onHandLeExpand}
                                expandedIds={expandedIds}
                                elementClass={elementClass}
                            />
                        </div>

                        <div className="view-course-right">
                            <div className="title-search">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h3 className="title-2 mg-b-0">{pagingAllCourses?.totalRecord}件</h3>
                                    {
                                        token && (
                                            <button className='w-auto btn btn-danger btn-export-course-list' onClick={onDownloadCoursePdf}>
                                                講座一覧PDF
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="content-course-right">
                                {(allCourses || []).map((item) => (
                                // <CourseCard key={item.id} course={item} onFetchData={handleSearch} />
                                    <CourseCard key={item.id} course={item} onFetchData={() => navigate(`${SYSTEM_PATH.COURSE}${item.id}`)} />
                                ))}
                                {!allCourses?.length && (
                                    <div className="no-data w-100">
                                        <p>データはありません</p>
                                    </div>
                                )}
                            </div>
                            {allCourses?.length > 0 && <Pagination paging={pagingAllCourses} onFetch={handleChangePage} />}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default observer(CoursesScreen);
