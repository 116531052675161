import { flow, makeObservable, observable } from 'mobx';
import { GroupAdminApi } from '../apis';
import { STUDENT_STATUS } from '../core/configs/constants';
import BaseStore from './BaseStore';

class GroupAdminStore extends BaseStore {

    studentsOfCompany = [];
    studentsOfCompanyActive = [];
    studentsOfCompanyOtherActive = [];
    disabledStudentIdx = [];
    pagingStudentOfCompany = this.defaultPaging;

    studentDetail = {};

    courseListByStudent = {};
    studentListByAttendanceCode = {};

    attendancesOfCompany = [];

    ticketByGroup = [];

    companyAttendanceRateList = [];
    studentListWithAttCompany = [];

    constructor(rootStore) {
        super();
        makeObservable(this, {
            studentsOfCompany: observable,
            studentsOfCompanyActive: observable,
            studentsOfCompanyOtherActive: observable,
            disabledStudentIdx: observable,
            pagingStudentOfCompany: observable,
            studentDetail: observable,
            attendancesOfCompany: observable,
            courseListByStudent: observable,
            studentListByAttendanceCode: observable,
            ticketByGroup: observable,
            companyAttendanceRateList: observable,
            studentListWithAttCompany: observable,
            getStudentsOfCompanyByCourseStatus: flow.bound,
            getAttendancesOfCompany: flow.bound,
            getStudentsOfCompany: flow.bound,
            getStudentDetail: flow.bound,
            getCourseListByStudent: flow.bound,
            getStudentListByAttendanceCode: flow.bound,
            getTicketByGroup: flow.bound,
            exportStudentCSV: flow.bound,
            updateStatusStudent: flow.bound,
            getCompanyAttendanceRate: flow.bound,
            getDetailCompanyAttendanceRate: flow.bound
        });
        this.rootStore = rootStore;
        this.api = new GroupAdminApi();
    }

    *getStudentsOfCompanyByCourseStatus(searchParams) {
        try {
            const { size, page, sortDir, sortKey } = this.pagingStudentOfCompany;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getStudentsOfCompanyByCourseStatus, payload);
            if(res?.ok) {
                this.studentsOfCompany = res?.data?.[0]?.elements || [];
                this.setAttrObservable('pagingStudentOfCompany', res?.data?.[0]?.paginate, true, false);
            }
        } catch(error) {
            console.log(error);
        }
    }

    *getAttendancesOfCompany(searchParams) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAttendancesOfCompany, searchParams);
            if(res?.ok) {
                this.attendancesOfCompany = res?.data;
            }
        } catch(error) {
            console.log(error);
        }
    }

    *getStudentsOfCompany(searchParams) {
        try {
            const { size, page, sortDir, sortKey } = this.pagingStudentOfCompany;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getStudentsOfCompany, payload);
            if(res?.ok) {
                this.studentsOfCompany = res?.data?.elements || [];
                this.studentsOfCompanyActive = res?.data?.elements?.filter(e => e.status === STUDENT_STATUS.ACTIVE);
                this.studentsOfCompanyOtherActive = res?.data?.elements?.filter(e => e.status !== STUDENT_STATUS.ACTIVE);
                this.disabledStudentIdx = res?.data?.elements?.map(e => e.status !== STUDENT_STATUS.ACTIVE);
                this.setAttrObservable('pagingStudentOfCompany', res?.data?.paginate, true, false);
            }
        } catch(error) {
            console.log(error);
        }
    }

    *getStudentDetail(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getStudentDetail, {id, payload});
            if(res?.ok) {
                this.studentDetail = res?.data;
            }
        } catch(error) {
            console.log(error);
        }
    }

    *getCourseListByStudent(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getCourseListByStudent, {id, payload});
            if(res?.ok) {
                this.courseListByStudent = res?.data;
            }
        } catch(error) {
            console.log(error);
        }
    }

    *getStudentListByAttendanceCode(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getStudentListByAttendanceCode, {id, payload});
            if(res?.ok) {
                this.studentListByAttendanceCode = res?.data;
            }
        } catch(error) {
            console.log(error);
        }
    }

    *getTicketByGroup(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getTicketByGroup, payload);
            if(res?.ok) {
                this.ticketByGroup = res?.data;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *exportStudentCSV(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.exportStudentCSV, payload);
        } catch (error) {
            console.log(error);
        }
    }

    *updateStatusStudent(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateStatusStudent, {id, payload});
            if(res?.ok) {
                return true;
            }
            return false;
        } catch(error) {
            console.log(error);
        }
    }

    *getCompanyAttendanceRate(searchParams) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getCompanyAttendanceRate, searchParams);
            if(res?.ok) {
                this.companyAttendanceRateList = res?.data;
            }
        } catch(error) {
            console.log(error);
        }
    }
    *getDetailCompanyAttendanceRate(id,searchParams) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getDetailCompanyAttendanceRate, {id: id , payload:searchParams});
            if(res?.ok) {
                this.studentListWithAttCompany = res?.data;
            }
        } catch(error) {
            console.log(error);
        }
    }

    clean() {
        super.clean();
        this.pagingStudentOfCompany = this.defaultPaging;
    }
}

export default GroupAdminStore;
