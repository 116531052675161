import { GENERAL_TYPE } from '../../../core/configs/constants';

export const getGeneralSettingValueByType = (data, type) => {
    let value = '';
    switch(type) {
    case GENERAL_TYPE.USER_GUIDE:
        value = data?.userGuide || ''
        break;
    case GENERAL_TYPE.TERM_SERVICE:
        value = data?.termService || ''
        break;
    case GENERAL_TYPE.PRIVACY_POLICY:
        value = data?.privacyPolicy || ''
        break;
    case GENERAL_TYPE.CANCELLATION_POLICY:
        value = data?.cancellationPolicy || ''
        break;
    case GENERAL_TYPE.RECOMMENDED_ENVIRONMENT:
        value = data?.recommendedEnvironment || ''
        break;
    case GENERAL_TYPE.TRANSACTION_LAW:
        value = data?.transactionLaw || ''
        break;
    case GENERAL_TYPE.ABOUT_US:
        value = data?.aboutUs || ''
        break;
    case GENERAL_TYPE.ABOUT_COMPANY:
        value = data?.aboutCompany || ''
        break;
    default:
        break;
    }

    return value;
}