/* eslint-disable max-len */
import { ArcElement, Chart as ChartJS, Legend, Tooltip, elements } from 'chart.js';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TreeViewCustom } from '../../components';
import { useStore } from '../../core/utils/hook';
import LectureCard from './components/LectureCard';
import Pagination from './components/Pagination';
import { isShowCompletionTest, isShowJoinCourse, isShowReTest } from './ultis/courses.ultis';
import { MSG, SYSTEM_PATH } from '../../core/configs/constants';
import { toJS } from 'mobx';
ChartJS.register(ArcElement, Tooltip, Legend);

const CourseDtailScreen = observer(() => {

    const location = useLocation();
    const expandedIdsState = location.state?.expandedIds?.length > 0 ? location.state.expandedIds : []; 

    const [errorMessage, setErrorMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [ expandedIds, setExpandedIds ] = useState([]);

    const navigate = useNavigate();


    const { id } = useParams();
    const {
        courseStore: {
            coursDetail,
            lectureByCourse,
            pagingLectureListByCourse,
            getLectureListByCourse,
            getLectureListByCourseInternal,
            getCourseInternalById,
            getCourseById,
            studentBuyCourse
        },
        authStore: { token, userInfo },
        modalStore: { hide, openAlert },
        userStore: { userInfoHeader, getUserInfoHeader, onAddTicketPlan, coursePeriodStatus },
        themeStore: { themeTree, getAllThemesTreeView, canExpandIds }
    } = useStore();

    const onFetchData = () => {
        if (token) {
            getLectureListByCourseInternal({ id: id, ...pagingLectureListByCourse });
        } else {
            getLectureListByCourse({ id: id, ...pagingLectureListByCourse });
        }
        getAllThemesTreeView({ treeView: true });
        history.replaceState({}, '');
    };

    useEffect(() => {
        if (!id) return;
        onFetchData();
    }, [id]);

    useEffect(() => {
        if (!id) return;
        if (token) {
            getCourseInternalById({ id: id }).then(res => {
                if (!res?.ok) {
                    setIsError(true);
                    setErrorMessage(res?.message|| '講座が存在しません。')
                } else {
                    setIsError(false)
                }
            }).catch(error => {
                console.log('error:', error)
            });
        } else {
            getCourseById({ id: id }).then((res) => {
                if (!res?.ok) {
                    setIsError(true);
                    setErrorMessage(res?.message|| '講座が存在しません。')
                } else {
                    setIsError(false)
                }
            }).catch(error => {
                console.log('error:', error)
            });
        }
    }, [id, token])

    useEffect(() => {
        if(themeTree?.length > 0) {
            if(expandedIdsState?.length > 0) {
                if(expandedIdsState.every(id => canExpandIds.includes(id))) {
                    setExpandedIds(expandedIdsState);
                }
            }
        }
    }, [themeTree])

    if (isError) {
        return <div className="col-md-10 col-sm-10 col-12"><div style={{ textAlign: 'center' }}>{errorMessage}</div> </div>;
    }

    const handleBuyCourse = () => {
        const id = coursDetail?.id;
        if (!token) {
            openAlert(MSG['warning.student.apply.ticket'], () => {
                hide();
            })
        } else {
            if (userInfoHeader?.numberTicket == 0) {
                onAddTicketPlan();
            } else {
                studentBuyCourse(id).then(res => {
                    if (res?.ok) {
                        if (token) {
                            getUserInfoHeader();
                        }
                        // onFetchData && onFetchData();
                        window.location.reload(false)
                    }
                });
            }
        }
    }

    const handleClickCompletionTest = () => {
        navigate(`/course/${id}/completion-test`);
    }

    const handleClickReTest = (event) => {
        event.stopPropagation();
        navigate(`/course/${coursDetail?.id}/completion-test`);
    }

    // function theme tree view
    const onClickTreeName = (element) => {
        if(element?.metadata) {
            if(element.metadata.isTheme) {
                navigate(`${SYSTEM_PATH.THEMES}?themeId=${element.metadata.themeId}`, { state: { expandedIds } });
            } else if(element.metadata.isCourse) {
                if(token) {
                    navigate(`/course/${element.metadata.courseId}`);
                }
            } else if(element.metadata.isLecture && element.metadata.isAttend) {
                navigate(`/lecture/${element.metadata.lectureId}`)
            }
        }
    }

    const onHandLeExpand = (e, alwaysAdd = false) => {
        if(alwaysAdd || !expandedIds.includes(e.id)) {
            setExpandedIds([...expandedIds, e.id]);
        } else {
            if(e.children?.length > 0) {
                const closeIds = [e.id, ...e.children]; 
                setExpandedIds([...expandedIds].filter(id => !closeIds.includes(id)));
            }
        }
    }

    const elementClass = (element) => {
        if(element?.metadata?.isCourse && element.metadata.courseId && id && element.metadata.courseId == id) {
            return 'tree-name-highlight';
        } return '';
    }

    return (
        <div id="main" className="wrapper main-student-list main-Attended-couse main-view-course main-course-detail course-detail-screen">
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li>
                            <a href="/"><img src="/images/icon-home.svg" alt="" /></a> 
                        </li>
                        <li className="dash">\</li>
                        <li>
                            <a href={SYSTEM_PATH.THEMES + `?themeId=${coursDetail?.theme?.themeId}`}>{coursDetail?.theme?.themeName}</a>
                        </li>
                        <li className="dash">\</li>
                        <li>{coursDetail?.name}</li>
                    </ul>
                </div>
            </div>
            <div className="content-view-course">
                <div className="container">
                    <div className="content-course-right">
                        <div className='content-view-course-bottom mg-t-0 mg-b-30'>
                            <div></div>
                            <div className="container-buy-courses content-course-detail">
                                <h1 className="title-primary mg-b-0">コンテンツ一覧</h1>
                                {isShowJoinCourse(token, coursDetail, userInfo) && 
                                    <button role='button' onClick={handleBuyCourse} className="btn-buy-courses">受講ボタン</button>}
                                {isShowCompletionTest(token, coursDetail, coursePeriodStatus) && 
                                    <button type='button' onClick={handleClickCompletionTest} className="btn-buy-courses">修了テスト</button>}
                                {isShowReTest(token, coursDetail, coursePeriodStatus) &&
                                    <button type='button' onClick={handleClickReTest} className="btn-buy-courses">再テストの実施</button>}
                            </div>
                        </div>
                        <div className='content-view-course-bottom mg-t-0'>
                            <div className='theme-tree-view'>
                                <TreeViewCustom
                                    data={{
                                        name: 'すべてのテーマ',
                                        children: toJS(themeTree)
                                    }}
                                    onClickTreeName={onClickTreeName}
                                    onHandLeExpand={onHandLeExpand}
                                    expandedIds={expandedIds}
                                    elementClass={elementClass}
                                />
                            </div>
                            <div className='content-course-detail'>
                                {(lectureByCourse?.listLecture?.elements || []).map((item, idx) => (
                                    <LectureCard key={idx} lecture={item} />
                                ))}
                                {lectureByCourse?.listLecture?.elements?.length > 0 ? (
                                    <Pagination paging={pagingLectureListByCourse} onFetch={onFetchData} />
                                ) : (
                                    <div style={{ textAlign: 'center' }}>データはありません</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default CourseDtailScreen;
