import { NumericFormat } from 'react-number-format';

const FormatNumber = (props) => {

    const { className, value } = props;

    return(
        <NumericFormat 
            displayType={'text'}
            className={className}
            value={value ?? 0} 
            thousandSeparator=','
            decimalSeparator='.'
        />
    )
}

export default FormatNumber;