import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useStore } from '../../core/utils/hook';
import { BeatLoader } from 'react-spinners';
import Spinner from './Spinner';
import './style.scss';

const Loading = observer(() => {

    const { apiStore: { loading } } = useStore();

    return(
        <div id='loading-src' className={classnames('loading-src', !loading && 'd-none')}>
            <img className='image-logo' src='/images/elephamt_logo.svg'/>
            <BeatLoader className='mg-t-30' color='#36d7b7' margin={5} size={20}/>
            {/* <Spinner/> */}
        </div>
    )
})

export default Loading;