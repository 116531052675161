import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { SYSTEM_PATH, USER_TYPE } from '../../../core/configs/constants';
import { useStore } from '../../../core/utils/hook';

const ProfileSideBar = observer(({ type }) => {

    // other
    const location = useLocation();
    
    // store
    const { userStore: { getProfile, updateProfile, profile,userInfoHeader }, authStore: { userInfo } } = useStore();


    const params = useParams() || {};
    const path = params['*'];


    if (!(profile || userInfo)?.id) return null;

    return (
        <div className="aside-left">
            <p className="title-admin"><img src={type === 'test-history-screen' ? '../images/icon9.svg' : 'images/icon9.svg'} alt="" />
                <span>{`${(profile || userInfoHeader)?.firstName || ''} ${(profile || userInfoHeader)?.lastName || ''}`}</span>
            </p>
            <ul className="list-account">
                <li className={classNames('li-1', 
                    (location.pathname === SYSTEM_PATH.PROFILE || location.pathname === SYSTEM_PATH.PROFILE_EDIT) && 'active' )}>
                    <Link to={SYSTEM_PATH.PROFILE}>ユーザー情報</Link>
                </li>
                <li className="li-2">
                    <Link to={SYSTEM_PATH.CHANGE_PASSWORD}>パスワード変更</Link>
                </li>
                {
                    (userInfo?.roleId === USER_TYPE.STUDENT_INDIVIDUAL || userInfo?.roleId === USER_TYPE.STUDENT_GROUP)  && 
                    <>
                        <li className={classNames('li-3', location.pathname === SYSTEM_PATH.PURCHASE_HISTORY && 'active')}>
                            <Link to={SYSTEM_PATH.PURCHASE_HISTORY}>購入履歴</Link>
                            {
                                userInfoHeader?.haveTransactionNoPaymentAndProcessing &&
                                <span className='dot-highlight'></span>
                            }
                        </li>
                        <li className={classNames('li-4', location.pathname === SYSTEM_PATH.TOOL_CERTIFICATE && 'active')}>
                            <Link to={SYSTEM_PATH.TOOL_CERTIFICATE}>単位管理ツール</Link>
                        </li>
                    </>
                }
            </ul>
        </div>
    )
})

export default ProfileSideBar;