import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from '../../core/utils/hook';
import { Table } from '../../components';
import { Link } from 'react-router-dom';
import { COURSE_BY_STUDENT_STATUS } from '../../core/configs/constants';
import NotifyList from './components/NotifyList';

const GAHomeScreen = observer((props) => {

    // store
    const { 
        groupAdminStore: { 
            studentsOfCompany, pagingStudentOfCompany, attendancesOfCompany, companyAttendanceRateList,
            getAttendancesOfCompany, getStudentsOfCompanyByCourseStatus, setAttrObservable, getCompanyAttendanceRate, clean
        } 
    } = useStore();

    // lifecycle
    useEffect(() => {
        clean();
        getAttendancesOfCompany();
        // getStudentsOfCompanyByCourseStatus();
        getCompanyAttendanceRate();
    }, [])

    // function
    const onFetchData = (tableData, unsetPaging) => {
        !unsetPaging && setAttrObservable('pagingStudentOfCompany', tableData, true, false);
        // getStudentsOfCompanyByCourseStatus(tableData);
        getCompanyAttendanceRate(tableData);
    }

    // table columns
    const attendanceColumns = [
        {
            Header: '受講コード',
            accessor: 'attendanceCode',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.attendanceCode}
                    </div>
                );
            },
            width: '25%'
        },
        {
            Header: '受講コース',
            accessor: 'ticketName',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.ticketName}
                    </div>
                );
            },
            width: '25%'
        },
        {
            Header: '制限人数',
            accessor: 'limit',
            disableSortBy: true,

            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.limit}
                    </div>
                );
            },
            width: '15%'
        },
        {
            Header: '有効期限',
            accessor: 'expirationDate',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        { original?.expirationDate?.replace(/-/g, '/')}
                    </div>
                );
            },
            width: '20%'
        },
        {
            Header: '受講者数',
            accessor: 'numberUsed',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <Link to={`/student-list-by-attendance-code/${original.id}`}>{ original?.numberUsed }</Link>
                    </div>
                );
            },
            width: '15%'
        }
    ]

    const companyAttendanceColumns = [
        {
            Header: '受講コード',
            accessor: 'attendanceCode',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>{original?.attendanceCode}</div>
                );
            },
            width: '20%'
        },
        // {
        //     Header: '未受講',
        //     accessor: 'totalCourseNotAttendance',
        //     disableSortBy: true,
        //     Cell: ({ row: { original } }) => {
        //         return (
        //             <div>
        //                 <Link to={`/course-list-by-student/${original.id}?status=${COURSE_BY_STUDENT_STATUS.USER_COURSE_NOT_ATTENDED.key}`}>
        //                     {original?.totalCourseNotAttendance}
        //                 </Link>
        //             </div>
        //         );
        //     },
        //     width: '16%'
        // },
        {
            Header: '受講コース',
            accessor: 'ticketName',
            disableSortBy: true,

            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.ticketName}
                    </div>
                );
            },
            width: '17.5%'
        },
        {
            Header: '消化率50%未満',
            accessor: 'lt50',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <Link 
                            to={`/student-list-by-attendance-list-code/${original.groupAttendanceId}`} 
                            state={{attendanceCode: original?.attendanceCode, lstUser : JSON.stringify(original.userIDlt50) }}>
                            { original?.lt50}
                        </Link>
                    </div>
                );
            },
            width: '18.5%'
        },
        {
            Header: '消化率50%以上100%未満',
            accessor: 'gte50',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <Link 
                            to={`/student-list-by-attendance-list-code/${original.groupAttendanceId}`} 
                            state={{attendanceCode: original?.attendanceCode, lstUser : JSON.stringify(original.userIDgte50)}}>
                            { original?.gte50}
                        </Link>
                    </div>
                );
            },
            width: '18.5%'
        },
        {
            Header: '消化率100%',
            accessor: 'eq100',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <Link 
                            to={`/student-list-by-attendance-list-code/${original.groupAttendanceId}`} 
                            state={{attendanceCode: original?.attendanceCode, lstUser : JSON.stringify(original.userIDeq100)}}>
                            { original?.eq100 }
                        </Link>
                    </div>
                );
            },
            width: '18.5%'
        }
    ]

    return(
        <div id='main' className='wrapper main-student-list main-view-student-detail'>
            <section className='Banner-Home'>
                {/* <div className='img-banner'>
                    <a href><img src='/images/banner.png' alt='' /></a>
                </div>
                <div className='content-banner'>
                    <div className='content'>
                        <h2 className='title-1'>選ばれる薬剤師に一歩近づく！</h2>
                        <h2 className='title-1'>臨床力が身につくeラーニングサイト</h2>
                        <p className='desc'>elephamtのすべての配信コンテンツは研修認定薬剤の単位対象講座となります。</p>
                    </div>
                </div> */}
                <NotifyList/>
            </section>
            <section className='content-student-list content-view-student-detail pd-t-75'>
                <div className='container'>
                    <h1 className='title-primary'>受講コード別の受講者数</h1>
                    <Table
                        columns={attendanceColumns}
                        data={attendancesOfCompany || []}
                        disablePaging={true}
                        className='lst-attendance-table mg-t-35'
                    />
                </div>
            </section>
            
            <section className='content-student-list content-view-student-detail content-view-student-detail-2'>
                <div className='container'>
                    <h1 className='title-primary'>受講コード別コース消化率</h1>
                    <Table
                        columns={companyAttendanceColumns}
                        data={companyAttendanceRateList.elements || []}
                        enableServerSidePaging={true}
                        initialTableState={pagingStudentOfCompany}
                        onFetch={onFetchData}
                        disablePaging={false}
                        disableSelectionPageSize={true}
                        showPagingInfo={false}
                        className='lst-attendance-table mg-t-35'
                    />
                </div>
            </section>
            {/* <section className='content-student-list content-view-student-detail content-view-student-detail-2'>
                <div className='container'>
                    <h1 className='title-primary'>ステータス別の各従業員の統計</h1>
                    <Table
                        columns={studentColumns}
                        data={studentsOfCompany || []}
                        enableServerSidePaging={true}
                        initialTableState={pagingStudentOfCompany}
                        onFetch={onFetchData}
                        disablePaging={false}
                        disableSelectionPageSize={true}
                        showPagingInfo={false}
                        className='lst-attendance-table mg-t-35'
                    />
                </div>
            </section> */}
        </div>

    )
})

export default GAHomeScreen;