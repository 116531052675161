/* eslint-disable max-len */
import classNames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { COURSE_PERIOD_STATUS, DATE_TIME_YMD, SYSTEM_PATH, USER_TYPE } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { onLoadNavbar } from '../../core/utils/main';

const Header = observer((props) => {

    // other
    const navigate = useNavigate();
    const location = useLocation();

    // store
    const { 
        authStore: { token, userInfo, setShowLoadingSrc }, 
        userStore: { getUserInfoHeader, userInfoHeader, coursePeriodStatus, onAddTicketPlan, onExtendCoursePeriod },
        messageStore: { getAllMessages, messages, totalMessages }
    } = useStore();

    const isCoursePeriodExpired = useMemo(() => {
        return coursePeriodStatus === COURSE_PERIOD_STATUS.EXPIRED
    }, [coursePeriodStatus])

    // state
    const [showMessage, setShowMessage] = useState(false);
    const [typeMessage, setTypeMessage] = useState(0);
    const [loadNavbarFirst, setLoadNavbarFirst] = useState(false);

    const textAddTicket = useMemo(() => {
        if(userInfoHeader) {
            const { allowBuyTicket, displayAttendanceCode } = userInfoHeader;
            if(allowBuyTicket && displayAttendanceCode) return '今すぐ受講';
            if(displayAttendanceCode) return '受講コード入力';
            return '今すぐ受講';
        }
        return '';
    }, [userInfoHeader])

    // lifecycle
    useEffect(() => {
        const getData = async () => {
            setShowLoadingSrc(true);
            if(token) {
                await getUserInfoHeader();
                await getAllMessages({}, false, true);
            }
            if(!loadNavbarFirst) {
                window.$('#main-nav').hide();
                setLoadNavbarFirst(true);
            }
            setShowLoadingSrc(false);
        }
        getData();
    }, [token, location.pathname])

    useEffect(() => {
        if(loadNavbarFirst) {
            onLoadNavbar();
        }
    }, [loadNavbarFirst])

    useEffect(() => {
        if(userInfo?.roleId != null && userInfoHeader?.role != null && userInfo?.roleId !== userInfoHeader?.role) {
            onLogout();
        }
    }, [userInfo, userInfoHeader])

    useEffect(() => {
        if(showMessage) {
            onClickOutSideMessage();
        }
    }, [showMessage])

    // function
    const onLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = SYSTEM_PATH.HOME;
    }

    const onClickOutSideMessage = () => {
        const $el = window.$('.bell');
        const $ee = window.$('.scope');

        window.$(document).unbind('click');

        window.$(document).on('click',function(e){
            if((window.$(e.target) != $el) && !window.$(e.target).hasClass('change-type-message') && ($ee.hasClass('active'))){
                setShowMessage(false);
            }
        });
    }

    const onToggleShowMessage = async () => {
        if(!showMessage) {
            await getAllMessages({}, false, true);
            setTypeMessage(0);
        }
        setShowMessage(!showMessage);
    }

    const onChangeTypeMessage = async (type) => {
        await getAllMessages(type === 0 ? {} : { seen: 0 }, false, true);
        setTypeMessage(type);
    }

    const onOpenMessageDetail = (id) => {
        navigate(`${SYSTEM_PATH.MESSAGE}${id}`);
    }

    const onNavigateHome = () => {
        navigate(SYSTEM_PATH.HOME);
    }

    return (
        <header id='header-site' className='header-after-login'>
            {/* begin mobile */}
            <div className='main-logo'>
                <div className='wrapper cf'>
                    <nav id='main-nav'>
                        {
                            token ? 
                                <>
                                    <ul className='login-2'>
                                        <li className='login-admin'>
                                            <span className='d-flex align-items-center'>
                                                <span className='icon-1'><img src='/images/i-9.png' /></span>
                                                <span className='nav-icon-1'> <div className='nav-bar-name'>{userInfoHeader?.firstName} {userInfoHeader?.lastName}</div></span>
                                            </span>
                                        </li>
                                    </ul>
                                    {
                                        userInfo?.roleId !== USER_TYPE.GROUP_MANAGER &&
                                        <ul className='login-box'>
                                            {
                                                coursePeriodStatus !== COURSE_PERIOD_STATUS.NOT_HAVE &&
                                                <li>
                                                    <span>受講期間 <span className={classNames(isCoursePeriodExpired && 'warning-expired')}>{isCoursePeriodExpired && '(期限切れ)'}</span></span> 
                                                    {isCoursePeriodExpired && <br/>}
                                                    <span className={classNames(isCoursePeriodExpired && 'warning-expired')}>
                                                        {userInfoHeader?.coursePeriodStart?.replace(/-/g, '/')} - {userInfoHeader?.coursePeriodEnd?.replace(/-/g, '/')}
                                                    </span>
                                                </li>
                                            }
                                            {
                                                (!isCoursePeriodExpired && userInfoHeader?.ticket) &&
                                                <>
                                                    <li>{userInfoHeader?.ticket?.name}受講中</li>
                                                    <li className='red'>未受講数: {userInfoHeader?.numberTicket ?? 0}講座（単位)</li>
                                                </>
                                            }
                                            {
                                                (userInfoHeader?.allowBuyTicket || userInfoHeader?.displayAttendanceCode) &&
                                                <li className='btn'>
                                                    <a onClick={onAddTicketPlan}>{textAddTicket}<i className='fa fa-caret-right' aria-hidden='true' style={{marginLeft: '5px'}} />
                                                        <i className='fa fa-caret-right' aria-hidden='true' />
                                                    </a>
                                                </li>
                                            }
                                        </ul>
                                    }
                                </>
                                :
                                <ul className='link-login'>
                                    <li className='link-login-button'><Link className='link-register-button' to={SYSTEM_PATH.REGISTRATION_FIRST_STEP}>新規お申込み</Link></li>
                                    <li className='li-login link-login-button'>
                                        <Link to={SYSTEM_PATH.LOGIN}>ログイン</Link>
                                    </li>
                                </ul>
                        }
                        <ul className='second-nav'>
                            {
                                token &&
                                <li>
                                    アカウント情報
                                    <ul className='nav-sub-menu'>
                                        <li>
                                            <Link to={SYSTEM_PATH.PROFILE}>
                                                <img src='/images/i-5.png' /> ユーザー情報
                                            </Link>
                                        </li>
                                        <li><Link to={SYSTEM_PATH.CHANGE_PASSWORD}><img src='/images/i-6.png' /> パスワード変更</Link></li>
                                        {
                                            (userInfo?.roleId === USER_TYPE.STUDENT_INDIVIDUAL || userInfo?.roleId === USER_TYPE.STUDENT_GROUP) &&
                                            <>
                                                <li><Link to={SYSTEM_PATH.PURCHASE_HISTORY}><img src='/images/i7.png' /> 購入履歴</Link></li>
                                                <li><Link to={SYSTEM_PATH.TOOL_CERTIFICATE}><img src='/images/i-17.svg' /> 単位管理ツール</Link></li>
                                            </>
                                        }
                                    </ul>
                                </li>
                            }
                            {
                                token && (userInfo?.roleId === USER_TYPE.STUDENT_INDIVIDUAL || userInfo?.roleId === USER_TYPE.STUDENT_GROUP) &&
                                <>
                                    <li>
                                        <Link to={SYSTEM_PATH.ATTENDANCE_HISTORY}>受講履歴</Link>
                                    </li>
                                    <li>
                                        <Link to={SYSTEM_PATH.THEMES}>講座一覧</Link>
                                    </li>
                                </>
                            }
                            {
                                userInfo?.roleId === USER_TYPE.GROUP_MANAGER && 
                                <li>
                                    <Link to={SYSTEM_PATH.STUDENT_GROUP_LIST}>団体受講者管理</Link>
                                </li>
                            }
                            {
                                (!token || userInfo?.roleId === USER_TYPE.GROUP_MANAGER) &&
                                <li>
                                    <Link to={SYSTEM_PATH.THEMES}>講座一覧</Link>
                                </li>
                            }
                            {
                                !token &&
                                <li>
                                    <Link to={SYSTEM_PATH.TICKETS}>受講プラン案内</Link>
                                </li>
                            }
                            <li>
                                <Link to={SYSTEM_PATH.USER_GUIDE}>利用案内</Link>
                            </li>
                            <li>
                                <Link to={SYSTEM_PATH.FAQ_SCREEN}>よくある質問</Link>
                            </li>
                            <li>
                                <Link to={SYSTEM_PATH.SEND_INQUIRY}>お問い合わせ</Link>
                            </li>
                            {
                                token &&
                                <li onClick={onLogout}>
                                    <Link to={SYSTEM_PATH.HOME}>
                                        <img src={'/images/i-8.png'} />ログアウト
                                    </Link>
                                </li>
                            }
                        </ul>
                    </nav>
                    <a className='toggle'>
                        <span />
                    </a>
                </div>
                <img className='logo-navbar-img' src={'/images/logo-text.png'} alt='' 
                    onClick={onNavigateHome}
                />
                {
                    token &&
                    <div className='menu-header menu-header-mobile'>
                        <ul>
                            <li className='li-bell'>
                                <a className='click-bell bell' onClick={() => onToggleShowMessage()}>
                                    <i className='fa fa-bell' aria-hidden='true'/>
                                    {totalMessages?.notSeen > 0 && <span className='dot'>{totalMessages?.notSeen ? totalMessages.notSeen > 99 ? '99+' : totalMessages.notSeen : 0}</span>}
                                </a>
                                {
                                    showMessage &&
                                    <div className='content-bell scope active'> 
                                        <div className='content arrow-top arrow'>
                                            <h3 className='title-2'>メッセージ</h3>
                                            <div className='tab'> 
                                                <ul>
                                                    <li className={classNames(typeMessage === 0 && 'active')}> <a className='change-type-message' role={'button'} onClick={() => onChangeTypeMessage(0)}>全て ({totalMessages?.all ?? 0})</a></li>  
                                                    <li className={classNames(typeMessage === 1 && 'active')}> <a className='change-type-message' role={'button'} onClick={() => onChangeTypeMessage(1)}>未読 ({totalMessages?.notSeen ?? 0})</a></li>  
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='nav-content-bell'> 
                                            <div className='scrollbar-message' id='style-2'>
                                                <div className='force-overflow'>
                                                    {
                                                        messages?.length > 0 ?
                                                            messages.map(e => (
                                                                <a onClick={() => onOpenMessageDetail(e.id)} className={'link-message-detail'} key={e.id}>
                                                                    <div role={'button'} className={classNames('item', e.isSeen && 'item-3')}>   
                                                                        <div className='content-message'>
                                                                            {e.senderRole === 1 && <p className='message-ga'>企業管理者より</p>}
                                                                            <h4 className='title-3'> {e.title}</h4>
                                                                            {
                                                                                !e.isNotifyExpireCoursePeriod ?
                                                                                    <p className='desc'>{e.content}</p> :
                                                                                    <p className='desc desc-full'>
                                                                                        <span>受講期間終了まで残り{e.content}となりました。 下記より受講期間の更新が可能となり ますのでお早めにお手続きをお願いいたします。 </span>
                                                                                        <br/>
                                                                                        <span className='text-decoration-underline text-hover-link' onClick={onExtendCoursePeriod}>受講プランの申込み</span> 
                                                                                    </p>
                                                                            }
                                                                            <p className='date'>{e.createdAt && moment(e.sendDate || e.createdAt).format(DATE_TIME_YMD)}</p>
                                                                        </div>
                                                                        {!e.isSeen && <div className='dot' />}
                                                                    </div>
                                                                </a>
                                                            ))
                                                            :
                                                            <div className='text-center'>データはありません</div>
                                                    }
                                                </div> 
                                            </div>
                                            {
                                                messages?.length > 0 && 
                                                <div className='readmore'>
                                                    <Link to={SYSTEM_PATH.MESSAGES}>全表示</Link>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </li>
                        </ul>
                    </div>
                }
            </div>
            <div className='menu-header'>
                <ul>
                    {
                        token && (userInfo?.roleId === USER_TYPE.STUDENT_INDIVIDUAL || userInfo?.roleId === USER_TYPE.STUDENT_GROUP) &&
                        <>
                            <li>
                                <Link to={SYSTEM_PATH.ATTENDANCE_HISTORY} className='item-menu-header'>受講履歴</Link>
                            </li>
                            <li>
                                <Link to={SYSTEM_PATH.THEMES} className='item-menu-header'>講座一覧</Link>
                            </li>
                        </>
                        
                    }
                    {
                        token && userInfo?.roleId === USER_TYPE.GROUP_MANAGER && 
                            <li>
                                <Link to={SYSTEM_PATH.STUDENT_GROUP_LIST} className='item-menu-header'>
                                    団体受講者管理
                                </Link>
                            </li>
                            
                    }
                    {
                        (!token || userInfo?.roleId === USER_TYPE.GROUP_MANAGER) &&
                        <li>
                            <Link to={SYSTEM_PATH.THEMES} className='item-menu-header'>講座一覧</Link>
                        </li>
                    }
                    {
                        !token &&
                        <li>
                            <Link to={SYSTEM_PATH.TICKETS} className='item-menu-header'>受講プラン案内</Link>
                        </li>
                    }
                    <li>
                        <Link to={SYSTEM_PATH.USER_GUIDE} className='item-menu-header'>利用案内</Link>
                    </li>
                    <li>
                        <Link to={SYSTEM_PATH.FAQ_SCREEN} className='item-menu-header'>よくある質問</Link>
                    </li>
                    <li>
                        <Link to={SYSTEM_PATH.SEND_INQUIRY} className='item-menu-header'>お問い合わせ</Link>
                    </li>
                    {
                        token ?
                            <>
                                {
                                    userInfo?.roleId !== USER_TYPE.GROUP_MANAGER &&
                                    <>
                                        {
                                            coursePeriodStatus !== COURSE_PERIOD_STATUS.NOT_HAVE &&
                                            <li className='li-date'>
                                                <a> 
                                                    <span>受講期間 <span className={classNames(isCoursePeriodExpired && 'warning-expired')}>{isCoursePeriodExpired && '(期限切れ)'}</span></span>
                                                    <br/> 
                                                    <span className={classNames(isCoursePeriodExpired && 'warning-expired')}>
                                                        {userInfoHeader?.coursePeriodStart?.replace(/-/g, '/')} - {userInfoHeader?.coursePeriodEnd?.replace(/-/g, '/')}
                                                    </span>
                                                </a>
                                            </li>
                                        }
                                        {
                                            (!isCoursePeriodExpired && userInfoHeader?.ticket) && 
                                            <li className='li-ticket'>
                                                <a> 
                                                    {userInfoHeader?.ticket?.name}受講中
                                                    <br />
                                                    <span>未受講数: {userInfoHeader?.numberTicket ?? 0}講座（単位)</span>
                                                </a>
                                            </li>
                                        }
                                        {
                                            (userInfoHeader?.allowBuyTicket || userInfoHeader?.displayAttendanceCode) &&
                                            <li className='btn-t'>
                                                <a role={'button'} onClick={onAddTicketPlan}>
                                                    {textAddTicket}
                                                    <img src={'/images/icon8.svg'} alt='' />
                                                </a>
                                            </li>
                                        }
                                    </>
                                }
                                <li className='li-bell'>
                                    <a className='click-bell bell' onClick={() => onToggleShowMessage()}>
                                        <img src='/images/icon7.svg' alt='' />
                                        {totalMessages?.notSeen > 0 && <span className='dot'>{totalMessages?.notSeen ? totalMessages.notSeen > 99 ? '99+' : totalMessages.notSeen : 0}</span>}
                                    </a>
                                    {
                                        showMessage &&
                                        <div className='content-bell scope active'> 
                                            <div className='content arrow-top arrow'>
                                                <h3 className='title-2'>メッセージ</h3>
                                                <div className='tab'> 
                                                    <ul>
                                                        <li className={classNames(typeMessage === 0 && 'active')}>  <a className='change-type-message' role={'button'} onClick={() => onChangeTypeMessage(0)}>全て ({totalMessages?.all ?? 0})</a></li>  
                                                        <li className={classNames(typeMessage === 1 && 'active')}>  <a className='change-type-message' role={'button'} onClick={() => onChangeTypeMessage(1)}>未読 ({totalMessages?.notSeen ?? 0})</a></li>  
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='nav-content-bell'> 
                                                <div className='scrollbar-message' id='style-2'>
                                                    <div className='force-overflow'>
                                                        {
                                                            messages?.length > 0 ?
                                                                messages.map(e => (
                                                                    <a key={e.id} onClick={() => onOpenMessageDetail(e.id)} className='link-message-detail'>
                                                                        <div  role={'button'} className={classNames('item', e.isSeen && 'item-3')}>   
                                                                            <div className='content-message'>
                                                                                {e.senderRole === 1 && <p className='message-ga'>企業管理者より</p>}
                                                                                <h4 className='title-3'>{e.title}</h4>
                                                                                {
                                                                                    !e.isNotifyExpireCoursePeriod ?
                                                                                        <p className='desc'>{e.content}</p> :
                                                                                        <p className='desc desc-full'>
                                                                                            <span>受講期間終了まで残り{e.content}となりました。 下記より受講期間の更新が可能となり ますのでお早めにお手続きをお願いいたします。 </span>
                                                                                            <br/>
                                                                                            <span className='text-decoration-underline text-hover-link' onClick={onExtendCoursePeriod}>受講プランの申込み</span> 
                                                                                        </p>
                                                                                }
                                                                                <p className='date'>{e.createdAt && moment(e.sendDate || e.createdAt).format(DATE_TIME_YMD)}</p>
                                                                            </div>
                                                                            {!e.isSeen && <div className='dot' />}
                                                                        </div>
                                                                    </a>
                                                                ))
                                                                :
                                                                <div className='text-center'>データはありません</div>
                                                        }
                                                    </div> 
                                                </div>
                                                {
                                                    messages?.length > 0 && 
                                                    <div className='readmore'>
                                                        <Link to={SYSTEM_PATH.MESSAGES}>全表示</Link>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </li>
                                <li className='li-last'>
                                    <a className='li-infor-user'>
                                        <span className='icon'>
                                            <img src={'/images/icon9.svg'} alt='' />
                                        </span>
                                        <span className='nav-icon'>
                                            <b>{userInfoHeader?.firstName} {userInfoHeader?.lastName}</b>
                                            <br />
                                            アカウント情報
                                            <i className='fa fa-caret-down' aria-hidden='true' />
                                        </span>
                                    </a>
                                    <ul className='sub-login'>
                                        <li role={'button'}>
                                            <Link to={SYSTEM_PATH.PROFILE}>
                                                <img src={'/images/i-1.svg'} alt='' />
                                                ユーザー情報
                                            </Link>
                                        </li>
                                        <li role={'button'}>
                                            <Link to={SYSTEM_PATH.CHANGE_PASSWORD}>
                                                <img src={'/images/i-2.svg'} alt='' />
                                                パスワード変更
                                            </Link>
                                        </li>
                                        {
                                            (userInfo?.roleId === USER_TYPE.STUDENT_INDIVIDUAL || userInfo?.roleId === USER_TYPE.STUDENT_GROUP) && 
                                                <>
                                                    <li role={'button'}>
                                                        <Link to={SYSTEM_PATH.PURCHASE_HISTORY}>
                                                            <img src={'/images/i-3.svg'} alt='' />
                                                            購入履歴
                                                        </Link>
                                                    </li>
                                                    <li role={'button'}>
                                                        <Link to={SYSTEM_PATH.TOOL_CERTIFICATE}>
                                                            <img src={'/images/i-17.svg'} alt='' />
                                                            単位管理ツール
                                                        </Link>
                                                    </li>
                                                </>
                                        }
                                        <li role={'button'} onClick={onLogout}>
                                            <Link to={SYSTEM_PATH.HOME}>
                                                <img src={'/images/i-4.svg'} alt='' />
                                                ログアウト
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </>
                            :
                            <>
                                <li>
                                    {/* <a href className='register-login'>新規登録</a> */}
                                    <Link className='register-login' to={SYSTEM_PATH.REGISTRATION_FIRST_STEP}>新規お申込み</Link>
                                </li>
                                <li>
                                    <Link to={SYSTEM_PATH.LOGIN} className='register-login register-login-2'>ログイン</Link>
                                </li>
                            </>
                    }
                </ul>
            </div>
        </header>
    );
});

export default Header;
