
import { observer } from 'mobx-react';
import yup from '../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import RegistrationLayout from './components/RegistrationLayout';
import { MSG, SYSTEM_PATH, USER_TYPE } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { Link, useNavigate } from 'react-router-dom';

const RegistrationFirstStepScreen = observer(() => {

    const navigate = useNavigate();

    const validateSchema = yup.object().shape({
        termsConditions: yup.bool().required(MSG['error.required']),
        confirmPolicy: yup.bool().required(MSG['error.required']),
        confirmNotes: yup.bool().required(MSG['error.required'])
    });

    const { register, handleSubmit, watch, trigger } = useForm({ resolver: yupResolver(validateSchema), mode: 'onTouched' });
    const watchTermsCondition = watch('termsConditions');
    const watchConfirmPolicy = watch('confirmPolicy');
    const watchConfirmNotes = watch('confirmNotes');
    const isDisableButton = (!watchTermsCondition || !watchConfirmPolicy || !watchConfirmNotes);

    const {
        authStore: { registerCurrentStep, changeStepRegister }
    } = useStore();



    const handleRegister = (data,type) => {
        changeStepRegister(2)
        navigate(`${SYSTEM_PATH.REGISTRATION_SECOND_STEP}?type=${type}&step=1`)
    }

    return (
        <RegistrationLayout>
            <div className="form-account">
                <h3 className="title-2">ご登録前の確認事項</h3>
                <div className="box-form-account">

                    <div className="content-content">
                        <form>
                            <p className="input">
                                <input {...register('termsConditions')} type="checkbox" />
                                <span onClick={() => window.open(SYSTEM_PATH.TERM_OF_SERVICE, '_blank')}>利用規約</span>に同意する
                            </p>
                            <p className="input">
                                <input {...register('confirmPolicy')} type="checkbox" />
                                <span onClick={() => window.open(SYSTEM_PATH.PRIVACY_POLICY, '_blank')}>個人情報保護方針</span>
                                を確認した
                            </p>
                            <p className="input">
                                <input {...register('confirmNotes')}
                                    type="checkbox"
                                />
                                <span onClick={() => window.open(SYSTEM_PATH.CANCELLATION_POLICY, '_blank')}>登録前の注意事項</span>
                               を確認した
                            </p>
                        </form>
                        <p className="red text-start mg-0 messages-register">上記の内容を必ずご確認の上、チェックを入れてください。</p>
                        <div className="link-button">
                            <button type='submit'
                                disabled={isDisableButton}
                                onClick={handleSubmit((d) => handleRegister(d, USER_TYPE.STUDENT_INDIVIDUAL))}
                                className="btn-1">
                                個人で受講される方
                            </button>
                            
                            <button type='submit'
                                disabled={isDisableButton}
                                onClick={handleSubmit((d) => handleRegister(d, USER_TYPE.STUDENT_GROUP))}
                                className="btn-2">
                                受講コードをお持ちの方
                            </button>
                        </div>
                        <div className="red text-start mg-0 mg-t-10 messages-register">
                            ※研修担当者の方で会社としてお申込みご希望の方はこちらより <Link target='_blank' to={SYSTEM_PATH.SEND_INQUIRY}>お問い合わせ</Link> ください。
                        </div>
                    </div>


                </div>
            </div>
        </RegistrationLayout>
    )
})

export default RegistrationFirstStepScreen;