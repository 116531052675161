import ApiService from './ApiService';

class GroupAdminApi extends ApiService {

    constructor() {
        super();
    }

    getStudentsOfCompanyByCourseStatus(payload) {
        return this.get('company', payload);
    }

    getAttendancesOfCompany(payload) {
        return this.get('company-attendance', payload);
    }

    getStudentsOfCompany(payload) {
        return this.get('user', payload);
    }

    getStudentDetail({ id, payload }) {
        return this.get(`user/${id}`, payload);
    }

    getCourseListByStudent({id, payload}) {
        return this.get(`${id}/company/courses`, payload);
    }

    getStudentListByAttendanceCode({ id, payload }) {
        return this.get(`company-attendance/${id}/list`, payload);
    }

    getTicketByGroup(payload) {
        return this.get('ticket-by-group', payload);
    }

    exportStudentCSV(payload) {
        return this.post('export-csv', payload, null, 'blob');
    }

    updateStatusStudent({ id, payload }) {
        return this.put(`status/${id}`, payload);
    }

    getCompanyAttendanceRate(payload) {
        return this.get('company-attendance-rate', payload);
    }

    getDetailCompanyAttendanceRate({id,payload}) {
        return this.post(`company-attendance-rate/${id}`, {'userIds': payload});
    }
}

export default GroupAdminApi;