import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { convertVideoDuration } from '../../../core/utils/common';
import classNames from 'classnames';

function RelatedLectureItem({ item, isOldCourse }) {
    const lectureRes = useMemo(() => {
        const lectureDetail = toJS(item);
        const lastTimeViewed = lectureDetail?.lastTimeViewed || 0;
        const videoLength = lectureDetail?.video_length || 0;
        let name = '未視聴';
        let color = '#6D757D';

        if (lastTimeViewed > 0 && lastTimeViewed < videoLength) {
            name = '視聴中';
            color = '#FFC108'
        }

        if (lastTimeViewed > 0 && lastTimeViewed === videoLength || lectureDetail?.status === 2) {
            name = '視聴済';
            color = '#28A745'
        }


        return { name, color };
    }, [item])

    return (
        <div className="item item-related-lecture">
            <div className="img">
                <a href={`/lecture/${item.id}`}>
                    <img src={item.thumbnail || '/images/img-1.png'} alt="" />
                </a>
            </div>
            <h3 className="title-2">
                <a href={`/lecture/${item.id}`} className={classNames(item?.isNew && 'icon-lecture-new lecture-recommend')}>{item.name}</a>
            </h3>
            { !isOldCourse && <span className='item-course-status' style={{ background: lectureRes.color }}>{lectureRes.name}</span> }
            <div className="clock-video mg-t-10">
                <ul>
                    <li>
                        <img src="/images/icon2.svg" alt="" />
                         約 {convertVideoDuration(item?.video_length)}分
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default observer(RelatedLectureItem);
