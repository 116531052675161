import ApiService from './ApiService';

class GeneralSettingApi extends ApiService {
    constructor(){
        super('general');
    }

    getGeneralSetting(payload) {
        return this.get('',payload);
    }
}

export default GeneralSettingApi;