import classNames from 'classnames';
import { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

const maxLength = 300;

export default function LectureContent({ content = '', title }) {
    const [isShowMore, setIsShowMore] = useState(false);

    const onToggle = () => setIsShowMore((prev) => !prev);

    return (
        <div className="item-view-lecture">
            <h3 className="title-1">{title}</h3>
            {isShowMore ? ReactHtmlParser(content) : ReactHtmlParser(content.substring(0, maxLength) + (content.length > maxLength ? ' ...' : ''))}
            {content.length > maxLength && (
                <div className="read-more">
                    <p onClick={onToggle}>
                        {isShowMore ? '短縮する' : 'もっと見る'}
                        <i className={classNames('fa', isShowMore ? 'fa-caret-up' : 'fa-caret-down')} aria-hidden="true"></i>
                    </p>
                </div>
            )}
        </div>
    );
}
