import ApiService from './ApiService';

class NotifyApi extends ApiService {

    constructor() {
        super('');
    }

    getNotify(payload) {
        return this.get('notifications', payload);
    }

    getNotifyDetail({ id, payload }) {
        return this.get(`notifications/${id}`, payload);
    }
}

export default NotifyApi;