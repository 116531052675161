import { observer } from 'mobx-react';
import { useStore } from '../../core/utils/hook';
import { DATE_YMD, DATE_YMD_ISO, JOIN_ATTENDANCE_CODE_STEPS, MSG, SYSTEM_PATH, TIME_APPLY_COURSE_PERIOD } from '../../core/configs/constants';
import yup from '../../core/utils/yupValidate';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import moment from 'moment';
import CalendarCustom from '../Calendar/CalendarCustom';

const AddTicketPlanModal = observer(props => {

    // props
    const { method: { ticketMethod, attendanceMethod } } = props;

    // store
    const { 
        userStore: { validateAddAttendanceCode, getUserInfoHeader, addAttendanceCode, userInfoHeader },
        modalStore: { hide, show }
    } = useStore();

    // state
    const [ coursePeriodOfAttendanceCode,  setCoursePeriodOfAttendanceCode ] = useState();
    const [ showSelectOptions, setShowSelectOptions ] = useState(false);
    const [ enableSelectCoursePeriod, setEnableSelectCoursePeriod ] = useState(false);

    const validateSchema = yup.object().shape({
        step: yup.number(),
        attendanceCode: yup.string().trim().required(MSG['error.required']),
        timeApplyCoursePeriod: yup.string().nullable().notRequired(),
        coursePeriod: yup.mixed().when('step', {
            is: JOIN_ATTENDANCE_CODE_STEPS.VALIDATE,
            then: yup.string().nullable().notRequired(),
            otherwise: yup.string().required(MSG['error.required'])
        })
    })

    const { 
        register, handleSubmit, setValue,
        formState: { errors, isSubmitting }, watch, reset 
    } = useForm({resolver: yupResolver(validateSchema), mode: 'onChange', defaultValues: {
        step: JOIN_ATTENDANCE_CODE_STEPS.VALIDATE
    }});

    const watchStep = watch('step');
    const watchAttendanceCode = watch('attendanceCode');
    const watchCoursePeriod = watch('coursePeriod');

    const isStepValidate = watchStep === JOIN_ATTENDANCE_CODE_STEPS.VALIDATE;

    // lifecycle
    useEffect(() => {
        if(userInfoHeader && watchStep === JOIN_ATTENDANCE_CODE_STEPS.CONFIRM) {
            if(coursePeriodOfAttendanceCode) {
                if(
                    (
                        (userInfoHeader?.numberTicket > 0 || userInfoHeader?.hasCourseAttending) && 
                        userInfoHeader?.coursePeriodEnd &&
                        coursePeriodOfAttendanceCode > userInfoHeader.coursePeriodEnd
                    ) || (
                        !userInfoHeader?.numberTicket && !userInfoHeader?.hasCourseAttending
                    )
                ) {
                    setValue('coursePeriod', coursePeriodOfAttendanceCode);
                }
            } else {
                if(userInfoHeader?.numberTicket > 0 || userInfoHeader?.hasCourseAttending) {
                    setValue('coursePeriod', moment(userInfoHeader?.coursePeriodEnd ?? moment()).add(1, 'day').format(DATE_YMD_ISO));
                }

                if(!userInfoHeader?.numberTicket && !userInfoHeader?.hasCourseAttending) {
                    setShowSelectOptions(true);
                    setValue('timeApplyCoursePeriod', TIME_APPLY_COURSE_PERIOD.AFTER_THE_PREVIOUS_COURSE_PERIOD_EXPIRE.value.toString());
                    setValue('coursePeriod', moment(userInfoHeader?.coursePeriodEnd ?? moment()).add(1, 'day').format(DATE_YMD_ISO));
                }
            }
        }
    }, [watchStep, userInfoHeader, coursePeriodOfAttendanceCode])

    // function
    const onSubmitValidateAddAttendanceCode = async (data) => {
        const { attendanceCode } = data;
        const res = await validateAddAttendanceCode({ attendanceCode });
        if(res) {
            setCoursePeriodOfAttendanceCode(res?.coursePeriodOfAttendanceCode ?? null);
            getUserInfoHeader();
            reset({
                step: JOIN_ATTENDANCE_CODE_STEPS.CONFIRM,
                attendanceCode
            })
        }
    }

    const onSubmitConfirmAddAttendanceCode = async (data) => {
        const { attendanceCode, coursePeriod } = data;
        const res = await addAttendanceCode({ 
            attendanceCode, 
            coursePeriodApply: coursePeriod, 
            coursePeriodOfAttendanceCodeOrigin: coursePeriodOfAttendanceCode 
        });
        if(res) {
            window.location.href = SYSTEM_PATH.HOME;
        }
    }
    
    const onSubmit = (data) => {
        const { step, ...payload } = data;
        if(step === JOIN_ATTENDANCE_CODE_STEPS.VALIDATE) {
            onSubmitValidateAddAttendanceCode(payload);
        } else {
            onSubmitConfirmAddAttendanceCode(payload);
        }
    }

    const onAddTicketByCredit = () => {
        window.location.href = SYSTEM_PATH.TICKETS;
    }

    const onAddTicketByAttendance = () => {
        hide();
        show({
            id: 'modal-add-ticket-plan',
            isOpen: true,
            header: '受講プランのお申込み',
            onCancel: () => hide(),
            children: (
                <AddTicketPlanModal method={{ ticketMethod: false, attendanceMethod: true }}/>
            ),
            type: 'medium'
        })
    }

    const onChangeTimeApplyCoursePeriod = (e) => {
        const value = Number(e.target.value);
        if(value === TIME_APPLY_COURSE_PERIOD.SELECT_COURSE_PERIOD.value) {
            setValue('coursePeriod', moment().format(DATE_YMD_ISO));
        } else {
            setValue('coursePeriod', moment(userInfoHeader?.coursePeriodEnd ?? moment()).add(1, 'day').format(DATE_YMD_ISO));
        }
    }

    if(ticketMethod) {
        return(
            <div className='add-ticket-plan-modal'>
                <div className='pd-lr-30 mg-tb-20'>
                    <div className='text-secondary fw-bold mg-b-15'>受講プラン購入方法を選択してください。</div>
                    <div className='btn-select-ticket-plans'>
                        <button className='btn-select-ticket-plan btn-select-ticket-plan-1' onClick={onAddTicketByCredit}>クレジットカードで受講プランを購入</button>
                        { attendanceMethod && 
                            <button className='btn-select-ticket-plan btn-select-ticket-plan-2' onClick={onAddTicketByAttendance}>受講コード入力</button> 
                        }
                    </div>
                </div>
            </div>
        )
    }
    
    return(
        <div className='add-attendance-code max-width-600 modal-only-view-content'>
            <form onSubmit={handleSubmit(onSubmit)} className='pd-lr-30 mg-t-20'>
                <label className='w-100'>
                    {
                        isStepValidate ? '所属先の受講コードを入力してください。' : '受講コード'
                    }
                </label>
                <input type={'text'} {...register('attendanceCode')} className='w-100 mg-t-5' disabled={!isStepValidate}/>
                {
                    errors?.attendanceCode &&
                        <div className='text-danger mg-t-5'>{errors.attendanceCode?.message}</div>
                }
                {
                    !isStepValidate && 
                    <>
                        <div className='d-sm-flex align-items-center justify-content-between mg-t-5'>
                            <div role='button' className='d-flex align-items-center flex-gap-5 text-success'>
                                <i className='fa-solid fa-circle-check'/>
                                <span>受講コードが有効です。</span>
                            </div>
                        </div>
                        <hr/>
                        {
                            showSelectOptions && (
                                <div className='mg-b-15'>
                                    <label className='w-100'>
                                受講期間を選択して、受講プランのお申込みを完了してください。
                                    </label>
                                    <select className='w-100 mg-t-5' {...register('timeApplyCoursePeriod', {
                                        onChange: onChangeTimeApplyCoursePeriod
                                    })}>
                                        {
                                            [
                                                TIME_APPLY_COURSE_PERIOD.AFTER_THE_PREVIOUS_COURSE_PERIOD_EXPIRE, 
                                                TIME_APPLY_COURSE_PERIOD.SELECT_COURSE_PERIOD
                                            ].map(timeApply => <option key={timeApply.value} value={timeApply.value}>{timeApply.label}</option>)
                                        }
                                    </select>
                                </div>
                            )
                        }
                        <div>
                            <label className='w-100 field-required'>
                                受講期間
                            </label>
                            <div className='d-flex flex-column flex-sm-row align-items-center justify-content-between mg-t-5'>
                                <CalendarCustom
                                    date={watchCoursePeriod || null}
                                    displayMode={'date'}
                                    onChange={date => {
                                        // setValue('coursePeriod', date ? moment(date).format(DATE_YMD_ISO) : null);
                                    }}
                                    className='calendar-add-attendance-code col-12-max-576 col-5'
                                    // maxDate={(enableSelectCoursePeriod && userInfoHeader?.coursePeriodEnd) ? 
                                    //     new Date(moment(userInfoHeader.coursePeriodEnd).subtract(1, 'day').format(DATE_YMD_ISO)) 
                                    //     : undefined
                                    // }
                                    disabled={!enableSelectCoursePeriod}
                                    removeIconCalendar={false}
                                    iconInput={<></>}
                                />
                                <span>~</span>
                                <input 
                                    className='col-12-max-576 col-5' 
                                    value={watchCoursePeriod ? moment(watchCoursePeriod).add(1, 'year').subtract(1, 'day').format(DATE_YMD) : ''} 
                                    disabled
                                />
                            </div>
                        </div>
                    </>
                }
                <div className='mg-tb-30 text-center footer-button'>
                    <button type='button' className='cancel' onClick={hide}>キャンセル</button>
                    <button 
                        type='submit' 
                        className='submit btn-join-attendance-code-confirm'
                        disabled={isStepValidate && !watchAttendanceCode?.trim()}>
                        { isStepValidate ? '確認' : '申込み' }
                    </button>
                </div>
            </form>
        </div>
    )
})

export default AddTicketPlanModal;