import { observer } from 'mobx-react';
import yup from '../../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    MSG,
    GENDER, GENDER_STRING,
    WORK_FORM,
    WORK_FORM_STRING, PREFECTURES,
    WORK_TYPE, USER_TYPE, SYSTEM_PATH,
    DATE_YMD
} from '../../../core/configs/constants';
import { useForm } from 'react-hook-form';
import CalendarCustom from '../../../components/Calendar/CalendarCustom';
import { handleKeyPressForNumber, handlePasteKataNumberText,
    handleKeyPressKataNumberText, KataNumberReg, handlePasteForNumber } from '../../../core/utils/common'
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
    useNavigate,
    useSearchParams
} from 'react-router-dom';
import { useStore } from '../../../core/utils/hook';
import { reloadEventsDOMContentLoaded } from '../../../core/utils/browser';

const RegistrationInformationSecondStep = observer(() => {
    const [searchParams] = useSearchParams();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isExpiredEmail, setSetIsExpiredEmail] = useState(false);
    let userId = searchParams.get('uuid');
    const navigate = useNavigate();
    const { authStore: { userRegisterInfo, getUserRegisterInfomaiton, registerUser, changeStepRegister },
        modalStore: { openAlert }

    } = useStore();

    const validateSchema = yup.object().shape({
        firstName: yup.string().trim().required(MSG['error.required']).nullable(),
        lastName: yup.string().trim().required(MSG['error.required']).nullable(),
        firstNameFuri: yup.string().trim().required(MSG['error.required']).matches(KataNumberReg, MSG['error.format_hira_kata']).nullable(),
        lastNameFuri: yup.string().trim().required(MSG['error.required']).matches(KataNumberReg, MSG['error.format_hira_kata']).nullable(),
        groupName: yup.string().required(MSG['error.required']).nullable(),
        groupNameFuri: yup.string().trim().required(MSG['error.required']).matches(KataNumberReg, MSG['error.format_hira_kata']).nullable(),
        gender: yup.string().required(MSG['error.required']).nullable(),
        password: yup.string().required(MSG['error.required']).matches(new RegExp('^(?=.*[A-Z])[a-zA-Z0-9]{8,}$'),
            MSG['error.password_format_detail']),
        passwordConfirm: yup.string().oneOf([yup.ref('password')], MSG['error.confirm_password_not_match']).required(MSG['error.required']),
        email: yup.string().required(MSG['error.required']).email(MSG['error.email_format']).nullable(),
        pharmacistNumber: yup.string().required(MSG['error.required']).typeError(MSG['error.number_positive']),
        pharmacistNumberConfirm: yup.string().required(MSG['error.required']).
            oneOf([yup.ref('pharmacistNumber')], MSG['error.student_confirm_pharmacist_not_match_format']).typeError(MSG['error.number_positive']),
        phoneNumber: yup.string().required(MSG['error.required']).typeError(MSG['error.number_positive']),
        workForm: yup.string().required(MSG['error.required']).nullable(),
        zipCode: yup.string().required(MSG['error.required']).typeError(MSG['error.number_positive']),
        prefecture: yup.string().required(MSG['error.required']).nullable(),
        address1: yup.string().required(MSG['error.required']).nullable(),
        address2: yup.string().nullable(),
        workType: yup.string().required(MSG['error.required']).nullable(),
        birthday: yup.string().required(MSG['error.required'])
    })

    const { register, formState: { errors },
        getValues, setValue, handleSubmit, watch, trigger, reset } =
        useForm({ resolver: yupResolver(validateSchema), mode: 'onChange' })
    const watchPharmacistNumber = watch('pharmacistNumber');
    const watchConfirmPharmacistNumberl = watch('pharmacistNumberConfirm');
    const watchPassword = watch('password');
    const watchConfirmPassword = watch('passwordConfirm');

    const onBlurZipCode = (e) => {
        if (e.target.value) {
            let address = document.getElementById('address1').value || '';
            let address2 = document.getElementById('address2').value || '';
            let prefecture = document.getElementById('prefecture').value || '';
            setValue('address1', address, { shouldValidate: true });
            setValue('address2', address2, { shouldValidate: true });
            setValue('prefecture', prefecture, { shouldValidate: true });
        }
    }

    const onSubmit = (data) => {
        data.id = userId;
        data.uuid = userId;
        data.workForm = parseInt(data?.workForm);
        data.gender = parseInt(data?.gender);
        registerUser(data).then(res => {
            if (res?.ok) {
                changeStepRegister(3)
                navigate(SYSTEM_PATH.REGISTRATION_THIRD_STEP)
            }
        })
    }

    // function
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    //lifeCircle
    useEffect(() => {
        if (!userId) return;
        getUserRegisterInfomaiton(userId).then(res => {
            if (res?.ok === false && res?.data?.tokenExpired) {
                setSetIsExpiredEmail(true);
                openAlert(res?.message,()=> {
                    navigate('/')
                });

            }
        });
    }, [userId])

    useEffect(() => {
        reset({
            groupName: userRegisterInfo?.groupName,
            email: userRegisterInfo?.email,
            groupNameFuri: userRegisterInfo?.groupNameFuri,

        });
    }, [userRegisterInfo])

    useEffect(() => {
        reloadEventsDOMContentLoaded();
    }, [])

    if (isExpiredEmail == true) {
        return <div></div>
    }
    return (
        <div className="form-account">
            <h3 className="title-2">情報登録</h3>
            <div className="note-cs-3 text-14 mg-t-20">
                <span className='text-12'>※ </span> 姓名、薬剤師名簿登録番号は、日本薬剤師研修センターへ受講者情報として提出いたしますので、薬剤師名簿に登録されている内容を正確にご入力ください。</div>
            <form onSubmit={handleSubmit(onSubmit)} className="h-adr">
                <div className="box-form-account mg-t-15">
                    <div className="content-content">
                        <div className="item">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="item-left">
                                        <label for="">姓: <span>*</span></label>
                                    </div>
                                    <div className="item-right">
                                        <input type="text" {...register('firstName')} />
                                        {errors?.firstName?.message && <p className='text-error'>{errors?.firstName?.message}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="item-left">
                                        <label for="">名: <span>*</span></label>
                                    </div>
                                    <div className="item-right">
                                        <input type="text" {...register('lastName')} />
                                        {errors?.lastName?.message && <p className='text-error'>{errors?.lastName?.message}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="item-left">
                                        <label for="">姓(フリガナ): <span>*</span></label>
                                    </div>
                                    <div className="item-right">
                                        <input type="text" {...register('firstNameFuri')} 
                                            onKeyPress={handleKeyPressKataNumberText}
                                            onPaste={handlePasteKataNumberText}
                                        />
                                        {errors?.firstNameFuri?.message && <p className='text-error'>{errors?.firstNameFuri?.message}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-6">
                                    <div className="item-left">
                                        <label for="">名(フリガナ): <span>*</span></label>
                                    </div>
                                    <div className="item-right">
                                        <input type="text" {...register('lastNameFuri')} 
                                            onKeyPress={handleKeyPressKataNumberText}
                                            onPaste={handlePasteKataNumberText}
                                        />
                                        {errors?.lastNameFuri?.message && <p className='text-error'>{errors?.lastNameFuri?.message}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item ">
                            <div className="item-left">
                                <label for="">所属会社名: <span>*</span></label>
                            </div>
                            <div className="item-right">
                                <input type="text"  {...register('groupName')} disabled={userRegisterInfo?.userType == USER_TYPE.STUDENT_GROUP} />
                                {errors?.groupName?.message && <p className='text-error'>{errors?.groupName?.message}</p>}
                            </div>
                        </div>
                        <div className="item ">
                            <div className="item-left">
                                <label for="">所属会社名 (フリガナ): <span>*</span></label>
                            </div>
                            <div className="item-right">
                                <input type="text" 
                                    {...register('groupNameFuri')}
                                    onKeyPress={handleKeyPressKataNumberText}
                                    onPaste={handlePasteKataNumberText}
                                    disabled={userRegisterInfo?.userType == USER_TYPE.STUDENT_GROUP} />
                                {errors?.groupNameFuri?.message && <p className='text-error'>{errors?.groupNameFuri?.message}</p>}
                            </div>
                        </div>
                        <div className="item ">
                            <div className="item-left">
                                <label for="">性別: <span>*</span></label>
                            </div>
                            <div className="item-right">
                                <select id="gender" {...register('gender')}>
                                    <option value="">選択して下さい</option>
                                    {GENDER && Object.keys(GENDER).map((key) =>
                                        <option key={key.toString()} value={GENDER[key]}>{GENDER_STRING[key]}</option>)
                                    }
                                </select>
                                {errors?.gender?.message && <p className='text-error'>{errors?.gender?.message}</p>}
                            </div>
                        </div>
                        <div className="item">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-12 bod-view">
                                    <div className="item-left">
                                        <label for="">生年月日: <span>*</span></label>
                                    </div>
                                    <div className="item-right item-bod">
                                        <CalendarCustom
                                            date={getValues('birthday') || null}
                                            displayMode={'date'}
                                            maxDate={new Date()}
                                            onChange={(date) => {
                                                setValue('birthday', date ? moment(date).format(DATE_YMD) : '', { shouldValidate: true });
                                            }}
                                        />
                                        {errors?.birthday?.message && <p className='text-error'>{errors?.birthday?.message}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item item-11">
                            <div className="item-left">
                                <label for="">メールアドレス: <span>*</span></label>
                            </div>
                            <div className="item-right">
                                <input type="text" {...register('email')} disabled />
                                {errors?.email?.message && <p className='text-error'>{errors?.email?.message}</p>}
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-left">
                                <label for="">ログインパスワード <span>*</span></label>
                            </div>
                            <div className="item-right">
                                <div className="item-2">
                                    <div className="input">
                                        <input type={!showPassword ? 'password' : 'text'} {...register('password')}
                                            onChange={(e) => {
                                                setValue('password', e.target.value, { shouldValidate: true })
                                                if (watchConfirmPassword) {
                                                    trigger('passwordConfirm', { shouldValidate: true })
                                                }

                                            }}
                                        />
                                        <button type='button' onClick={toggleShowPassword}>
                                            {!showPassword ?
                                                <i className='fa fa-eye-slash'></i>
                                                :
                                                <i className='fa fa-eye'></i>
                                            }
                                        </button>
                                    </div>
                                    <div className="icon">
                                        {
                                            watchPassword &&
                                            <>
                                                {
                                                    !errors.password ?
                                                        <img src="/images/icon11.svg" alt="" />
                                                        :
                                                        <img src="/images/clear.svg" alt="" />
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                {errors?.password?.message && <p className='text-error'>{errors?.password?.message}</p>}

                            </div>
                        </div>
                        <div className="item">
                            <div className="item-left">
                                <label for="">ログインパスワード確認<span>*</span></label>
                            </div>
                            <div className="item-right">
                                <div className="item-2">
                                    <div className="input">
                                        <input type={!showConfirmPassword ? 'password' : 'text'} {...register('passwordConfirm')}
                                            onPaste={(e) => e.preventDefault()} />
                                        <button type='button' onClick={toggleShowConfirmPassword}>
                                            {!showConfirmPassword ?
                                                <i className='fa fa-eye-slash'></i>
                                                :
                                                <i className='fa fa-eye'></i>
                                            }
                                        </button>
                                    </div>
                                    <div className="icon">
                                        {
                                            watchConfirmPassword &&
                                            <>
                                                {
                                                    !errors.passwordConfirm ?
                                                        <img src="/images/icon11.svg" alt="" />
                                                        :
                                                        <img src="/images/clear.svg" alt="" />
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                {errors?.passwordConfirm?.message && <p className='text-error'>{errors?.passwordConfirm?.message}</p>}

                            </div>
                        </div>
                        <div className="item">
                            <div className="item-left">
                                <label for="">薬剤師名簿登録番号<span>*</span></label>
                            </div>
                            <div className="item-right">
                                <div className="item-2">
                                    <div className="input">
                                        <input type="text" {...register('pharmacistNumber')}
                                            onChange={(e) => {
                                                setValue('pharmacistNumber', e.target.value, { shouldValidate: true })
                                                if (watchConfirmPharmacistNumberl) {
                                                    trigger('pharmacistNumberConfirm', { shouldValidate: true })
                                                }
                                            }}
                                        />
                                        {/* <button><img src="/images/icon-eye.png" alt="" /></button> */}
                                    </div>
                                    <div className="icon">
                                        {
                                            watchPharmacistNumber &&
                                            <>
                                                {
                                                    !errors.pharmacistNumber ?
                                                        <img src="/images/icon11.svg" alt="" />
                                                        :
                                                        <img src="/images/clear.svg" alt="" />
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                {errors?.pharmacistNumber?.message && <p className='text-error'>{errors?.pharmacistNumber?.message}</p>}
                                <p className="note-3 text-14" style={{display:'flex', alignItems:'center'}}>
                                    <span className='text-12'>※ </span>半角数字で入力してください。再度、薬剤師名簿登録番号に間違いがないかご確認ください。</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-left">
                                <label for="">薬剤師名簿登録番号確認<span>*</span></label>
                            </div>
                            <div className="item-right">
                                <div className="item-2">
                                    <div className="input">
                                        <input type="text" {...register('pharmacistNumberConfirm')} onPaste={(e) => e.preventDefault()} />
                                    </div>
                                    <div className="icon">
                                        {
                                            watchConfirmPharmacistNumberl &&
                                            <>
                                                {
                                                    !errors.pharmacistNumberConfirm ?
                                                        <img src="/images/icon11.svg" alt="" />
                                                        :
                                                        <img src="/images/clear.svg" alt="" />
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                {errors?.pharmacistNumberConfirm?.message && <p className='text-error'>{errors?.pharmacistNumberConfirm?.message}</p>}
                            </div>
                        </div>
                        <div className="item ">
                            <div className="item-left">
                                <label for="">沖/外選択 <span>*</span></label>
                            </div>
                            <div className="item-right">
                                <select {...register('workForm')}>
                                    {WORK_FORM && Object.keys(WORK_FORM).map((key) =>
                                        <option key={key.toString()} value={WORK_FORM[key].toString()}>{WORK_FORM_STRING[key]}</option>)
                                    }
                                </select>
                                {errors?.workForm?.message && <p className='text-error'>{errors?.workForm?.message}</p>}
                                <p className="note-3 text-14" style={{display:'flex', alignItems:'center'}}>
                                    <span className='text-12'>※ </span> 沖／外で始まる名簿登録番号の方は、こちらにて選択してください。</p>
                            </div>
                        </div>
                        <div className="item ">
                            <div className="item-left">
                                <label for="">電話番号 <span>*</span></label>
                            </div>
                            <div className="item-right">
                                <input type="text" {...register('phoneNumber')}
                                    onKeyPress={handleKeyPressForNumber}
                                    onPaste={handlePasteForNumber} />
                                {errors?.phoneNumber?.message && <p className='text-error'>{errors?.phoneNumber?.message}</p>}
                            </div>
                        </div>
                        <div className="item ">
                            <div className="item-left">
                                <span className="p-country-name" style={{ display: 'none' }}>Japan</span>
                                <label for="">郵便番号 <span>*</span></label>
                            </div>
                            <div className="item-right">

                                <input type="text" className='p-postal-code' {...register('zipCode')}
                                    onKeyPress={handleKeyPressForNumber}
                                    onPaste={handlePasteForNumber}
                                    onBlur={onBlurZipCode} />
                                <p className="note-3 text-14" style={{ marginTop:5}}>ハイフンなしで入力ください。</p>
                                {errors?.zipCode?.message && <p className='text-error'>{errors?.zipCode?.message}</p>}
                            </div>
                        </div>
                        <div className="item ">
                            <div className="item-left">
                                <label for="">都道府県 <span>*</span></label>
                            </div>
                            <div className="item-right">
                                <select id="prefecture" {...register('prefecture')} className="p-region">
                                    <option value="">選択して下さい</option>
                                    {PREFECTURES && PREFECTURES.map((item, idx) => <option key={idx.toString()} value={item}>{item}</option>)}
                                </select>
                                {errors?.prefecture?.message && <p className='text-error'>{errors?.prefecture?.message}</p>}
                            </div>
                        </div>
                        <div className="item ">
                            <div className="item-left">
                                <label for="">住所 <span>*</span></label>
                            </div>
                            <div className="item-right">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-6">
                                        <input type="text" id='address1' {...register('address1')} className="p-locality p-street-address" />
                                        {errors?.address1?.message && <p className='text-error'>{errors?.address1?.message}</p>}
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-6">
                                        <input type="text" {...register('address2')} id='address2' className="p-extended-address" />
                                        {errors?.address2?.message && <p className='text-error'>{errors?.address2?.message}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item ">
                            <div className="item-left">
                                <label for="">勤務業態<span>*</span></label>
                            </div>
                            <div className="item-right">
                                <select {...register('workType')}>
                                    <option value="">選択して下さい</option>
                                    {WORK_TYPE && Object.keys(WORK_TYPE).map((key) =>
                                        <option key={key} value={WORK_TYPE[key]}>{WORK_TYPE[key]}</option>)}
                                </select>
                                {errors?.workType?.message && <p className='text-error'>{errors?.workType?.message}</p>}
                            </div>
                        </div>
                        {/* <div className="item ">
                            <div className="item-left">
                                <label for="">取得を希望する薬剤師認定制度<span>*</span></label>
                            </div>
                            <div className="item-right">
                                <div className="list-check">
                                    <ul>
                                        <li>
                                            <input type="checkbox" {...register('pharmacistCertification')} value="0" />研修認定薬剤師
                                        </li>
                                        <li>
                                            <input type="checkbox" {...register('pharmacistCertification')} value="1" />精神薬学会認定薬剤師
                                        </li>
                                        <li>
                                            <input type="checkbox" {...register('pharmacistCertification')} value="2" />漢方薬・生薬認定薬剤師（更新のみ対象）
                                        </li>
                                        <li>
                                            <input type="checkbox" {...register('pharmacistCertification')} value="3" />小児薬物療法認定薬剤師（更新のみ対象）
                                        </li>
                                    </ul>
                                </div>
                                {errors?.pharmacistCertification?.message && <p className='text-error'>
                                    {errors?.pharmacistCertification?.message}</p>}
                                <p className="note-3 text-14"  style={{display:'flex', alignItems:'center'}}>
                                    <span className='text-12'>※ </span>精神薬学会認定薬剤師は日本精神薬学会会員様のみ学会認定の単位対象となります</p>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="prev-next">
                    <div className="next">
                        <button type='submit'>登録完了<span><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></button>
                    </div>
                </div>
            </form>
        </div>
    )
})

export default RegistrationInformationSecondStep;