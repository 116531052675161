import classNames from 'classnames';
import TreeView, { flattenTree } from 'react-accessible-treeview';
import './style.scss';

const TreeViewCustom = (props => {

    const { 
        className, 
        elementClass,
        data,
        onSelect, 
        onNodeSelect,
        onExpand,
        onHandLeExpand,
        onClickTreeName,
        multiSelect = false,
        propagateSelect = false,
        propagateSelectUpwards = false,
        propagateCollapse = true,
        expandOnKeyboardSelect = false,
        togglableSelect = false,
        defaultSelectedIds = [],
        defaultExpandedIds = [],
        selectedIds = [],
        expandedIds = [],
        clickAction = 'SELECT',
        onBlur
    } = props;
    
    return (
        <TreeView
            className={classNames('tree-view-custom', className)}
            data={flattenTree(data)}
            onSelect={onSelect}
            onNodeSelect={onNodeSelect}
            onExpand={onExpand}
            multiSelect={multiSelect}
            propagateSelect={propagateSelect}
            propagateSelectUpwards={propagateSelectUpwards}
            propagateCollapse={propagateCollapse}
            expandOnKeyboardSelect={expandOnKeyboardSelect}
            togglableSelect={togglableSelect}
            defaultSelectedIds={defaultSelectedIds}
            defaultExpandedIds={defaultExpandedIds}
            selectedIds={selectedIds}
            expandedIds={expandedIds}
            clickAction={clickAction}
            onBlur={onBlur}
            nodeRenderer={({
                element,
                isBranch,
                isExpanded,
                isDisabled,
                getNodeProps,
                level,
                handleExpand
            }) => {
                const nodeProps = getNodeProps({ onClick: () => {} });
                return (
                    <div
                        {...nodeProps}
                        style={{
                            paddingLeft: level === 1 ? 10 : 10 + 25 * (level - 1),
                            opacity: isDisabled ? 0.5 : 1
                        }}
                        className={classNames(nodeProps.className, element.metadata.className ?? '', isBranch && 'd-flex')}
                    >   
                        {isBranch && <ArrowIcon isOpen={isExpanded} onHandleExpand={() => onHandLeExpand ? onHandLeExpand(element) : handleExpand}/>}
                        <span 
                            className={classNames('tree-name', 
                                element?.children?.length > 0 && 'fw-bold', 
                                element?.metadata?.hasClick && 'has-click', elementClass && elementClass(element))} 
                            onClick={() => onClickTreeName(element)}>
                            {element.name}
                        </span>
                    </div>
                );
            }}
        />
    )
})

const ArrowIcon = ({ isOpen, className, onHandleExpand }) => {
    return <i className={classNames(
        'tree-icon pd-r-10',
        isOpen ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down',
        className
    )} style={{'color': '#1A2C44' }} onClick={onHandleExpand}/>;
};

export default TreeViewCustom;