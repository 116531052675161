import { observer } from 'mobx-react';
import ProfileSideBar from './components/ProfileSideBar';
import { MutilCalendarCustom, ReactNotifications, TooltipCustom } from '../../components';
// eslint-disable-next-line max-len
import { DATE_YMD, DATE_YMD_ISO, DATE_YMD_JP, MSG, SETTING_TOOL_CERTIFICATE_STATUS, SETTING_TOOL_CERTIFICATE_STATUS_MAPPER, SETTING_TOOL_CERTIFICATE_TYPE } from '../../core/configs/constants';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useStore } from '../../core/utils/hook';
import classNames from 'classnames';
import { handleKeyPressForNumber, handlePasteForNumber } from '../../core/utils/common';

const ToolCertificateScreen = observer(() =>  {

    // store
    const { 
        userStore: { toolCertificate, getToolCertificate, getSettingToolCertificate, setAttrObservable, saveToolCertificate, resetToolCertificate } 
    } = useStore();

    // state
    const [ typeSettingTool, setTypeSettingTool ] = useState();
    const [ startDate, setStartDate ] = useState();
    const [ disabledSearch, setDisabledSearch ] = useState(false);
    const endDate = useMemo(() => {
        if(startDate) {
            switch (Number(typeSettingTool)) {
            case SETTING_TOOL_CERTIFICATE_TYPE.NEW:
                return calculateDateAfterYear(startDate, 4);
            case SETTING_TOOL_CERTIFICATE_TYPE.UPDATE:
                return calculateDateAfterYear(startDate, 3);
            default:
                break;
            }
        } 
        return ''; 
    }, [startDate, typeSettingTool])

    const { register, formState: { errors, isSubmitting }, setValue, watch } = useForm({ mode: 'onChange' });

    const watchDataSettingTool = watch('dataSettingTool');

    // lifecycle
    useEffect(() => {
        getToolCertificate();

        return () => {
            setAttrObservable('toolCertificate', null);
        }
    }, [])

    useEffect(() => {
        if(toolCertificate) {
            const { type, startDate, value } = toolCertificate;
            
            setTypeSettingTool(String(type));
            setStartDate(startDate);
            if(!disabledSearch) {
                setValue('dataSettingTool', value, { shouldValidate: true });
                setDisabledSearch(true);
            } else {
                let data;
                const formatValue = formatDataSettingTool(value);
                if(type == SETTING_TOOL_CERTIFICATE_TYPE.NEW) {
                    data = verifyDataSettingToolNew(formatValue);
                } else {
                    data = verifyDataSettingToolUpdate(formatValue);
                }

                setValue('dataSettingTool', data, { shouldValidate: true });
            }
        } else {
            setValue('dataSettingTool', null, { shouldValidate: true });
        }
    }, [toolCertificate])

    // function
    const onChangeTypeSettingTool = (e) => {
        setTypeSettingTool(e.target.value);
    }

    const onSubmitGetSettingTool = () => {
        if(!disabledSearch) {
            getSettingToolCertificate({ type: typeSettingTool, startDate });
            setDisabledSearch(true);
        }
    }

    const verifyDataSettingToolNew = (data, startIndex = 0) => {
        if(!data?.length) return data;

        const newData = JSON.parse(JSON.stringify(data));

        for (let index = startIndex; index < newData.length; index++) {
            const element = newData[index];
            
            element.totalCourseCompletion = Number((element.courseCompletion || 0)) + Number((element.othersCourseCompletion || 0));
            if(element.totalCourseCompletion < 5) {
                element.status = SETTING_TOOL_CERTIFICATE_STATUS.NOT_COMPLETE;
            } else {
                let totalCourseCompletionBeforeElement = 0;
                if(index > 0) {
                    totalCourseCompletionBeforeElement = newData.slice(0, index).reduce((prev, cur) => prev + cur.totalCourseCompletion, 0);
                }
                if(element.totalCourseCompletion + totalCourseCompletionBeforeElement < 40) {
                    element.status = SETTING_TOOL_CERTIFICATE_STATUS.WARNING_COMPLETE;
                } else {
                    element.status = SETTING_TOOL_CERTIFICATE_STATUS.COMPLETED;
                }
            }
        }

        return newData;
    }

    const verifyDataSettingToolUpdate = (data, startIndex = 0) => {
        if(!data?.length) return data;

        const newData = JSON.parse(JSON.stringify(data));

        for (let index = startIndex; index < newData.length; index++) {
            const element = newData[index];
            
            element.totalCourseCompletion = Number((element.courseCompletion || 0)) + Number((element.othersCourseCompletion || 0));
            if(element.totalCourseCompletion < 5) {
                element.status = SETTING_TOOL_CERTIFICATE_STATUS.NOT_COMPLETE;
            } else {
                if(index !== newData.length - 1) {
                    element.status = SETTING_TOOL_CERTIFICATE_STATUS.WARNING_COMPLETE;
                } else {
                    let totalCourseCompletionBeforeElement = 0;
                    if(index > 0) {
                        totalCourseCompletionBeforeElement = newData.slice(0, index).reduce((prev, cur) => prev + cur.totalCourseCompletion, 0);
                    }
                    if(element.totalCourseCompletion + totalCourseCompletionBeforeElement < 30) {
                        element.status = SETTING_TOOL_CERTIFICATE_STATUS.NOT_COMPLETE;
                    } else {
                        element.status = SETTING_TOOL_CERTIFICATE_STATUS.COMPLETED;
                    }
                }
            }
        }

        return newData;
    }

    const updateStatusDataSettingTool = (startIndex = 0) => {
        let data;
        if(typeSettingTool == SETTING_TOOL_CERTIFICATE_TYPE.NEW) {
            data = verifyDataSettingToolNew(watchDataSettingTool, startIndex);
        } else {
            data = verifyDataSettingToolUpdate(watchDataSettingTool, startIndex);
        }

        if(data?.length === watchDataSettingTool.length) {
            for (let index = startIndex; index < watchDataSettingTool.length; index++) {
                const originData = watchDataSettingTool[index];
                const newData = data[index];
                if(originData.status !== newData.status) {
                    setValue(`dataSettingTool.${index}.status`, newData.status);
                }
            }
        }
    }

    const onChangeOthersCourseComplete = (value, index) => {
        if(value > 99) {
            value = 99;
            setValue(`dataSettingTool.${index}.othersCourseCompletion`, value);
        }
        const totalCourseCompletion = Number(watchDataSettingTool[index].courseCompletion) + Number(value || 0);
        setValue(`dataSettingTool.${index}.totalCourseCompletion`, totalCourseCompletion);
        updateStatusDataSettingTool(index);
    }

    function calculateDateAfterYear (date, years = 1, formatDate = DATE_YMD_JP) {
        if(!date) return '';
        return moment(date).add(years, 'years').subtract(1, 'days').format(formatDate);
    }

    const formatDataSettingTool = (data) => {
        if(!data?.length) return data;

        const newData = JSON.parse(JSON.stringify(data));
        const result = [newData[0]];
        for (let index = 1; index < newData.length; index++) {
            const beforeElement = newData[index - 1];
            const element = newData[index];
            
            if(
                !isTargetPeriodContainsCurrentDate(element.startDate) || 
                (
                    typeSettingTool == SETTING_TOOL_CERTIFICATE_TYPE.NEW && 
                    (beforeElement.status == SETTING_TOOL_CERTIFICATE_STATUS.NOT_COMPLETE || 
                    beforeElement.status === SETTING_TOOL_CERTIFICATE_STATUS.COMPLETED)
                ) ||
                (
                    typeSettingTool == SETTING_TOOL_CERTIFICATE_TYPE.UPDATE &&
                    beforeElement.status == SETTING_TOOL_CERTIFICATE_STATUS.NOT_COMPLETE
                ) || beforeElement.disabled
            ) {
                element.disabled = true;
                element.othersCourseCompletion = '';
                element.totalCourseCompletion = element.courseCompletion;
            } else {
                element.disabled = false;
            }
            result.push(element);
        }

        return result;
    }

    const onSaveToolCertificate = async () => {
        if(watchDataSettingTool?.length) {
            const value = formatDataSettingTool(watchDataSettingTool);

            const res = await saveToolCertificate({
                type: Number(typeSettingTool),
                startDate,
                value
            });

            if(res) {
                getToolCertificate();
                ReactNotifications('success', MSG['tool.certificate.update.success']);
            }
        }
    }

    const onResetToolCertificate = async () => {
        if(toolCertificate) {
            const res = await resetToolCertificate();

            if(res) {
                setTypeSettingTool('');
                setStartDate(null);
                setDisabledSearch(false);
                getToolCertificate();
            }
        }
    }

    const isTargetPeriodContainsCurrentDate = (startDate) => {
        const endDate = moment(startDate).add(1, 'year').subtract(1, 'day');
        const currentDate = moment();
        if ( (moment(currentDate).isSameOrAfter(moment(startDate), 'date') && moment(currentDate).isSameOrBefore(endDate, 'date')) 
            || moment(currentDate).isAfter(endDate, 'date')) {
            return true;
        }
        return false;
    }

    return (
        <div id='main' className='wrapper main-student-list main-Attended-couse main-purchasse-history tool-certificate-screen'>
            <div className='bres-cus'>
                <div className='container'>
                    <ul>
                        <li>
                            <a href='/'>
                                <img src='/images/icon-home.svg' alt='' />
                            </a>
                        </li>
                        <li className='dash'>\</li>
                        <li>購入履歴</li>
                    </ul>
                </div>
            </div>
            <section className='top-student-list'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-2 col-lg-2 col-12 sidebar-tool-certificate'>
                            <ProfileSideBar />
                        </div>
                        <div className='col-md-10 col-lg-10 col-12 container-tool-certificate'>
                            <div className='search-tool-certificate content-top-student pd-tb-25'>
                                <div className='row'>
                                    <div className='col-12 d-flex align-items-center'>
                                        <div className='d-flex align-items-center'>
                                            <input type='radio' id='newRecord'
                                                name='typeSettingTool' 
                                                value={SETTING_TOOL_CERTIFICATE_TYPE.NEW} 
                                                onChange={onChangeTypeSettingTool}
                                                checked={typeSettingTool == SETTING_TOOL_CERTIFICATE_TYPE.NEW}
                                                disabled={disabledSearch}/>
                                            <label className='font-bold' role='button' htmlFor='newRecord'>新規</label>
                                        </div>
                                        <div className='d-flex align-items-center mg-l-50'>
                                            <input type='radio' id='updateRecord' 
                                                name='typeSettingTool' 
                                                value={SETTING_TOOL_CERTIFICATE_TYPE.UPDATE} 
                                                onChange={onChangeTypeSettingTool}
                                                checked={typeSettingTool == SETTING_TOOL_CERTIFICATE_TYPE.UPDATE}
                                                disabled={disabledSearch}/>
                                            <label className='font-bold' role='button' htmlFor='updateRecord'>更新</label>
                                        </div>
                                    </div>
                                    <div className='col-12 mg-t-25'>
                                        <label className='col-12 font-bold'>研修単位取得期間</label>
                                        <div className='mg-t-10 d-flex justify-content-between align-items-center flex-wrap'>
                                            <div className='col-md-9 col-12 d-flex justify-content-between align-items-center flex-wrap'>
                                                <MutilCalendarCustom
                                                    className={'col-sm-5 col-12'}
                                                    date={startDate || null}
                                                    onChange={date => setStartDate(date ? moment(date).format(DATE_YMD_ISO) : null)}
                                                    dateDisplayFormat={DATE_YMD_JP}
                                                    disabled={!typeSettingTool || disabledSearch}
                                                    minDate={new Date(typeSettingTool == SETTING_TOOL_CERTIFICATE_TYPE.NEW ? 
                                                        moment().add(1, 'day').subtract(4, 'year').format(DATE_YMD) :
                                                        moment().add(1, 'day').subtract(3, 'year').format(DATE_YMD)
                                                    )}
                                                    maxDate={new Date()}
                                                />
                                                <div>~</div>
                                                <input className='calendar-input col-sm-5 col-12' value={endDate} disabled readOnly/>
                                            </div>
                                            <input 
                                                type='submit' 
                                                className='submit-search' 
                                                value='設定' onClick={onSubmitGetSettingTool} 
                                                disabled={disabledSearch || !typeSettingTool || !startDate}/>
                                        </div>
                                        <div className='text-error font-bold mg-t-10 text-14'>※研修単位取得期間の開始日をカレンダーから選択してください。終了日は自動的に表示されます。</div>
                                    </div>
                                </div>
                            </div>
                            <div className='content-tool-certificate content-student-list'>
                                {
                                    watchDataSettingTool?.length > 0 &&
                                        <>
                                            <div className='table-table'>
                                                <div className='tool-certificate-warning'>
                                                    {
                                                        typeSettingTool == SETTING_TOOL_CERTIFICATE_TYPE.NEW ? 
                                                            '毎年最低5単位。4年で40単位が必要' : 
                                                            '毎年最低5単位。3年で30単位が必要'
                                                    }
                                                </div>
                                                <table className={classNames('border-bottom-0', 
                                                    typeSettingTool == SETTING_TOOL_CERTIFICATE_TYPE.NEW ? 'table-tool-certificate-new' 
                                                        : 'table-tool-certificate-update')}>
                                                    <tr>
                                                        { watchDataSettingTool.map((_, index) => <td key={index}>{index + 1}年目</td>) }
                                                    </tr>
                                                    <tr>
                                                        {
                                                            watchDataSettingTool.map((item, index) => (
                                                                <td key={index} className='bg-white'>
                                                                    {
                                                                        item?.startDate ? 
                                                                        // eslint-disable-next-line max-len
                                                                            `${moment(item.startDate).format(DATE_YMD)} ~ ${calculateDateAfterYear(item.startDate, 1, DATE_YMD)}` 
                                                                            : ''
                                                                    }
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                    <tr>
                                                        {
                                                            watchDataSettingTool.map((item, index) => (
                                                                <td key={index} className='bg-white'>
                                                                    { item?.disabled ? '-' : (item?.courseCompletion || 0) }
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                    <tr className='border-0'>
                                                        <td className='text-start border-0 pd-l-0 bg-white' 
                                                            colSpan={watchDataSettingTool.length}>
                                                            <div className='font-bold'>＜elephamt以外で取得した単位数を入力してください。&gt;</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {
                                                            watchDataSettingTool.map((item, index) => (
                                                                <td key={index} className='bg-white'>
                                                                    {
                                                                        item?.disabled ?
                                                                            <input value={'-'} disabled readOnly 
                                                                                className='w-100 tool-certificate-input-course text-center pd-0'/> :
                                                                            <input {...register(`dataSettingTool.${index}.othersCourseCompletion`, {
                                                                                onChange: e => onChangeOthersCourseComplete(e.target.value, index)
                                                                            })}
                                                                            onKeyPress={handleKeyPressForNumber}
                                                                            onPaste={handlePasteForNumber}
                                                                            type='number'
                                                                            max={100}
                                                                            min={0}
                                                                            className='w-100 tool-certificate-input-course text-center pd-0'
                                                                            />
                                                                    }
                                                                    
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                    <tr className='border-0'>
                                                        <td className='text-start border-0 pd-l-0 bg-white' 
                                                            colSpan={watchDataSettingTool.length}>
                                                            <div className='font-bold'>合計</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {
                                                            watchDataSettingTool.map((item, index) => (
                                                                <td key={index} className='bg-white'>
                                                                    <div className='font-bold'>
                                                                        { item?.disabled ? '-' : (item?.totalCourseCompletion || 0) }
                                                                    </div>
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                    <tr>
                                                        {
                                                            watchDataSettingTool.map((item, index) => (
                                                                <td key={index} className='bg-white border-0'>
                                                                    { 
                                                                        SETTING_TOOL_CERTIFICATE_STATUS_MAPPER[item?.status] && 
                                                                        !item?.disabled &&
                                                                        // <img src={SETTING_TOOL_CERTIFICATE_STATUS_MAPPER[item.status].IMG}
                                                                        //     width={20}
                                                                        // /> 
                                                                        // eslint-disable-next-line max-len
                                                                        <span className='fw-bolder' style={{'color': SETTING_TOOL_CERTIFICATE_STATUS_MAPPER[item.status].TEXT_COLOR}}>
                                                                            {SETTING_TOOL_CERTIFICATE_STATUS_MAPPER[item.status].LABEL}
                                                                        </span>
                                                                    }
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                </table>
                                            </div>

                                            <div className='text-error font-bold mg-t-10 text-14'>
                                                <div>&lt;英字表示についての説明&gt;</div>
                                                <div>英字表示は本サイトおよびご自身で入力された単位数をもとに計算</div>
                                                <div>した<u>目安となりますので予めご了解ください。</u></div>
                                                <div>正確な情報は日本薬剤師研修センターのPECSにてご確認いただけます</div>
                                                <div>ようお願いいたします。</div>
                                                <div>A：新規の場合は合計40単位以上、更新の場合は3年目で 30単位以上</div>
                                                <div>となった時点で表示。</div>
                                                <div>B：新規、更新ともに5単位以上となった時点で表示。</div>
                                                <div>C：新規、更新ともに5単位未満の時点で表示。</div>
                                            </div>

                                            <div className='tool-certificate-button d-flex 
                                                justify-content-center align-items-center flex-gap-40 flex-wrap mg-t-75 mg-b-30'>
                                                <TooltipCustom 
                                                    title={
                                                        <div>
                                                            <div>情報が全てリセットされますが、</div>
                                                            <div>よろしいですか？</div>
                                                        </div>
                                                    }
                                                >
                                                    <button type='button' className='btn-1' onClick={onResetToolCertificate}>
                                                            情報リセット
                                                    </button>
                                                </TooltipCustom>
                                                <TooltipCustom
                                                    title={
                                                        <div>
                                                            <div>情報が更新されますが、</div>
                                                            <div>よろしいですか？</div>
                                                        </div>
                                                    }
                                                >
                                                    <button type='button' className='btn-2' onClick={onSaveToolCertificate}>
                                                            情報更新
                                                    </button>
                                                </TooltipCustom>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
})

export default ToolCertificateScreen;
