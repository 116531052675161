/* eslint-disable max-len */
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { SYSTEM_PATH } from '../../../core/configs/constants';

const AnswerItem = observer(({ item, index, questionIndex, onChange, isFinished, disabled }) => {
    const handleChange = (event) => {
        onChange(questionIndex, index, event.target.checked);
    };

    const id = questionIndex + '-' + index;
    const isTrue = item.isChecked && item.is_correct;

    return (
        <li>
            {isFinished && item.isChecked && <span className="icon-1"><img src={isTrue ? '/images/elip.svg' : '/images/close.svg'} alt="" /></span>}
            <input disabled={disabled} id={id} type="radio" checked={item.isChecked} onChange={handleChange} />
            <label className='cursor-pointer' htmlFor={id}>{item.content}</label>
        </li>
    );
});

function QuestionItem({ item, index, onChange, isFinished, disabled }) {
    const isTrue = (item.answers || []).find(x => x.isChecked && x.is_correct);
    return (
        <div className="item-execute">
            <h4 className="title-4">
                <span>{index + 1}</span> {item.question}
            </h4>
            <div className="list-execute">
                <ul>
                    {item.answers?.map((answer, answerIdx) => {
                        return (
                            <AnswerItem
                                key={index + 1 + '#' + answerIdx}
                                onChange={onChange}
                                item={answer}
                                index={answerIdx}
                                questionIndex={index}
                                isFinished={isFinished}
                                disabled={disabled}
                            />
                        )
                    })}
                    {isFinished && !isTrue && <p className="note"><Link target='_blank' to={SYSTEM_PATH.LECTURE + item.lectureId}>{item.lectureName}</Link>の再視聴をお 勧めします。</p>}
                </ul>
            </div>
        </div>
    );
}

export default observer(QuestionItem);
