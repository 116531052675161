import { action, flow, makeObservable, observable } from 'mobx';
import { NotifyApi } from '../apis';

class NotifyStore {

    notifies = [];

    notify = {};

    constructor(rootStore) {
        makeObservable(this, {
            notifies: observable,
            notify: observable,
            getNotifies: flow.bound,
            getNotifyDetail: flow.bound
        });
        this.rootStore = rootStore;
        this.api = new NotifyApi();
    }

    *getNotifies(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getNotify, payload);
            if(res?.ok) {
                this.notifies = res?.data?.elements || [];
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getNotifyDetail(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getNotifyDetail, { id, payload });
            if(res?.ok) {
                this.notify = res?.data || {};
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export default NotifyStore;
