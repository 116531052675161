import { action, flow, makeObservable, observable } from 'mobx';
import { MessageApi } from '../apis';
import BaseStore from './BaseStore';

class MessageStore extends BaseStore {

    messages = [];
    allMessages = [];
    pagingAllMessage = { ...this.paging };

    totalMessages = {
        all: 0,
        notSeen: 0
    }

    messagePopupInfo = {}

    constructor(rootStore) {
        super();
        makeObservable(this, {
            messages: observable,
            totalMessages: observable,
            allMessages: observable,
            pagingAllMessage: observable,
            messagePopupInfo: observable,
            getAllMessages: flow.bound,
            getMessageDetail: flow.bound,
            readMessages: flow.bound,
            sendMessage: flow.bound,
            pushNewMessage: action.bound,
            cleanMessagePage: action.bound,
            getMessagePopup: flow.bound,
            readMessagePopup: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new MessageApi();
    }

    *getAllMessages(searchParams, isMessagePage = false, disableShowLoading = false) {
        try {
            const { size, page, sortDir, sortKey } = this.paging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllMessages, payload, false, null, disableShowLoading);
            if(res?.ok) {
                if (isMessagePage) {
                    this.allMessages = res?.data?.elements;
                    this.setAttrObservable('pagingAllMessage', {...res?.data?.paginate, ...searchParams}, true, false);
                } else {
                    this.messages = res?.data?.elements;
                    if (searchParams?.seen === 0) {
                        this.totalMessages.notSeen = res?.data?.total?.notSeen || 0;
                    } else {
                        this.totalMessages.all = res?.data?.total?.all || 0;
                        this.totalMessages.notSeen = res?.data?.total?.notSeen || 0;
                    }
                    this.setAttrObservable('paging', res?.data?.paginate, true, false);
                }
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *readMessages(payload, cb) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.readMessages, payload);
            if(res?.ok) {
                cb && cb();
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *getMessageDetail(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getMessageDetail, payload);
            return res?.data;
        } catch (error) {
            console.log(error);
            return;
        }
    }

    *sendMessage(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.sendMessage, payload);
            if(res?.ok) {
                return true;
            } return false
        } catch (error) {
            console.log(error);
            return;
        }
    }

    *getMessagePopup(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getMessagePopup, payload);
            if(res?.ok) {
                this.messagePopupInfo = res?.data;
                return res;
            } 
            return false
        } catch (error) {
            console.log(error);
            return;
        }
    }

    *readMessagePopup( id, payload, disableLoading) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.readMessagePopup, { id, payload }, false, null, disableLoading);
            if(res?.ok) {
                this.messagePopupInfo = res?.data;
                return res;
            } 
            return false
        } catch (error) {
            console.log(error);
            return;
        }
    }

    pushNewMessage(msg) {
        if (this.pagingAllMessage.seen !== '1') {
            this.allMessages.unshift(msg);
        }
        this.messages.unshift(msg);
        this.totalMessages.all += 1;
        this.totalMessages.notSeen += 1;
    }

    cleanMessagePage() {
        this.allMessages = [];
        this.pagingAllMessage = {
            ...this.paging,
            page: 1,
            size: 10
        }
    }
}

export default MessageStore;