import { useEffect, useState } from 'react';

export function useIsActiveTab(page = 'lecture') {
    const tabId = 'tab_' + page;
    const openpageId = 'openpages_' + tabId;
    const [isActiveTab, setIsActiveTab] = useState(false);
    const [checkActiveTab, setCheckActiveTab] = useState(true);

    const handleCheckTabOpened = (event) => {
        if (event.key === openpageId) {
            const timeOpen = Date.now();
            localStorage[tabId] = timeOpen;
            
        }

        if (event.key === tabId) {
            const url = new URL(window.location);
            const currentTime = Date.now();
            const lastTime = url.searchParams.get('t');
            if (!lastTime || Number(lastTime) > currentTime) {
                setIsActiveTab(true);
            }
        }
    }

    useEffect(() => {
        const timeOpen = Date.now();
        localStorage[openpageId] = timeOpen;
        window.addEventListener('storage', handleCheckTabOpened);
        const timeRef = setTimeout(() => {
            setCheckActiveTab(false);
        }, 100)
        return () => {
            window.removeEventListener('storage', function() {
                localStorage.openpages = undefined;
                localStorage[tabId] = undefined;
            });
            if (timeRef) {
                clearTimeout(timeRef);
            }
        };
    }, []);
    return { checkActiveTab, isActiveTab };
}
