/* eslint-disable max-len */
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { MSG, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';

const ChangePasswordScreen = observer(props => {

    // store
    const {
        userStore: { changePassword },
        modalStore: { openAlert }
    } = useStore();

    // state
    const [ showPassword, setShowPassword ] = useState({
        passwordOld: false,
        passwordNew: false,
        confirmPasswordNew: false
    });

    const validateSchema = yup.object().shape({
        passwordOld: yup.string().required(MSG['error.required']),
        passwordNew: yup.string().matches(
            new RegExp('^(?=.*[A-Z])[a-zA-Z0-9]{8,}$'),
            MSG['error.password_format_detail']
        ).required(MSG['error.required']),
        confirmPasswordNew: yup
            .string()
            .oneOf([yup.ref('passwordNew')], MSG['error.confirm_password_not_match'])
            .required(MSG['error.required'])
    })

    const { register, handleSubmit, formState: { errors, isSubmitting }, reset, setValue, trigger, getValues } = useForm({resolver: yupResolver(validateSchema), mode: 'onChange'});

    // function
    const toggleShowPassword = (password) => {
        setShowPassword({
            ...showPassword,
            [password]: !showPassword[password]
        })
    }

    const onChangePassword = async (field, value) => {
        setValue(field, value);
        if(getValues('passwordNew') && getValues('confirmPasswordNew')) {
            trigger(['passwordNew', 'confirmPasswordNew']);
        } else {
            trigger(field);
        }
    }

    const onSubmitChangePassword = async (data) => {
        const { passwordNew, passwordOld } = data;
        const res = await changePassword({ passwordNew, passwordOld });
        if(res) {
            openAlert('パスワードを変更しました。');
            reset();
        }
    }

    return (
        <div id='main' className='wrapper main-signin main-ChangPassword change-password-screen'>
            <div className='bres-cus'>
                <div className='container'>
                    <ul>
                        <li>
                            <Link to={SYSTEM_PATH.HOME}><img src='/images/icon-home.svg' alt='' /></Link> 
                        </li>
                        <li className='dash'>\</li>
                        <li>パスワード変更</li>
                    </ul>
                </div>
            </div>
            <div className='content-signin'>
                <div className='container'>
                    <div className='content-form'>
                        <h2 className='title-primary'>パスワード変更</h2>
                        <form onSubmit={handleSubmit(onSubmitChangePassword)}>
                            <div className='item'>
                                <label htmlFor='passwordOld'>現在パスワード <span>*</span></label>
                                <div className='input'>
                                    <input {...register('passwordOld')} type={showPassword.passwordOld ? 'text' : 'password'} 
                                        autoComplete="off"
                                        onCopy={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                        onCut={(e) => e.preventDefault()}
                                    />
                                    <button type='button' className='button-toggle-password' onClick={() => toggleShowPassword('passwordOld')}>
                                        {
                                            !showPassword.passwordOld ? 
                                                <i className='fa fa-eye-slash'></i>
                                                : 
                                                <i className='fa fa-eye'></i>
                                        }
                                    </button>
                                </div>
                                {
                                    errors?.passwordOld &&
                                    <div className='text-danger mg-t-5'>{errors.passwordOld?.message}</div>
                                }
                            </div>
                            <div className='item'>
                                <label htmlFor>新しいパスワード <span>*</span></label>
                                <div className='input'>
                                    <input {...register('passwordNew')} type={showPassword.passwordNew ? 'text' : 'password'} 
                                        autoComplete="off"
                                        onCopy={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                        onCut={(e) => e.preventDefault()}
                                        onChange={(e) => onChangePassword('passwordNew', e.target.value)}
                                    />
                                    <button type='button' className='button-toggle-password' onClick={() => toggleShowPassword('passwordNew')}>
                                        {
                                            !showPassword.passwordNew ? 
                                                <i className='fa fa-eye-slash'></i>
                                                : 
                                                <i className='fa fa-eye'></i>
                                        }
                                    </button>
                                </div>
                                {
                                    errors?.passwordNew &&
                                    <div className='text-danger mg-t-5'>{errors.passwordNew?.message}</div>
                                }
                            </div>
                            <div className='item'>
                                <label htmlFor>新しいパスワード確認 <span>*</span></label>
                                <div className='input'>
                                    <input {...register('confirmPasswordNew')} type={showPassword.confirmPasswordNew ? 'text' : 'password'}
                                        autoComplete="off"
                                        onCopy={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                        onCut={(e) => e.preventDefault()}
                                        onChange={(e) => onChangePassword('confirmPasswordNew', e.target.value)}
                                    />
                                    <button type='button' className='button-toggle-password' onClick={() => toggleShowPassword('confirmPasswordNew')}>
                                        {
                                            !showPassword.confirmPasswordNew ? 
                                                <i className='fa fa-eye-slash'></i>
                                                : 
                                                <i className='fa fa-eye'></i>
                                        }
                                    </button>
                                </div>
                                {
                                    errors?.confirmPasswordNew &&
                                    <div className='text-danger mg-t-5'>{errors.confirmPasswordNew?.message}</div>
                                }
                            </div>
                            <input type='submit' value='パスワード変更' disabled={isSubmitting}/>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
})

export default ChangePasswordScreen;