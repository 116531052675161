import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../core/utils/hook';

import '../style.scss';
import { DATE_YMD } from '../../../core/configs/constants';

const NotifyList = observer(props => {

    // navigate
    const navigate = useNavigate();

    // store
    const { notifyStore: { notifies, getNotifies } } = useStore();

    // lifecycle
    useEffect(() => {
        getNotifies({ size: -1, status: 1 });
    }, [])

    return(
        <div className='notify container'>
            <div className='title'>
                <h2 className='title-primary'>【お知らせ】</h2>
            </div>
            {
                notifies?.length > 0 ?
                    <div className='list-notify'>
                        {
                            notifies?.map((e, index) => 
                                <React.Fragment key={e.id}>
                                    <div className='notify-content' onClick={() => navigate(`notification/${e.id}`)}>
                                        <span className='notify-date'>{moment(e.createdAt).format(DATE_YMD)}</span>
                                        <span className='notify-title'>{e.title}</span>
                                    </div>
                                    <div className='border-bottom'></div>
                                </React.Fragment>
                            )
                        }
                    </div>
                    :
                    <div className='mg-t-10'>お知らせがありません。</div>
            }
        </div>
    )
})

export default NotifyList;