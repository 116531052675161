import { Link } from 'react-router-dom';
import { SYSTEM_PATH } from '../../core/configs/constants';

const Footer = () => {
    return(
        <footer id='Footer'>
            <div className='container'>
                <div className='top-footer'>
                    <div className='row align-items-center'>
                        <div className='col-md-3 col-sm-3 col-4'>
                            <a className='logo-footer'>
                                <img src='/images/LogoWhite.png' alt='' width={80}/>
                            </a>
                        </div>
                        <div className='col-md-9 col-sm-9 col-8'>
                            <div className='item-footer row'>
                                <ul className='row'>
                                    <li className='col-md-4 col-sm-6 col-12'>
                                        <Link target="_blank" to={SYSTEM_PATH.ABOUT_US}>運営内容</Link>
                                    </li>
                                    <li className='col-md-4 col-sm-6 col-12'>
                                        <Link target="_blank" to={SYSTEM_PATH.PRIVACY_POLICY}>個人情報保護方針</Link>
                                    </li>
                                    <li className='col-md-4 col-sm-6 col-12'>
                                        <Link target="_blank" to={SYSTEM_PATH.SPECIFIED_COMMERCIAL_TRANSACTION_LAW_DISPLAY}>特定商取引法表示</Link>
                                    </li>
                                    <li className='col-md-4 col-sm-6 col-12'>
                                        <Link target="_blank" to={SYSTEM_PATH.TERM_OF_SERVICE}>利用規約</Link>
                                    </li>
                                    {/* <li className='col-md-4 col-sm-6 col-12'>
                                        <Link target="_blank" to={SYSTEM_PATH.CANCELLATION_POLICY}>登録前の注意事項</Link>
                                    </li> */}
                                    <li className='col-md-4 col-sm-6 col-12'>
                                        <Link target="_blank" to={SYSTEM_PATH.RECOMMENDED_ENVIRONMENT}>推奨環境</Link>
                                    </li>
                                    <li className='col-md-4 col-sm-6 col-12'>
                                        <Link target="_blank" to={SYSTEM_PATH.ABOUT_COMPANY}>elephamtとは</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='copyright'>
                    <p>Copyright © 2022 一般社団法人医学薬学ナレッジ All rights reserved</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;