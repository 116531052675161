import { observer } from 'mobx-react';
import ProfileLayout from './components/ProfileLayout';
import { useStore } from '../../core/utils/hook';
import { useEffect } from 'react';
import {
    USER_TYPE 
} from '../../core/configs/constants';
import { reloadEventsDOMContentLoaded } from '../../core/utils/browser';
import UpdateProfile from './components/UpdateProfile'
import UpdateProfileGA from './components/UpdateProfileGA';

const ProfileEditScreen = observer(() => {
    const { userStore: { getProfile, profile } } = useStore();

    useEffect(() => {
        reloadEventsDOMContentLoaded();
    }, [])

    useEffect(() => {
        if (profile) return
        getProfile();
    }, [profile]);


    return (
        <ProfileLayout>
            {profile?.roleId == USER_TYPE.GROUP_MANAGER ? <UpdateProfileGA />: <UpdateProfile /> }
        </ProfileLayout>
    )
})

export default ProfileEditScreen