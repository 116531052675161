/* eslint-disable max-len */
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { Calendar, DateRange, DateRangePicker } from 'react-date-range';
import * as localDefault from 'date-fns/locale/ja/index';

import './style.scss';
import { useOutsideAlerter } from '../../core/utils/hook';
import moment from 'moment';
import ReactInputMask from 'react-input-mask';

const MutilCalendarCustom = (props) => {

    // props
    const {
        ariaLabels = {},
        calendarFocus = 'forwards',
        className,
        color = '#0077B6',
        date,
        dateDisplayFormat = 'yyyy/MM/DD',
        dayDisplayFormat = 'dd',
        direction = 'vertical',
        disabledDates = [],
        disabledDay,
        displayMode = 'date',
        dragSelectionEnabled = true,
        editableDateInputs = false,
        endDatePlaceholder = 'Continuous',
        fixedHeight = false,
        focusedRange = [0, 0],
        initialFocusedRange,
        locale,
        maxDate,
        minDate,
        monthDisplayFormat = 'yyyy/MM',
        months = 1,
        navigatorRenderer,
        onChange,
        onPreviewChange,
        onRangeFocusChange,
        onShownDateChange,
        preventSnapRefocus = false,
        preview,
        rangeColors = ['#0077B6', '#3ecf8e', '#fed14c'],
        ranges = [{
            startDate: null,
            endDate: new Date(''),
            key: 'selection'
        }],
        scroll,
        showDateDisplay = false,
        showMonthAndYearPickers = true,
        showMonthArrow = true,
        showPreview = true,
        shownDate,
        startDatePlaceholder = 'Early',
        updateRange,
        weekStartsOn,
        weekdayDisplayFormat = 'E',
        isRenderInput = true,
        isEditInput = false,
        iconInput,
        // date range
        moveRangeOnFirstSelection = false,
        showSelectionPreview = true,
        // date range picker
        monthsPicker = 2,
        directionPicker = 'horizontal',
        disabled,
        dayContentRenderer,
        dateDisplay,
        isFirstDate = false
    } = props;

    // state
    const [ showCalendar, setShowCalendar ] = useState(false);
    const [ countSelectRange, setCountSelectRange ] = useState(0);
    const [ dateInput, setDateInput ] = useState('');
    const [ invalidDateInput, setInvalidDateInput ] = useState(false);
    const [ firstDate, setFirstDate ] = useState(false);

    // ref
    const calendarRef = useOutsideAlerter(() => setShowCalendar(false));

    // lifecycle
    useEffect(() => {
        if(isFirstDate && !firstDate) {
            if(displayMode === 'date') {
                if(date && moment(new Date(date)).isValid()) {
                    setDateInput(moment(new Date(date)).format(dateDisplayFormat));
                    setFirstDate(true);
                    setInvalidDateInput(false);
                }
            } else {
                if(ranges?.[0]?.startDate && !isNaN(ranges?.[0]?.startDate) && ranges?.[0]?.endDate && !isNaN(ranges?.[0]?.endDate)) {
                    setDateInput(`${ranges?.[0]?.startDate && !isNaN(ranges?.[0]?.startDate) ? `${moment(new Date(ranges[0].startDate)).format(dateDisplayFormat)} ~`: dateInput?.split('~ ')?.[0] } ${ranges?.[0]?.endDate && !isNaN(ranges?.[0]?.endDate) ? moment(new Date(ranges[0].endDate)).format(dateDisplayFormat): dateInput?.split('~')?.[1]}`);
                    setFirstDate(true);
                    setInvalidDateInput(false);
                }
            }
        }
    }, [date, ranges])

    // function
    const onToggleShowCalendar = () => {
        setShowCalendar(!showCalendar);
    }

    const onChangeSelectDate = (date) => {
        if(date && moment(new Date(date)).isValid()) {
            setDateInput(moment(new Date(date)).format(dateDisplayFormat));
            setInvalidDateInput(false);
        }
        onChange && onChange(date);
        setShowCalendar(false);
    }
    const onChangeSelectDateRange = (date) => {
        if (date?.selection?.startDate && date?.selection?.endDate) {
            if(date?.selection?.startDate && !isNaN(date?.selection?.startDate) && date?.selection?.endDate && !isNaN(date?.selection?.endDate)) {
                setDateInput(`${date?.selection?.startDate && !isNaN(date?.selection?.startDate) ? `${moment(new Date(date?.selection?.startDate)).format(dateDisplayFormat)} ~`: dateInput?.split('~ ')?.[0] } ${date?.selection?.endDate && !isNaN(date?.selection?.endDate) ? moment(new Date(date?.selection?.endDate)).format(dateDisplayFormat): dateInput?.split('~')?.[1]}`);
                setInvalidDateInput(false);
            }
            onChange && onChange(date);
        }

        if(countSelectRange === 1) {
            setShowCalendar(false);
            setCountSelectRange(0);
        } else setCountSelectRange(countSelectRange + 1);
    }

    const onClearCalendar = () => {
        if(displayMode === 'date') {
            onChange && onChange(null);
        } else {
            onChange && onChange([{
                startDate: null,
                endDate: new Date(''),
                key: 'selection'
            }]);
        }
        if(isEditInput) {
            if(displayMode === 'date') {
                setDateInput('____/__/__');
            } else {
                setDateInput('____/__/__ ~ ____/__/__');
            }
            setInvalidDateInput(false);
        } 
    }

    const onChangeInputEdit = (value) => {
        if(dateInput !== value) {
            if(displayMode === 'date') {
                const dt = value;
                if(!dt?.includes('_') && moment(new Date(dt)).isValid()) {
                    setInvalidDateInput(false);
                    onChange && onChange(dt);
                } else {
                    setInvalidDateInput(true);
    
                    if(value === '' || value === '____/__/__') {
                        onClearCalendar();
                    }
                }
            } else {
                const [ start, end ] = value?.split(' ~ ') || ['', ''];
                const startDate = moment(new Date(start));
                const endDate = moment(new Date(end));
                if(!value?.includes('_') && startDate?.isValid() && endDate?.isValid() && start <= end) {
                    setInvalidDateInput(false);
                    onChange && onChange({
                        selection: {
                            startDate: new Date(start),
                            endDate: new Date(end),
                            key: 'selection'
                        }
                    });
                } else {
                    setInvalidDateInput(true);
                    
                    if(value === '' || value === '____/__/__ ~ ____/__/__') {
                        onClearCalendar();
                    }
                }
            }
        }
        setDateInput(value);
    }

    return(
        <div className={classnames('calendar-custom pd-0', className, disabled && 'disabled-date-picker')} ref={isRenderInput ? calendarRef : null}>
            {
                isRenderInput &&
                <div className='container-input pd-0'>
                    
                    {
                        !isEditInput ?
                            <input className='w-100' 
                                value={
                                    displayMode === 'date' ? 
                                        date ? moment(new Date(date)).format(dateDisplayFormat) : ''
                                        :
                                    // eslint-disable-next-line max-len
                                        `${ranges?.[0]?.startDate && !isNaN(ranges?.[0]?.startDate) ? `${moment(new Date(ranges[0].startDate)).format(dateDisplayFormat)} ~`: '' } ${ranges?.[0]?.endDate && !isNaN(ranges?.[0]?.endDate) ? moment(new Date(ranges[0].endDate)).format(dateDisplayFormat): ''}`
                                } 
                                readOnly={true}
                                onClick={onToggleShowCalendar}
                                disabled={disabled}
                            />
                            :
                            <ReactInputMask
                                mask={ displayMode === 'date' ? '9999/99/99' : '9999/99/99 ~ 9999/99/99'}
                                className={classnames('w-100 calendar-edit-input', invalidDateInput && 'border-danger')}
                                value={dateInput} 
                                onChange={e => onChangeInputEdit(e.target.value)}
                                alwaysShowMask={true}
                                disabled={disabled}
                            />
                    }
                    <div className='btn-icon-input'>
                        {iconInput || <i className='fa-solid fa-calendar-days' onClick={onToggleShowCalendar}></i> }
                    </div>
                    {
                        (date || (ranges?.[0]?.startDate && !isNaN(ranges?.[0]?.startDate))) &&
                        <div className='btn-icon-input btn-clear-input btn-mutil-clear-input' onClick={onClearCalendar}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                    }
                </div>
            }
            {
                ((isRenderInput && showCalendar) || !isRenderInput) && 
                <div className='container-calender'>
                    { 
                        displayMode === 'date' &&
                        <Calendar
                            ariaLabels={ariaLabels}
                            calendarFocus={calendarFocus}
                            className={'calendar'}
                            color={color}
                            date={date ? new Date(date) : new Date()}
                            dateDisplayFormat={dateDisplayFormat}
                            dayDisplayFormat={dayDisplayFormat}
                            direction = {direction}
                            disabledDates = {disabledDates}
                            disabledDay = {disabledDay}
                            displayMode = {'date'}
                            dragSelectionEnabled = {dragSelectionEnabled}
                            fixedHeight = {fixedHeight}
                            focusedRange = {focusedRange}
                            locale = {locale || localDefault}
                            maxDate = {maxDate}
                            minDate = {minDate}
                            monthDisplayFormat = {monthDisplayFormat}
                            months = {months}
                            navigatorRenderer = {navigatorRenderer}
                            onChange = {onChangeSelectDate}
                            onPreviewChange = {onPreviewChange}
                            onShownDateChange = {onShownDateChange}
                            preventSnapRefocus = {preventSnapRefocus}
                            preview = {preview}
                            scroll = {scroll}
                            showDateDisplay = {showDateDisplay}
                            showMonthAndYearPickers = {showMonthAndYearPickers}
                            showMonthArrow = {showMonthArrow}
                            showPreview = {showPreview}
                            shownDate = {shownDate}
                            weekStartsOn = {weekStartsOn}
                            weekdayDisplayFormat = {weekdayDisplayFormat}
                            dayContentRenderer={dayContentRenderer}
                            dateDisplay={dateDisplay}
                        />
                    }
                    {
                        displayMode === 'dateRange' &&
                        <DateRange
                            ariaLabels={ariaLabels}
                            calendarFocus={calendarFocus}
                            className={'calendar'}
                            color={color}
                            direction = {direction}
                            disabledDates = {disabledDates}
                            disabledDay = {disabledDay}
                            dragSelectionEnabled = {dragSelectionEnabled}
                            editableDateInputs = {editableDateInputs}
                            endDatePlaceholder = {endDatePlaceholder}
                            fixedHeight = {fixedHeight}
                            initialFocusedRange = {initialFocusedRange}
                            locale = {locale || localDefault}
                            maxDate = {maxDate}
                            minDate = {minDate}
                            monthDisplayFormat = {monthDisplayFormat}
                            months = {months}
                            navigatorRenderer = {navigatorRenderer}
                            onChange = {onChangeSelectDateRange}
                            rangeColors = {rangeColors}
                            ranges = {ranges}
                            scroll = {scroll}
                            showDateDisplay = {showDateDisplay}
                            showMonthAndYearPickers = {showMonthAndYearPickers}
                            showMonthArrow = {showMonthArrow}
                            showPreview = {showPreview}
                            startDatePlaceholder = {startDatePlaceholder}
                            updateRange = {updateRange}
                            weekStartsOn = {weekStartsOn}
                            weekdayDisplayFormat = {weekdayDisplayFormat}
                            moveRangeOnFirstSelection={moveRangeOnFirstSelection}
                            showSelectionPreview={showSelectionPreview}
                        />
                    }
                    {
                        displayMode === 'dateRangePicker' &&
                        <DateRangePicker
                            ariaLabels={ariaLabels}
                            calendarFocus={calendarFocus}
                            className={'calendar-picker'}
                            color={color}
                            locale = {locale || localDefault}
                            ranges={ranges}
                            months={monthsPicker}
                            onChange={onChangeSelectDateRange}
                            direction={directionPicker}
                            showSelectionPreview={showSelectionPreview}
                            moveRangeOnFirstSelection={moveRangeOnFirstSelection}
                            showDateDisplay={showDateDisplay}
                            monthDisplayFormat = {monthDisplayFormat}
                        />
                    }
                </div>
            }
        </div>
    )
}

export default MutilCalendarCustom;