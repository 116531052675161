import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DATE_TIME_YMD, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import moment from 'moment';

function MessagesDetailScreen() {
    const [message, setMessage] = useState();
    const navigate = useNavigate();
    const params = useParams();

    // store
    const {
        messageStore: { getMessageDetail, readMessages, getAllMessages },
        userStore: { onExtendCoursePeriod },
    } = useStore();

    useEffect(() => {
        fetchData(params?.id);
    }, [params?.id]);

    const fetchData = async (id) => {
        try {
            const res = await getMessageDetail({ id });
            setMessage(res);
            if (res && !res.isSeen) {
                await readMessages({ id: res.id });
                getAllMessages();
            }
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const goMessageList = () => {
        navigate(SYSTEM_PATH.MESSAGES);
    };

    if (!message) return <div style={{ height: '68vh' }}></div>;

    return (
        <div id="main" className="wrapper  main-Message-detail">
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li>
                            <a href="/">
                                <img src="/images/icon-home.svg" alt="" />
                            </a>
                        </li>
                        <li className="dash">\</li>
                        <li>
                            <a href={SYSTEM_PATH.MESSAGES}>メッセージ一覧</a>
                        </li>
                        <li className="dash">\</li>
                        <li>{message.title}</li>
                    </ul>
                </div>
            </div>
            <div className="content-Message-detail">
                <div className="container">
                    {/* 1 = GA, 0 = Jiho Admin */}
                    {message.senderRole === 1 && <span className='title-secondary'>企業管理者より</span>}
                    <p className="date">
                        {message.category} | {moment(message.sendDate || message.createdAt).format(DATE_TIME_YMD)}
                    </p>
                    <h1 className="title-primary">{message.title}</h1>
                    <div className="content-content pre-wrap">{
                        !message.isNotifyExpireCoursePeriod ? 
                            message.content: 
                            <>
                                <span>受講期間終了まで残り{message.content}となりました。 下記より受講期間の更新が可能となり ますのでお早めにお手続きをお願いいたします。 </span>
                                <br/>
                                <span className='text-decoration-underline text-hover-link' onClick={onExtendCoursePeriod}>受講プランの申込み</span>
                            </> 
                    }</div>
                    <div className="link-link">
                        <button onClick={goMessageList}>メッセージリストへ戻る</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(MessagesDetailScreen);
