export const getSavedInfo = fieldName => {
    return localStorage.getItem(fieldName) || sessionStorage.getItem(fieldName) || null;
};

export const getSavedInfoJson = fieldName => {
    return JSON.parse(localStorage.getItem(fieldName)) || JSON.parse(sessionStorage.getItem(fieldName)) || null;
};

export const reloadEventsDOMContentLoaded = () => {

    document.dispatchEvent(new Event('DOMContentLoaded', {

        bubbles: true,

        cancelable: true

    }));

}

export const reloadScript = (url, callback = () => {}) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    if (script.readyState){  //IE
        script.onreadystatechange = function(){
            if (script.readyState == 'loaded' ||
                    script.readyState == 'complete'){
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {  //Others
        script.onload = function(){
            callback();
        };
    }

    script.src = url;
    document.getElementsByTagName('body')[0].appendChild(script);
}