import './style.scss';

const Tooltip = ({ children, title }) => {

    return (
        <div className='tooltip-custom'>
            { children }
            <span className="tooltiptext">{title}</span>
        </div>
    )
}

export default Tooltip;