import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { PAYMENT_STATUS, PAYMENT_STATUS_NAME } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import Pagination from '../Courses/components/Pagination';
import ProfileSideBar from './components/ProfileSideBar';
import PurchaseHistoryItem from './components/PurchaseHistoryItem';
import {debounce} from 'lodash'

function PurchaseHistoryScreen() {
    const [params, setParams] = useState({ status: '', ticketName: '' });
    // store
    const {
        userStore: { pagingPurchaseHistory, listPurchaseHistories, getPurchaseHistories, setAttrObservable, deletePurchase, cancelPurchase }
    } = useStore();

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = (payload = {}) => {
        setAttrObservable('pagingPurchaseHistory', { page: 1 }, true, false);
        if (payload.ticketName) {
            payload.ticketName = payload.ticketName.trim()
        }
        getPurchaseHistories(payload);
    }

    const handleChangeStatus = (event) => {
        const status = event.target.value;
        const payload = {...params, status}
        setParams(payload)
        fetchData(payload);
    }

    const debouncedSearch = debounce(function (ticketName) {
        const payload = {...params, ticketName}
        setParams(payload)
        fetchData(payload);
    }, 1000);

    const handleChangeText = (event) => {
        debouncedSearch(event.target.value)
    }

    const handleChangePage = () => {
        const ticketName = params.ticketName ? params.ticketName.trim() : ''
        getPurchaseHistories({...params, ticketName});
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const onDeletePurchase = async (item) => {
        if(item.status === PAYMENT_STATUS.UNPAID) {
            const res = await deletePurchase({ id: item.paymentId });
            if(res) {
                fetchData(params);
            }
        }
    }

    const onRequestCancelPurchase = async (item) => {
        if(item.status === PAYMENT_STATUS.PROCESSING) {
            const res = await cancelPurchase({ id: item.paymentId });
            if(res) {
                fetchData(params);
            }
        }
    }

    return (
        <div id="main" className="wrapper main-student-list main-Attended-couse main-purchasse-history">
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li>
                            <a href="/">
                                <img src="/images/icon-home.svg" alt="" />
                            </a>
                        </li>
                        <li className="dash">\</li>
                        <li>購入履歴</li>
                    </ul>
                </div>
            </div>
            <section className="top-student-list">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 col-sm-2 col-12">
                            <ProfileSideBar />
                        </div>
                        <div className="col-md-10 col-sm-10 col-12">
                            <div className="content-Attended-couse ">
                                <h1 className="title-primary">購入履歴</h1>
                                <div className="top-top">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <h3 className="title-3">
                                                <span>ステータス</span>
                                                <select onChange={handleChangeStatus}>
                                                    <option value={''}>全て</option>
                                                    <option value={PAYMENT_STATUS.PROCESSING}>{PAYMENT_STATUS_NAME.PROCESSING}</option>
                                                    <option value={PAYMENT_STATUS.UNPAID}>{PAYMENT_STATUS_NAME.UNPAID}</option>
                                                    <option value={PAYMENT_STATUS.PAID}>{PAYMENT_STATUS_NAME.PAID}</option>
                                                    <option value={PAYMENT_STATUS.CANCEL_REQUEST}>{PAYMENT_STATUS_NAME.CANCEL_REQUEST}</option>
                                                    <option value={PAYMENT_STATUS.CANCELED}>{PAYMENT_STATUS_NAME.CANCELED}</option>
                                                </select>
                                            </h3>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <form onSubmit={e => e.preventDefault()}>
                                                <input onChange={handleChangeText} type="text" placeholder="オーダーコードで検索" />
                                                <button>
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {listPurchaseHistories?.map((item, index) => {
                                    return <PurchaseHistoryItem key={index} item={item} 
                                        onDeletePurchase={onDeletePurchase}
                                        onRequestCancelPurchase={onRequestCancelPurchase}/>;
                                })}
                                
                                {!listPurchaseHistories?.length && <div className='no-data w-100'>
                                    <p>データはありません</p>
                                </div>}

                                {listPurchaseHistories?.length > 0 && <Pagination paging={pagingPurchaseHistory} onFetch={handleChangePage} />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default observer(PurchaseHistoryScreen);
