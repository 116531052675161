import { observer } from 'mobx-react';
import Breadcrumb from './Breadcrumb';
import ProfileSideBar from './ProfileSideBar';

const ProfileLayout = observer((props) => {
    const { type, children } = props;

    return (
        <div id="main" 
            className="wrapper 
                main-student-list 
                main-view-student-detail 
                main-view-detail-information
                main-account-trgisstration-1 main-account-trgisstration-2
                main-account-trgisstration-4 main-update main-update-acount 
                main-detail-count-info">
            <Breadcrumb />
            <section className="top-student-list">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-2 col-lg-2 col-md-12 col-sm-12">
                            <ProfileSideBar type={type} />
                        </div>
                        <div className="col-12 col-xl-10 col-lg-10 col-md-12 col-sm-12">
                            {children}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
})

export default ProfileLayout;