import { flow, makeObservable, observable } from 'mobx';
import { PaymentApi } from '../apis';
import BaseStore from './BaseStore';
class PaymentStore extends BaseStore {

    constructor(rootStore) {
        super();
        makeObservable(this, {
            pushDataToGMO: flow.bound,
            statusOrder: flow.bound,
            pushToPaymentStatusWhenOrderFree: flow.bound,
            exportInvoicePDF: flow. bound
        })
        this.rootStore = rootStore;
        this.api = new PaymentApi();
    }

    *pushDataToGMO(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.pushToGMO, payload);
            if(res?.ok) {
                return res?.data
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }
    
    *pushToPaymentStatusWhenOrderFree(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.pushToPaymentWhenOrderFree, payload);
            console.log(res,'res---')
            if(res?.ok) {
                return res?.data
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *statusOrder(orderID) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.checkStatusOrder, orderID, false, null, false, true);
            if(res?.ok) {
                return res?.data
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *exportInvoicePDF(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.exportInvoicePDF, { id, payload }, false, null, true);
            if(res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return error;
        }
    }
}

export default PaymentStore;