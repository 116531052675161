import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from '../../../core/utils/hook';

const RegistrationStep = observer(() => {

    const {
        authStore: {registerCurrentStep}
    } = useStore();

    useEffect(() => {
        const section = document.querySelector(`#step${registerCurrentStep}`);
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    },[registerCurrentStep])

    return (
        <div className="step-step">
            <ul>
                <li id="step1" className={`arrow-right ${registerCurrentStep === 1 ? 'active': registerCurrentStep > 2 ? 'active-done-step-next': 
                    registerCurrentStep > 1 && registerCurrentStep < 3? 'active-done':  'active'}`}>
                    {registerCurrentStep > 1 &&  <img src="/images/v.svg" alt="" />}   1. 登録前確認
                </li>
                <li id="step2" className={`arrow-right ${registerCurrentStep === 2 ? 'active': registerCurrentStep > 2 ? 'active-done': ''}`}>
                    {registerCurrentStep > 2 &&  <img src="/images/v.svg" alt="" />}
                    2. 情報登録
                </li>
                <li id="step3" className={` ${registerCurrentStep >= 3 ? 'active':  ''}`}>
                    {registerCurrentStep > 3 &&  <img src="/images/v.svg" alt="" />}
                    3. 登録完了
                </li>
            </ul>
        </div>
    );
})

export default RegistrationStep;