
import { observer } from 'mobx-react';
import yup from '../../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import { MSG, SCREEN_MODE, USER_TYPE, SYSTEM_PATH } from '../../../core/configs/constants';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../core/utils/hook';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';


const RegistrationEmailSecondStep = observer(() => {
    const [searchParams] = useSearchParams();
    const type = searchParams.get('type');
    const navigate = useNavigate();

    const validateSchema = yup.object().shape({
        roleId: yup.number(),
        attendanceCode: yup.string().when('roleId', {
            is: USER_TYPE.STUDENT_GROUP || USER_TYPE.STUDENT_GROUP.toString(),
            then: yup.string().required(MSG['error.required'])
        }),
        email: yup.string().required(MSG['error.required']).email(MSG['error.email_format']),
        confirmEmail: yup.string().when('userId', {
            is: null || '' || undefined,
            then: yup.string().required(MSG['error.required'])
                .email(MSG['error.email_format']).oneOf([yup.ref('email')], MSG['error.email_confirm_not_match'])
        })
    })

    const { register,
        handleSubmit,
        formState: { errors, isSubmitting },
        unregister,
        setValue,
        trigger,
        watch
    } = useForm({ resolver: yupResolver(validateSchema), mode: 'onChange' })
    const watchEmail = watch('email');
    const watchConfirmEmail = watch('confirmEmail');
    //store 
    const {
        authStore: { registerEmail }
    } = useStore();

    const onSubmit = (data) => {
        registerEmail({ ...data, roleId: parseInt(type) }).then(res => {
            if (res?.ok) {
                navigate(`${SYSTEM_PATH.REGISTRATION_SECOND_STEP}?type=${type}&step=2`)
            }
        })
        console.log('data:', data);
    }

    useEffect(() => {
        setValue('roleId', type)
        if (type == USER_TYPE.STUDENT_GROUP) return;
        if (!type || type == USER_TYPE.STUDENT_INDIVIDUAL) {

            unregister('attendanceCode',
                { keepError: false }
            )
        }
    }, [type])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-account">
                <h3 className="title-2">メールアドレス入力</h3>
                <div className="box-form-account">
                    <div className="content-content">
                        {type == USER_TYPE.STUDENT_GROUP &&
                            <div className="item">
                                <div className="item-left">
                                    <label for="">受講コード: <span>*</span></label>
                                </div>
                                <div className="item-right">
                                    <div className="item-2">
                                        <div className="input">
                                            <input {...register('attendanceCode')} type="text" />
                                        </div>
                                    </div>
                                    {errors?.attendanceCode?.message && <p className='text-error'>{errors?.attendanceCode?.message}</p>}
                                    <p className="note-1">※会社負担で受講される方は、上記へ入力してください。ご不明の場合は勤務先へお問合せください。</p>
                                </div>
                            </div>
                        }
                        <div className="item">
                            <div className="item-left">
                                <label for="">メールアドレス: <span>*</span></label>
                            </div>
                            <div className="item-right">
                                <div className="item-2">
                                    <div className="input">
                                        <input {...register('email')} type="text"
                                            onCopy={(e) => e.preventDefault()}
                                            onCut={(e) => e.preventDefault()}
                                            onChange={(e) => {
                                                setValue('email', e.target.value, { shouldValidate: true })
                                                if(watchConfirmEmail) {
                                                    trigger('confirmEmail', { shouldValidate: true })
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="icon">
                                        {
                                            watchEmail &&
                                            <>
                                                {
                                                    !errors.email ?
                                                        <img src="/images/icon11.svg" alt="" />
                                                        :
                                                        <img src="/images/clear.svg" alt="" />
                                                }
                                            </>
                                        }
                                    </div>

                                </div>
                                {errors?.email?.message && <p className='text-error' >{errors?.email?.message}</p>}
                                <p className="note-1">※ログインする時にログインIDとして使用します。</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-left">
                                <label for="">メールアドレスの確認: <span>*</span></label>
                            </div>
                            
                            <div className="item-right">
                                <div className="item-2">
                                    <div className="input">
                                        <input {...register('confirmEmail')} type="text"
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                    </div>
                                    <div className="icon">
                                        {
                                            watchConfirmEmail &&
                                            <>
                                                {
                                                    !errors.confirmEmail ?
                                                        <img src="/images/icon11.svg" alt="" />
                                                        :
                                                        <img src="/images/clear.svg" alt="" />
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                
                                {errors?.confirmEmail?.message && <p className='text-error' >{errors?.confirmEmail?.message}</p>}
                            </div>
                        </div>
                        <p className="note-2">※ドメイン指定受信されている方は【@elephamt.jp】を許可してください。</p>
                        <p className="note-2 mg -0">下記送信ボタンを押すと登録完了メールが送信されますので、メール内のURLより登録を完了させてください。</p>

                    </div>

                </div>
                <div className="prev-next">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                            <button type='button' onClick={(e) => {
                                navigate(SYSTEM_PATH.REGISTRATION_FIRST_STEP)
                            }} className=" btn-prev"> <i className="fa fa-long-arrow-left" aria-hidden="true"></i> 戻る</button>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                            <div className="next">
                                <button type='submit'>
                                    送 信 <span><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span>
                                </button>
                                {/* <a href="" type='submit'>送 信 <span><img src="/images/ar5.svg" alt="" /></span></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
});

export default RegistrationEmailSecondStep