import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Table } from '../../components';
import { SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';

const StudentListByAttendanceCodeScreen = observer(props => {

    // params, query
    const { id } = useParams();

    // store
    const { groupAdminStore: { getStudentListByAttendanceCode, studentListByAttendanceCode } } = useStore();

    // lifecycle
    useEffect(() => {
        getStudentListByAttendanceCode(id);
    }, [])

    // columns table
    const columns = [
        {
            Header: 'ID',
            accessor: 'userId',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.userId}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '名前',
            accessor: 'name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {
                            original?.inGroup ? 
                                <Link to={`/student-detail/${original.id}`}>{original?.name}</Link> : 
                                original?.name
                        }
                        
                    </div>
                );
            },
            width: '25%'
        },
        {
            Header: '薬剤師名簿登録番号',
            accessor: 'pharmacistNumber',
            disableSortBy: true,

            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.pharmacistNumber}
                    </div>
                );
            },
            width: '15%'
        },
        {
            Header: 'メールアドレス',
            accessor: 'email',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        { original?.email}
                    </div>
                );
            },
            width: '30%'
        }
    ]

    return(
        <div id='main' className='wrapper main-student-list main-view-student-detail main-student-list'>
            {
                // <div className='bres-cus'>
                //     <div className='container'>
                //         <ul>
                //             <li>
                //                 <Link to={SYSTEM_PATH.HOME}><img src='/images/icon-home.svg' alt='' /></Link>
                //             </li>
                //             {/* <li className='dash'>\</li>
                //             <li>ログイン</li> */}
                //         </ul>
                //     </div>
                // </div> 
            }
            <section className='content-student-list'>
                <div className='container'>
                    <h1 className='title-primary'>受講コード #{studentListByAttendanceCode?.attendanceCode} 別の受講者リスト</h1>
                    <Table
                        columns={columns}
                        data={studentListByAttendanceCode?.listUser || []}
                        disablePaging={true}
                        scrollHorizontalWidth={'768px'}
                        className='lst-attendance-table mg-t-35'
                    />
                </div>
            </section>
        </div>

    )
})

export default StudentListByAttendanceCodeScreen;