import ApiService from './ApiService';

class AuthApi extends ApiService {

    login(payload) {
        return this.post('login', payload);
    }

    registerEmail(payload) {
        return this.post('register-email', payload);
    }
    registerUser(payload) {
        let data = {...payload};
        delete data?.id;
        return this.post(`${payload?.id}/register`, data);
    }

    getUserConfirmation(userId) {
        return this.get(`${userId}/user`,{});
    }

    confirmEmail(payload) {
        return this.get('confirm-email', payload);
    }
}

export default AuthApi;